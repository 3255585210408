import { Tooltip } from "antd";
import styled from "styled-components";

export const PhonePeFloatingContainer = styled.div`
  // position: fixed;
  width: auto;
  height: auto;
  bottom: 5%;
  align-self: center;
  right: 1rem;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  z-index: 999; 

  @media only screen and (max-width: 768px) {
    right: 0.4rem;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 1;
    }
  }
`;

export const PhonePeButton = styled.a`
  color: #fff;
  margin: 0.2rem 0rem;
  transition: transform 0.5s ease-in-out;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  background-color: #5F259F; 
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none; 
  
  &:hover {
    color: #5F259F; 
    background-color: #fff; 
    transform: rotate(360deg);
  }
`;

export const PhonePeCustomTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    max-width: none !important;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5F259F;
    color: #fff;
    border-radius: 4px;
    padding: 4px 8px;
  }
`;


export const DollarFloatingContainer = styled.div`
  // position: fixed;
  width: auto;
  height: auto;
  bottom: 5%;
  align-self: center;
  right: 1rem;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  z-index: 999; 

  @media only screen and (max-width: 768px) {
    right: 0.4rem;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 1;
    }
  }
`;

export const DollarButton = styled.a`
  color: #fff; 
  margin: 0.2rem 0rem;
  transition: transform 1s ease-in-out;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  background-color: #4caf50;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
  
  &:hover {
    color: #4caf50; 
    background-color: #fff; 
    transform: rotate(360deg);
  }
`;

export const DollarCustomTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    max-width: none !important;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #4caf50; 
    // border: 1px solid #4caf50; 
  }
`;
