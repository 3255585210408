import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Tag } from "antd";
import { TableContainer } from "../../paymentHistory/index.style";
import moment from "moment";
import { getTransactions } from "../../../redux/paymentSlice";
import { Wrapper } from "./index.style";
import { sessionMemory } from "../../../redux/stripeSlice";
import { SyncOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

function AdminPaymentProcessing() {
  const dispatch = useDispatch();
  const { transactions, loading } = useSelector((state) => state.payment);
  const { loading: sessionLoader } = useSelector((state) => state.stripe);
  const [selectedPaymentGateway, setSelectedPaymentGateway] =
    useState("stripe");
  const [transactionsData, setTransactionsData] = useState(null);
  const [isLoaderId, setLoaderId] = useState(null);

  useEffect(() => {
    dispatch(getTransactions(selectedPaymentGateway));
  }, [dispatch, selectedPaymentGateway]);

  useEffect(() => {
    if (selectedPaymentGateway && transactions) {
      setTransactionsData(transactions[selectedPaymentGateway] || []);
    }
  }, [selectedPaymentGateway, transactions]);

  const handlePaymentGatewayChange = (checked) => {
    if (checked) {
      setSelectedPaymentGateway("razorPay");
    } else {
      setSelectedPaymentGateway("stripe");
    }
  };

  const handlePaymentData = (data, index, setLoaderId) => {
    setLoaderId(index);
    const paymentData = {};
    if (selectedPaymentGateway === "razorPay") {
      paymentData.razorpay_payment_id = data?.sessionId;
      paymentData.action = selectedPaymentGateway;
      paymentData.userId = data?.tansyUserId;
    } else if (selectedPaymentGateway === "stripe") {
      paymentData.sessionId = data?.sessionId;
      paymentData.action = selectedPaymentGateway;
      paymentData.userId = data?.tansyUserId;
    }
    dispatch(sessionMemory(paymentData));
  };

  const dataSource = (transactionsData || [])?.map((data, i) => ({
    key: i,
    id: i,
    email: data?.email,
    packageName: data?.packageName,
    platformName: data?.platformName,
    status: data?.status,
    date:
      moment?.unix(data?.date)?.format("dddd, MMMM DD, YYYY, HH:mm:ss") || null,
    active: (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <span>
          {data?.active === true ? (
            <Tag color="green">True </Tag>
          ) : (
            <Tag color="red">False </Tag>
          )}
        </span>
        <span>
          {data?.status === "paid" && data?.active === false && (
            <SyncOutlined
              onClick={() => handlePaymentData(data, i, setLoaderId)}
              spin={isLoaderId === i && sessionLoader}
            />
          )}
        </span>
      </div>
    ),
  }));

  const columns = [
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Transaction Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Record Entry Flag",
      dataIndex: "active",
      key: "active",
    },
    {
      title: "Platform Name",
      dataIndex: "platformName",
      key: "platformName",
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Paid", value: "paid" },
        { text: "Unpaid", value: "unpaid" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text) =>
        text === "unpaid" ? (
          <span style={{ color: "red" }}>Unpaid</span>
        ) : (
          <span style={{ color: "green" }}>Paid</span>
        ),
    },
  ];

  return (
    <Wrapper>
       <Helmet>
      <title>Tansy Academy Admin | Payment Processing </title>
    </Helmet>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            margin: "20px 0px",
          }}
        >
          <div>
            <h1>Payment Processing</h1>
            <p>Length: {transactionsData?.length}</p>
            <p className="note">
              Note: The displayed transactions are limited to the last 100
              entries.
            </p>
          </div>

          <div className="switch">
            <strong
              className={
                selectedPaymentGateway === "stripe" ? "activePlan" : ""
              }
            >
              Stripe
            </strong>
            <Switch onChange={handlePaymentGatewayChange} />
            <strong
              className={
                selectedPaymentGateway === "razorPay" ? "activePlan" : ""
              }
            >
              RazorPay
            </strong>
          </div>
        </div>

        <TableContainer
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            pageSize: transactionsData?.length || 0,
            total: transactionsData?.length || 0,
          }}
        />
      </Container>
    </Wrapper>
  );
}

export default AdminPaymentProcessing;
