import React, { useEffect } from "react";
import "antd/dist/antd.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "../src/style/common.css";
import AllRouts from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./style/toastStyle.scss";
import ReactGA from "react-ga4";
import { GTagManager, TagManagerArgs } from "./utils/helper";
import TagManager from 'react-gtm-module'; 

ReactGA.initialize(GTagManager);
TagManager.initialize(TagManagerArgs);
export const AnalyticsEvents = ({ category, action, label, value }) => {
  ReactGA.event({ category, action, label, value });
};
export const pushPurchaseEvent = (transactionId, value, currency, items) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase_event',
    transaction_id: transactionId,
    value: value,
    currency: currency,
    items: items
  });
};

function App() {
  const currentPath = window.location.pathname + window.location.search;

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: currentPath,
    });
  }, [currentPath]);

  return (
    <React.Fragment>
      <div className="App">
        <AllRouts />
        <ToastContainer />
      </div>
    </React.Fragment>
  );
}

export default App;
