import React from "react";
import CustomBtn from "../../common/button";
import { SectionHeading } from "../../screens/home/index.style";
import { CodeContainer } from "./index.style";
import { useNavigate } from "react-router-dom";

const CodeSection = () => {
  const navigate = useNavigate();

  return (
    <CodeContainer>
      <SectionHeading>
        <strong>Test</strong> Your Code
      </SectionHeading>
      <p>
        Our web-based SQL Query Tool, conveniently accessible via your laptop or
        mobile device's browser, allows you to execute SQL statements and view
        the results immediately with a single click.
      </p>
      <p>1.5M Executions</p>
      <div className="codeSection">
        <code className="codeContainer">
          <div>
            <p>
              SELECT * FROM org_client
              <span></span>
            </p>
            <p>
              WHERE <span>Country = </span> 'USA';
            </p>
          </div>
        </code>
        <div className="showBtn">
          <CustomBtn
            onClick={() => navigate("/online_sql_query_editor_execution")}
            title="Try it yourself"
          />
        </div>
      </div>
    </CodeContainer>
  );
};

export default CodeSection;
