import styled from "styled-components";
import { Container } from "react-bootstrap";

export const Wrapper = styled(Container)`
  padding: 10px;

  .container {
    padding: 5px;
    /* border: 1px solid #ddd; */
    border-radius: 4px;

    h2 {
      display: flex;
      padding: 10px;
      justify-content: center;
      text-align: center;
      margin-bottom: 1em;
      margin-top: 1em;
      text-decoration: underline;
    }

    .ant-select-arrow {
      display: none;
    }
    .ant-card {
      border-radius: 12px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .ant-card-body {
      padding: 20px;
      background-color: white;
      border-radius: 12px;
    }

    .ant-card:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .ant-card-meta-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ant-card-meta-description {
      font-size: 14px;
      color: #555;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;
