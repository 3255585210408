import { Container } from "react-bootstrap";
import { TableContainer, Wrapper } from "../Forum/manageTopic/index.style";
import { CustomSelect } from "../../common/select/index.style";
import { useEffect, useState } from "react";
import { Select, Skeleton, Space, Col } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paginate } from "../../utils/helper";
import CustomPagination from "../../common/pagination";
import { LoadingOutlined, InfoCircleFilled } from "@ant-design/icons";
import { getUserCourses } from "../../redux/userSlice";
import moment from "moment";
import {
  addTasksResponse,
  getAssignmentResponse,
} from "../../redux/assignmentsResSlice";
import { Helmet } from "react-helmet";

function AssignmentResponse() {
  const pageSize = 10;
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSkeleton, setSkeleton] = useState(false);
  const { assignmentResponse, assignmentloading, loading } = useSelector(
    (state) => state.assignmentsRes
  );
  const { userCourses } = useSelector((state) => state?.user);

  const { userProfile } = useSelector((state) => state?.auth);
  let assignmentSubscription = false;
  const assignmentExpiry = userProfile?.subscription?.assignmentAccess
    ? userProfile?.subscription?.assignmentAccess.reduce((array, index) => {
        if (index.courseId === courseId) array.push(index.expiryDate);
        return array;
      }, [])
    : [];
  if (assignmentExpiry) {
    const daysUntilExpiry = moment(assignmentExpiry[0]).diff(moment(), "days");
    if (moment(assignmentExpiry[0]).isValid() && daysUntilExpiry > 0)
      assignmentSubscription = true;
  }

  useEffect(() => {
    dispatch(getUserCourses());
    setSelectedCourse(courseId);
    dispatch(getAssignmentResponse(courseId));
  }, []);

  useEffect(() => {
    setSkeleton(!Object.keys(assignmentResponse).length > 0);
  }, [assignmentResponse]);

  const courseOptions =
    userCourses?.map((course) => ({
      value: course?.courseId,
      label: course?.longName,
    })) || [];

  const filterActiveFlagData = (
    assignmentResponse?.assignmentDetails || []
  )?.filter((assignment) => assignment?.activeFlag);

  const dataSource = [];

  paginate(filterActiveFlagData || [], currentPage, pageSize)
    ?.filter((e) => ["Incomplete", "Rejected", "Approved"].includes(e?.status))
    ?.map((assignmentData, i) => {
      const isSubmitEnabled = ["Incomplete", "Rejected"].includes(
        assignmentData?.status
      );

      let textColor;
      switch (assignmentData?.status) {
        case "Submitted":
          textColor = "#00AC47";
          break;
        case "Approved":
          textColor = "#2AB2DB";
          break;
        default:
          textColor = isSubmitEnabled ? "#C91D2E" : "black";
      }

      const style = { color: textColor };

      const isLocked = assignmentData?.lockedFlag;
      dataSource.push({
        key: i,
        assignmentName: assignmentData?.longName,
        status: <div style={style}>{assignmentData?.status}</div>,
        actions: (
          <Space size="middle" className="deleteBtn">
            {!isLocked ? (
              <Link
                style={{ color: "#D39331" }}
                onClick={() => {
                  const courseId = selectedCourse;
                  const assignmentId = assignmentData?.assignmentId;
                  const data = {
                    assignmentId,
                    assignmentName: assignmentData?.longName,
                  };
                  setLoader(assignmentId);
                  dispatch(
                    addTasksResponse({
                      courseId,
                      data,
                      navigate,
                      assignmentId,
                    })
                  );
                }}
              >
                {assignmentData?.assignmentId === loader &&
                assignmentloading ? (
                  <LoadingOutlined className="loader" />
                ) : (
                  assignmentSubscription && "Start"
                )}
              </Link>
            ) : null}
            {isLocked ? (
              <Link
                to={`/assignment/response/${courseId}/${assignmentData?.assignmentId}/task`}
                style={{
                  color: "#3F6FD8",
                  cursor: "pointer",
                }}
              >
                View
              </Link>
            ) : null}
          </Space>
        ),
      });
    });

  const columns = [
    {
      title: "Assignment Name",
      dataIndex: "assignmentName",
      key: "assignmentName",
    },
    {
      title: "Status ",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const assignmentlength = dataSource?.length;

  return (
    <Wrapper>
       <Helmet>
       <title>Tansy Academy | Course Assignment</title>
      </Helmet>
      <Container style={{ margin: "20px 0px" }} fluid>
        <Skeleton active loading={isSkeleton}>
          <h3 style={{ margin: "50px 0px", textTransform: "uppercase" }}>
            {assignmentResponse?.courseName}
          </h3>
          <div className="mainHeading">
            <div>
              <h3>Assignments</h3>
              <span className="total"> {assignmentlength} assignments</span>
            </div>
            <Col xs={{ span: 24 }} md={{ span: 16 }}>
              {!assignmentSubscription && (
                <div className="subscriptionWrapper">
                  <div className="subscriptionBox">
                    <InfoCircleFilled className="subscriptionIcon" />
                    <p>
                      Subscription is required for manual review of your
                      assignments,{" "}
                      <Link to="/learn_sql_power_bi_analytics_online_training_cost" style={{ textDecoration: "underline" }}>
                        {" "}
                        click here
                      </Link>
                    </p>
                  </div>
                </div>
              )}
            </Col>
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  placeholder="-- Select Course --"
                  defaultValue={selectedCourse}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(courseId) => {
                    dispatch(getAssignmentResponse(courseId));
                    setSelectedCourse(courseId);
                    navigate(`/assignment/response/${courseId}/tasks`);
                  }}
                  options={courseOptions}
                />
              </CustomSelect>
            </div>
          </div>
          <TableContainer
            loading={loading}
            dataSource={dataSource}
            columns={columns}
          />
          {assignmentlength > 10 ? (
            <CustomPagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={assignmentlength}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          ) : null}
        </Skeleton>
      </Container>
    </Wrapper>
  );
}

export default AssignmentResponse;
