import axios from "axios";
import { basePath } from "./constants";

const axiosPayment = axios.create();
axiosPayment.defaults.baseURL = basePath;
axiosPayment.defaults.timeout = 35000;

axiosPayment.interceptors.request.use(
  async (config) => {
    const authUser = JSON.parse(
      localStorage.getItem("authUser") &&
        localStorage.getItem("authUser") != "undefined"
        ? localStorage.getItem("authUser")
        : null
    );
    const token = authUser && authUser?.token;
    config.headers.Authorization = token ? "Bearer " + token : null;
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Headers"] = "*";
    config.headers["Access-Control-Allow-Methods"] = "*";

    if (!isSecure()) {
      throw new Error("Insecure connection detected");
    }

    config.headers["X-Frame-Options"] = "DENY";
    config.headers["X-XSS-Protection"] = "1; mode=block";
    config.headers["Content-Security-Policy"] = "default-src 'self'";
    config.headers["Strict-Transport-Security"] =
      "max-age=1; includeSubDomains";
    config.headers["Referrer-Policy"] = "strict-origin-when-cross-origin";

    const csrfToken = getCSRFToken();
    if (csrfToken) {
      config.headers["X-CSRF-Token"] = csrfToken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function isSecure() {
  return window.location.protocol === "https:";
}

function getCSRFToken() {
  const csrfToken = document.querySelector('meta[name="csrf-token"]');
  return csrfToken ? csrfToken.getAttribute("content") : null;
}

export default axiosPayment;
