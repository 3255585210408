import { Card } from "antd";
import styled from "styled-components";

export const StyledMenu = styled.div`
  & > ul {
    padding: 0;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: 0;
  }
  .ant-menu-item-selected a {
    color: #1890ff;
    font-weight: 500;
  }
  .ant-menu-item a {
    white-space: pre-wrap;
  }
`;
export const HoverCard = styled(Card)`
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid white;
  border-radius: 10px;
  transition: box-shadow 0.3s;

  pre,
  code,
  span {
    color: black !important;
    background: white !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16.5px;
  }

  &:hover {
    border-right: 4px solid #00ac47;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
