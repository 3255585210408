import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg, successMsg } from "../components/AlertMessage";
import { ApiRequests } from "../services/ApiRequest";

export const addQuestionsResponse = createAsyncThunk(
  "questionsResponse/addQuestionsResponse",
  async (
    { data, courseId, date, lockedFlag, quizId, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.addQuestionsResponse(data, courseId);
      if (response.status === 201) {
        successMsg(response?.data?.message);
        navigate(
          `/quiz/response/${courseId}/${quizId}/question?status=active&isLockFlag=${lockedFlag}&date=${date}`
        );
        return response?.data?.data;
      } else {
        errorMsg(response?.data?.message);
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const getQuizResponse = createAsyncThunk(
  "questionsResponse/getQuizResponse",
  async ({ courseId, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getQuizResponse(courseId);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      if (error?.response?.data?.status === "No Subscription") {
        errorMsg(error?.response?.data?.message?.message);
        navigate(error?.response?.data?.message?.navigate);
      } else {
        errorMsg(error?.response?.data?.status);
      }
    }
  }
);

export const quizQuestionsResponse = createAsyncThunk(
  "questionsResponse/quizQuestionsResponse",
  async (
    { action, courseId, quizId, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.quizquestionRes(
        action,
        courseId,
        quizId
      );
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      if (error?.response?.data?.status === "No Subscription") {
        errorMsg(error?.response?.data?.message?.message);
        navigate(error?.response?.data?.message?.navigate);
      } else {
        errorMsg(error?.response?.data?.status);
      }
    }
  }
);

export const updateScoreCard = createAsyncThunk(
  "questionsResponse/updateScoreCard",
  async (
    { courseId, quizId, data, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.updateScoreCard(
        courseId,
        quizId,
        data
      );
      if (response.status === 200) {
        successMsg("Check your score now!");
        dispatch(quizQuestionsResponse({ action: "result", courseId, quizId }));
        // navigate(`/quiz/response/${courseId}/questions`);
        navigate(
          `/quiz/response/${courseId}/${quizId}/question?status=result&isCompletedFlag=${response?.data?.isCompleted}&date=${response?.data?.date}`
        );
        return response.status;
      }
    } catch (error) {
      if (error?.response?.data?.status === "No Subscription") {
        errorMsg(error?.response?.data?.message?.message);
        navigate(error?.response?.data?.message?.navigate);
      } else {
        errorMsg(error?.response?.data?.status);
      }
    }
  }
);

export const updateQuizResult = createAsyncThunk(
  "questionsResponse/updateQuizResult",
  async (
    { courseId, quizId, data, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.updateQuizResult(
        courseId,
        quizId,
        data
      );
      if (response.status === 200) {
        await dispatch(
          updateScoreCard({
            courseId,
            quizId,
            navigate,
            data: { updateLockFlag: true },
          })
        );
        return response.status;
      }
    } catch (error) {
      if (error?.response?.data?.status === "No Subscription") {
        errorMsg(error?.response?.data?.message?.message);
        navigate(error?.response?.data?.message?.navigate);
      } else {
        errorMsg(error?.response?.data?.status);
      }
    }
  }
);

const initialState = {
  loading: false,
  quizLoading: false,
  quizResponse: {},
  quizQuestion: [],
  updateResult: {},
  scoreCard: {},
};

const questionsResSlice = createSlice({
  name: "questionsRes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addQuestionsResponse.pending, (state) => {
        state.quizLoading = true;
      })
      .addCase(addQuestionsResponse.fulfilled, (state) => {
        state.quizLoading = false;
      })
      .addCase(addQuestionsResponse.rejected, (state) => {
        state.quizLoading = false;
      })
      .addCase(updateScoreCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateScoreCard.fulfilled, (state, action) => {
        state.scoreCard = action.payload;
        state.loading = false;
      })
      .addCase(updateScoreCard.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQuizResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuizResponse.fulfilled, (state, action) => {
        state.quizResponse = action.payload;
        state.loading = false;
      })
      .addCase(getQuizResponse.rejected, (state) => {
        state.loading = false;
      })
      .addCase(quizQuestionsResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(quizQuestionsResponse.fulfilled, (state, action) => {
        state.quizQuestion = action.payload;
        state.loading = false;
      })
      .addCase(quizQuestionsResponse.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateQuizResult.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateQuizResult.fulfilled, (state, action) => {
        state.updateResult = action.payload;
        state.loading = false;
      })
      .addCase(updateQuizResult.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default questionsResSlice.reducer;
