import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "antd";

export const IconLinkWrapper = styled(Link)`
  margin-left: 10px;
  cursor: pointer;
`;

export const EditDeleteWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -20px;
  display: flex;
  align-items: center;
`;

export const QuestionWrapper = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  position: relative;
  padding-bottom: 15px;
`;

export const QuestionText = styled.p`
  width: 96%;
  font-size: 20px;
  margin-bottom: 8px;
`;

export const ExplanationText = styled.p`
  margin-bottom: 15px;
  // font-size: 15px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`;
export const EditButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
`;
export const ButtonConst = styled(Button)`
  background: #d39331 !important;
  border: 1px solid #d39331 !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  border-radius: 0;
  .loader {
    font-size: 2rem !important;
  }

  &:disabled {
    background: #d39331;
    border: 1px solid #d39331;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    border-radius: 0;
    opacity: 0.5;

    &:hover {
      background: #d39331;
      border: 1px solid #d39331;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      border-radius: 0;
      opacity: 0.5;
    }
  }

  span {
    color: #ffffff;
  }
  &:focus,
  &:hover {
    background: #d39331;
    border-radius: 0;
    border: 1px solid #d39331;
    opacity: 0.9;
    span {
      color: #ffffff;
    }
  }
`;
