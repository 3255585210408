import React from "react";
import { Button, List } from "antd";
import { Link } from "react-router-dom";
import CustomImage from "../../common/images";

const LinkList = ({ options }) => (
  <List
    dataSource={options}
    renderItem={(item) => (
      <React.Fragment key={item.id}>
        {item.icon ? (
          <Link to={item.url} target="_blank" rel="noopener noreferrer">
            <Button className={`chatbot-button ${item.className}`}>
              {item.icon}
              {item.text}
            </Button>
          </Link>
        ) : (
          <List.Item>
            <Link to={item.url} target="_blank" rel="noopener noreferrer">
              {item.text}
            </Link>
          </List.Item>
        )}
      </React.Fragment>
    )}
  />
);

export const CourseLinks = (props) => {
  const options = [
    {
      text: "MySQL",
      url: "https://tansyacademy.com/course_detail?name=MySQL",
      id: 1,
    },
    {
      text: "PostgreSQL",
      url: "https://tansyacademy.com/course_detail?name=PostgreSQL",
      id: 2,
    },
    {
      text: "Oracle",
      url: "https://tansyacademy.com/course_detail?name=Oracle",
      id: 3,
    },
    {
      text: "Microsoft SQL Server",
      url: "https://tansyacademy.com/course_detail?name=Microsoft%20SQL%20Server",
      id: 4,
    },
  ];

  return <LinkList options={options} actionProvider={props.actionProvider} />;
};

export const SocialMediaLinks = (props) => {
  const options = [
    {
      text: "Chat in Slack",
      id: 5,
      url: "https://app.slack.com/client/T87MFUR42/D03FMNQ5E6L",
      icon: <CustomImage src="/icons/slack.svg" alt="slack" className="button-icon" />,
      className: "slack-button",
    },
    {
      text: "Chat in WhatsApp",
      id: 6,
      url: "https://wa.me/+971504614868",
      icon: (
        <CustomImage src="/icons/whatsapp.svg" alt="whatsapp" className="button-icon" />
      ),
      className: "whatsapp-button",
    },
  ];

  return <LinkList options={options} actionProvider={props.actionProvider} />;
};
