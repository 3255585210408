import React from "react";
import { Button, List } from "antd";
import { Link } from "react-router-dom";

const QuestionButton = ({ text, onClick }) => (
  <List.Item className="talk-links-button" onClick={onClick}>
    <Link>{text}</Link>
  </List.Item>
);

const InquirySection = ({ questions, actionProvider }) => {
  const talkMarkup = questions?.map((question, i) => (
    <React.Fragment key={question.id}>
      {question.url ? (
        <Link to={question.url} target="_blank" rel="noopener noreferrer">
          <Button className={`chatbot-button ${question.className}`}>
            {question.icon}
            {question.text}
          </Button>
        </Link>
      ) : (
        <QuestionButton
          text={`${i + 1}. ${question.text}`}
          onClick={() => actionProvider.handleLinks(question.handler)}
        />
      )}
    </React.Fragment>
  ));

  return <div className="talk-links-container">{talkMarkup}</div>;
};

export const ClassScheduleEnquiry = (props) => {
  const questions = [
    {
      text: "What time do the SQL courses begin?",
      handler:
        "Our SQL courses typically start at 10 AM local time, but we offer multiple batches to suit different time zones.",
      id: 1,
    },
    {
      text: "Are weekend classes available for SQL boot camp?",
      handler:
        "Yes, we have weekend classes for our SQL boot camp to accommodate your schedule.",
      id: 2,
    },
    {
      text: "How long is the SQL database training course?",
      handler:
        "The SQL database training course runs for eight weeks, with twice-weekly sessions.",
      id: 3,
    },
  ];

  return (
    <InquirySection
      questions={questions}
      actionProvider={props.actionProvider}
    />
  );
};

export const PaymentsAndSubscriptionEnquiry = (props) => {
  const questions = [
    {
      text: "How do I pay for the SQL course?",
      handler:
        "You can pay for the SQL course via our secure online payment portal using a credit card, PayPal, or bank transfer.",
      id: 1,
    },
    {
      text: "What subscription plans are available for ongoing SQL training?",
      handler:
        "We offer monthly and annual subscription plans that provide access to all SQL courses and additional resources.",
      id: 2,
    },
    {
      text: "Can I upgrade my subscription to include more advanced SQL courses?",
      handler:
        "Absolutely! Upgrading is simple, just visit the subscription settings on your profile or contact us for assistance.",
      id: 3,
    },
  ];

  return (
    <InquirySection
      questions={questions}
      actionProvider={props.actionProvider}
    />
  );
};

export const OnlinePortalIssues = (props) => {
  const questions = [
    {
      text: "I'm having trouble logging into my SQL course dashboard; what should I do?",
      handler:
        "Please try resetting your password using the 'Forgot Password' link. If the issue persists, contact our support team.",
      id: 1,
    },
    {
      text: "The video lectures are not loading; how can I fix this?",
      handler:
        "Ensure you have a stable internet connection, and try clearing your browser cache. If the problem continues, our tech support can help.",
      id: 2,
    },
    {
      text: "I am unable to access the SQL course materials; whom should I contact?",
      handler:
        "We apologize for the inconvenience. Please reach out to our technical support team via the 'Contact Us' page, and they'll resolve the issue promptly.",
      id: 3,
    },
  ];

  return (
    <InquirySection
      questions={questions}
      actionProvider={props.actionProvider}
    />
  );
};

export const ContactSupport = (props) => {
  const handleLink = (text, url) => {
    props.actionProvider.handleLinks(text, url);
  };
  const options = [
    {
      text: "How can I contact a tutor for SQL-related queries?",
      handler: () =>
        handleLink(
          "You can reach out to your assigned tutor via the messaging system in your course dashboard or by posting in the course forum.",
          ""
        ),
      id: 1,
    },
    {
      text: "Is there a live chat support available during non-business hours?",
      handler: () =>
        handleLink(
          "Our live chat support is available 24/7 for any urgent queries or issues you might have.",
          ""
        ),
      id: 2,
    },
    {
      text: "What is the best way to get immediate help for SQL course-related issues?",
      handler: () =>
        handleLink(
          "For immediate assistance, use our live chat feature. For less urgent matters, you may prefer to send us an email or leave a message on the support page.",
          ""
        ),
      id: 3,
    },
    {
      text: "Need Help?",
      handler: () =>
        handleLink(
          "If you have any questions or need assistance, our support team is ready to help!",
          "socialMediaLinks"
        ),
      id: 4,
    },
  ];

  return (
    <List
      dataSource={options}
      renderItem={(item, index) => (
        <React.Fragment key={item.id}>
          <Link
            onClick={item.handler}
            className={`chatbot-button talk-links-button ${item.className}`}
          >
            {`${index + 1}. ${item.text}`}
          </Link>
        </React.Fragment>
      )}
    />
  );
};
