import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import {
  BlinkingText,
  Container,
  FlashNewsIcon,
  LiveClass,
  Marquee,
  Title,
} from "./index.style";
import sections from "./xmlContent.json";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";

const liveClasses = [
  {
    date: "Aug 23, 2024",
    time: "10:00 AM",
    link: "https://tansyacademy.com/learn_sql_class_online_sql_training_course",
    text: "** Stay Tuned for Exciting Live Classes at Tansy Academy!",
  },
  {
    date: "Aug 23, 2024",
    time: "12:00 AM",
    link: "https://tansyacademy.com/learn_sql_class_online_sql_training_course",
    text: "** Stay Tuned for Exciting Live Classes at Tansy Academy!",
  },
  {
    date: "Aug 23, 2024",
    time: "02:00 PM",
    link: "https://tansyacademy.com/learn_sql_class_online_sql_training_course",
    text: "** Stay Tuned for Exciting Live Classes at Tansy Academy!",
  },
  {
    date: "Aug 23, 2024",
    time: "04:00 PM",
    link: "https://tansyacademy.com/learn_sql_class_online_sql_training_course",
    text: "** Stay Tuned for Exciting Live Classes at Tansy Academy!",
  },
];

const Sitemap = () => (
  <Container>
    <MetaHelmet {...metaHelmetData.sitemap} />
    <Title>Sitemap</Title>
    <LiveClass>
      <FlashNewsIcon />
      <BlinkingText>
        <Marquee>
          {liveClasses?.map(({ link, text }, index) => (
            <Link
              key={index}
              to={link}
              style={{ margin: "0 30px", color: "#333" }}
            >
              {text}
            </Link>
          ))}
        </Marquee>
      </BlinkingText>
    </LiveClass>
    {sections?.map(({ title, content, url }, index) => (
      <React.Fragment key={index}>
        <Link to={url}>
          <h3 style={{ margin: "30px 0 20px 0px", color: "#333" }}>{title}</h3>
        </Link>
        <Row gutter={[6, 6]} style={{ width: "98%", margin: "20px auto" }}>
          {content?.map((item, subIndex) => (
            <React.Fragment key={subIndex}>
              {item?.chapter && (
                <Col xs={24}>
                  <h6 className="chapter">{item?.chapter}</h6>
                </Col>
              )}
              {Array.isArray(item?.subContent) ? (
                item?.subContent?.map(
                  ({ contentUrl, contentTitle }, colIndex) => (
                    <Col
                      gutter={[6, 6]}
                      key={colIndex}
                      xs={12}
                      md={6}
                      style={{ marginBottom: "10px" }}
                    >
                      <urlset>
                        <url>
                          <loc>
                            <Link className="contentUrl" to={contentUrl}>
                              {contentTitle}
                            </Link>
                          </loc>
                        </url>
                      </urlset>
                    </Col>
                  )
                )
              ) : (
                <Col style={{ marginBottom: "10px" }} xs={12} md={6}>
                  <urlset>
                    <url>
                      <loc>
                        <Link className="contentUrl" to={item?.subUrl}>
                          {item?.subtitle}
                        </Link>
                      </loc>
                    </url>
                  </urlset>
                </Col>
              )}
            </React.Fragment>
          ))}
        </Row>
      </React.Fragment>
    ))}
  </Container>
);

export default Sitemap;
