import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserPaymentDetails,
  makeRoles,
  UpdateUserPaymentStatus,
} from "../../../../redux/userInfoSlice";
import {
  Form,
  Input,
  Row,
  Col,
  Card,
  Select,
  Image,
  Button,
  Skeleton,
  Spin,
} from "antd";
import { Wrapper } from "./index.style";
import { getAdminCourses } from "../../../../redux/courseSlice";
import moment from "moment";
import { ButtonGroup } from "react-bootstrap";

const EditUserSessionDetails = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { userPaymentDetails, loading = true } = useSelector(
    (store) => store?.userInfoSession
  );
  const [isSubscription, setSubscription] = useState(null);
  const [enrollCourses, setEnrollCourses] = useState(null);
  const [editUserRoles, setEditUserRoles] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [userTransaction, setUserTransaction] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [userForm] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const { adminCourses, courseLoading = true } = useSelector(
    (state) => state?.course
  );
  const { Meta } = Card;

  useEffect(() => {
    dispatch(fetchUserPaymentDetails({ userId }));
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(getAdminCourses());
  }, [dispatch]);

  const { userDetails = {}, paymentDetails = [] } = userPaymentDetails;

  useEffect(() => {
    if (userDetails) {
      setSubscription((prev) =>
        prev !== userDetails?.subscription ? userDetails?.subscription : prev
      );
      setEnrollCourses((prev) =>
        prev !== userDetails?.course ? userDetails?.course : prev
      );
      setImageURL((prev) =>
        prev !== userDetails?.imageURL ? userDetails?.imageURL : prev
      );

      userForm?.setFieldsValue({
        userFullName: userDetails?.name,
        Email: userDetails?.email,
        SocialLogin: userDetails?.socialLogin,
        Admin: userDetails?.admin,
        Lecturer: userDetails?.lecturer,
        City: userDetails?.city,
        Country: userDetails?.country,
        Points: userDetails?.points,
      });
    }

    if (paymentDetails) {
      const processedPayments = paymentDetails?.map((payment) => ({
        _id: payment?._id,
        AfterDiscountAmount: payment?.afterDiscountAmount?.["$numberDecimal"],
        Date: payment?.date,
        ExpiryDate: payment?.expiryDate,
        Currency: payment?.currency?.toUpperCase(),
        PaymentStatus: payment?.paymentStatus,
        PaymentMode: payment?.paymentMode,
        PlatformName: payment?.platformName,
        SubscriptionStatus: payment?.subscriptionStatus,
      }));

      setUserTransaction((prev) => {
        const paymentsChanged =
          JSON.stringify(prev) !== JSON.stringify(processedPayments);
        return paymentsChanged ? processedPayments : prev;
      });
    }
  }, [userDetails, paymentDetails]);

  const courseOptions =
    adminCourses?.map((course) => ({
      value: course?._id,
      label: course?.longName,
    })) || [];

  const subscriptionOptions =
    paymentDetails?.map((subscription) => ({
      value: subscription?._id,
      label: subscription?.date,
    })) || [];

  const subscriptionStatus =
    ["active", "refunded", "disabled"]?.map((value) => ({
      value: value,
      label: value?.toUpperCase(),
    })) || [];

  const booleanStatus =
    [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ]?.map(({ value, label }) => ({
      value,
      label,
    })) || [];

  const handleEditClick = (transaction) => {
    setIsEditing(transaction?._id);
    paymentForm?.setFieldsValue({
      SubscriptionStatus: transaction?.SubscriptionStatus,
      PaymentStatus: transaction?.PaymentStatus,
      Currency: transaction?.Currency,
      AfterDiscountAmount: transaction?.AfterDiscountAmount,
      Date: transaction?.Date,
      ExpiryDate: transaction?.ExpiryDate,
      PlatformName: transaction?.PlatformName,
      PaymentMode: transaction?.PaymentMode,
    });
  };

  const handleCancelClick = () => {
    setIsEditing(null);
    setEditUserRoles(false);
    paymentForm?.resetFields();
  };

  const onFinishPaymentChanges = (values) => {
    paymentForm?.validateFields()?.then((values) => {
      const data = {
        subscriptionStatus: values?.SubscriptionStatus,
        paymentStatus: values?.PaymentStatus,
        currency: values?.Currency,
        afterDiscountAmount: values?.AfterDiscountAmount,
        date: values?.Date,
        expiryDate: values?.ExpiryDate,
        platformName: values?.PlatformName,
        paymentMode: values?.PaymentMode,
      };
      dispatch(
        UpdateUserPaymentStatus({
          paymentID: isEditing,
          onReset: handleCancelClick,
          userId,
          data,
        })
      );
    });
  };

  const onFinishUserChanges = (values) => {
    userForm?.validateFields()?.then((values) => {
      const data = {
        makeAdmin: values?.Admin,
        makeLecturer: values?.Lecturer,
        userId,
      };
      dispatch(
        makeRoles({
          data,
          userId,
          onReset: handleCancelClick,
        })
      );
    });
  };

  return (
    <Wrapper fluid>
      <Row gutter={16}>
        <Col xs={24} md={14}>
          <div className="container">
            <h2>User Information</h2>
            <Form
              form={userForm}
              layout="horizontal"
              name="dynamic_form_nest_item"
              autoComplete="off"
              onFinish={onFinishUserChanges}
            >
              <Row gutter={1}>
                <Col span={24}>
                  <Card
                    extra={
                      <div>
                        {editUserRoles ? (
                          <ButtonGroup>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={loading}
                              style={{ marginRight: "8px" }}
                            >
                              Save
                            </Button>
                            <Button
                              type="default"
                              onClick={() => handleCancelClick()}
                              style={{ marginRight: "8px" }}
                            >
                              Cancel
                            </Button>
                          </ButtonGroup>
                        ) : (
                          <Button
                            type="default"
                            loading={loading}
                            onClick={() => setEditUserRoles(true)}
                            style={{ marginRight: "8px" }}
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    }
                  >
                    <Spin spinning={loading}>
                      {courseLoading ? (
                        <Skeleton active />
                      ) : (
                        <Row gutter={1}>
                          <Col span={6}>
                            {imageURL && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  margin: "5px",
                                }}
                              >
                                <Image
                                  src={imageURL}
                                  alt="pic"
                                  width="140px"
                                  height="140px"
                                  preview={false}
                                  loading="lazy"
                                  style={{
                                    transition:
                                      "transform 0.3s ease, border 0.3s ease, padding 0.3s ease",
                                    padding: "5px",
                                    boxSizing: "border-box",
                                  }}
                                  onMouseEnter={(e) => {
                                    "3px double green";
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.border = "none";
                                  }}
                                  onError={(e) => {
                                    e.target.style.display = "none";
                                  }}
                                />
                              </div>
                            )}
                          </Col>
                          <Col span={18}>
                            <Row gutter={1}>
                              <Col span={12}>
                                <Form.Item
                                  name="userFullName"
                                  label="User Name"
                                >
                                  <Input bordered={false} readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="Email" label="Email">
                                  <Input bordered={false} readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name="SocialLogin"
                                  label="Social Login"
                                >
                                  <Input bordered={false} readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="Admin" label="Admin">
                                  <Select
                                    disabled={!editUserRoles}
                                    bordered={editUserRoles}
                                    placeholder="-- Select Course --"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={booleanStatus}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="City" label="City">
                                  <Input bordered={false} readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="Lecturer" label="Lecturer">
                                  <Select
                                    disabled={!editUserRoles}
                                    bordered={editUserRoles}
                                    placeholder="-- Select Course --"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={booleanStatus}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="Country" label="Country">
                                  <Input bordered={false} readOnly />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name="Points" label="Points">
                                  <Input bordered={false} readOnly />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </Spin>
                  </Card>
                  <hr style={{ margin: "10px 0" }} />
                </Col>
                <Col span={24}>
                  <Card>
                    {courseLoading ? (
                      <Skeleton active />
                    ) : (
                      <>
                        <Meta
                          title={
                            <strong
                              style={{
                                paddingBottom: "20px",
                                display: "block",
                              }}
                            >
                              {`Enrolled Courses : `}
                            </strong>
                          }
                        />

                        {Array.isArray(enrollCourses) &&
                          enrollCourses?.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                paddingLeft: "10px",
                              }}
                            >
                              <Row>
                                <Col span={8}>
                                  {item?.courseId && (
                                    <Form.Item label="Course ID">
                                      <Select
                                        key={item.courseId}
                                        bordered={false}
                                        disabled
                                        placeholder="-- Select Course --"
                                        value={item.courseId}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        options={courseOptions}
                                      />
                                    </Form.Item>
                                  )}
                                </Col>
                                <Col span={8}>
                                  {item.enrolledDate && (
                                    <Form.Item label="Enrolled Date">
                                      <Input
                                        bordered={false}
                                        value={moment(
                                          item?.enrolledDate
                                        ).format("yyy-mm-DD")}
                                        readOnly
                                      />
                                    </Form.Item>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          ))}
                      </>
                    )}
                  </Card>
                  <hr style={{ margin: "10px 0" }} />
                </Col>
                <Col span={24}>
                  {Object.entries(isSubscription || {})?.map(([key, value]) => (
                    <div key={key}>
                      <Card>
                        {courseLoading ? (
                          <Skeleton active />
                        ) : (
                          <>
                            <Meta
                              title={
                                <strong
                                  style={{
                                    paddingBottom: "20px",
                                    display: "block",
                                  }}
                                >
                                  {`${key?.toUpperCase()} : `}
                                </strong>
                              }
                            />

                            {Array.isArray(value) ? (
                              value?.map((item, index) => (
                                <div
                                  key={`${index}-${index}`}
                                  style={{
                                    paddingLeft: "10px",
                                  }}
                                >
                                  <Row>
                                    <Col span={8}>
                                      {item?.courseId && (
                                        <Form.Item label="Course ID">
                                          <Select
                                            key={item.courseId}
                                            bordered={false}
                                            disabled
                                            placeholder="-- Select Course --"
                                            value={item.courseId}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                            }
                                            options={courseOptions}
                                          />
                                        </Form.Item>
                                      )}
                                    </Col>
                                    <Col span={8}>
                                      {item?.expiryDate && (
                                        <Form.Item label="Expiry Date">
                                          <Input
                                            bordered={false}
                                            value={item.expiryDate}
                                            readOnly
                                          />
                                        </Form.Item>
                                      )}
                                    </Col>
                                    <Col span={8}>
                                      {item?.subscriptionId && (
                                        <Form.Item label="Subscription:">
                                          <Select
                                            key={item.subscriptionId}
                                            bordered={false}
                                            disabled
                                            placeholder="-- Select Course --"
                                            value={item.subscriptionId}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                            }
                                            options={subscriptionOptions}
                                          />
                                        </Form.Item>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              ))
                            ) : (
                              <Row style={{ paddingLeft: "20px" }}>
                                {Object.entries(value)
                                  ?.filter(([, subValue]) => subValue !== null)
                                  ?.map(([subKey, subValue]) => (
                                    <Col span={8}>
                                      {subKey === "expiryDate" ? (
                                        <Form.Item label="Expiry Date">
                                          <Input
                                            bordered={false}
                                            value={subValue}
                                            readOnly
                                          />
                                        </Form.Item>
                                      ) : (
                                        <Form.Item label="Subscription:">
                                          <Select
                                            disabled
                                            key={subValue}
                                            bordered={false}
                                            placeholder="-- Select Course --"
                                            value={subValue}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                              (option?.label ?? "")
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                            }
                                            options={subscriptionOptions}
                                          />
                                        </Form.Item>
                                      )}
                                    </Col>
                                  ))}
                              </Row>
                            )}
                          </>
                        )}
                      </Card>
                      <hr style={{ margin: "10px 0" }} />
                    </div>
                  ))}
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col xs={24} md={10}>
          <Spin spinning={loading}>
            <div className="container">
              <h2>Payment Details</h2>
              {courseLoading && <Skeleton active />}
              {userTransaction?.map((transaction, i) => {
                const visible = isEditing === transaction?._id;
                return (
                  <Form
                    key={transaction?._id || i}
                    form={paymentForm}
                    layout="horizontal"
                    name="dynamic_form_nest_item"
                    onFinish={onFinishPaymentChanges}
                  >
                    <Card
                      key={transaction?._id || i}
                      title={<strong>{`Transaction :  ${i + 1}`}</strong>}
                      style={{ marginBottom: "20px" }}
                      extra={
                        <div>
                          {visible ? (
                            <ButtonGroup>
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                style={{ marginRight: "8px" }}
                              >
                                Save
                              </Button>
                              <Button
                                type="default"
                                onClick={() => handleCancelClick()}
                                style={{ marginRight: "8px" }}
                              >
                                Cancel
                              </Button>
                            </ButtonGroup>
                          ) : (
                            <Button
                              type="default"
                              loading={loading}
                              onClick={() => handleEditClick(transaction)}
                              style={{ marginRight: "8px" }}
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      }
                    >
                      {courseLoading ? (
                        <Skeleton active />
                      ) : visible ? (
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              name="SubscriptionStatus"
                              label="Subscription Status"
                            >
                              <Select
                                placeholder="-- Select Course --"
                                bordered={visible}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={subscriptionStatus}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name="PaymentStatus" label="Pay Status">
                              <Input
                                bordered={visible}
                                readOnly={visible}
                                disabled={visible}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name="Currency" label="Currency">
                              <Input
                                bordered={visible}
                                readOnly={visible}
                                disabled={visible}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="AfterDiscountAmount"
                              label="After Discount Amount"
                            >
                              <Input
                                bordered={visible}
                                readOnly={visible}
                                disabled={visible}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name="Date" label="Start Date">
                              <Input
                                bordered={visible}
                                readOnly={visible}
                                disabled={visible}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name="ExpiryDate" label="Expiry Date">
                              <Input
                                bordered={visible}
                                readOnly={visible}
                                disabled={visible}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name="PlatformName" label="Platform">
                              <Input
                                bordered={visible}
                                readOnly={visible}
                                disabled={visible}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item name="PaymentMode" label="Payment Mode">
                              <Input
                                bordered={visible}
                                readOnly={visible}
                                disabled={visible}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : (
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="Subscription Status">
                              {transaction?.SubscriptionStatus?.toUpperCase()}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              value={transaction?.PaymentStatus}
                              label="Pay Status"
                            >
                              {transaction?.PaymentStatus}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Currency">
                              {transaction?.Currency}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Amount">
                              {transaction?.AfterDiscountAmount}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Start Date">
                              {transaction?.Date}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Expiry Date">
                              {transaction?.ExpiryDate}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Platform">
                              {transaction?.PlatformName}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Payment Mode">
                              {transaction?.PaymentMode}
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </Card>
                    <hr style={{ margin: "10px 0" }} />
                  </Form>
                );
              })}
            </div>
          </Spin>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default EditUserSessionDetails;
