import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Select, Input, Form, Checkbox } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { CustomSelect } from "../../../common/select/index.style.js";
import CustomBtn from "../../../common/button/index.jsx";
import { Wrapper } from "../../Forum/manageTopic/index.style.js";
import { InfoForm } from "../../Forum/manageTopic/topicDetails/index.style.js";
import { CustomInput } from "../../../common/input/index.style.js";
import { antValidator } from "../../../utils/helper.js";
import { questionsSchema } from "../../../utils/validationSchema.js";
import { addQuestion } from "../../../redux/questionsSlice.js";
import { Helmet } from "react-helmet";
import { BsChevronLeft } from "react-icons/bs";
import { BackBtn } from "../../Forum/forumlList/threadQuestion/index.style.js";

const AddQuizQuestion = () => {
  const { TextArea } = Input;
  const { quizId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [rules, setRules] = useState({});
  const [questionType, setQuestionType] = useState("");
  const { loadingQuestions } = useSelector((state) => state.questions);
  const ref = useRef(null);

  useEffect(() => {
    setRules(antValidator(questionsSchema));
    setQuestionType("Radio Button");
  }, []);

  useEffect(() => {
    if (questionType === "Radio Button") {
      ref.current.setFieldsValue({
        questionType: "Radio Button",
        OptionA: "",
        OptionB: "",
        OptionC: "",
        OptionD: "",
        questionPublicFlag: true,
      });
    } else if (questionType === "True/False") {
      ref.current.setFieldsValue({
        questionType: "True/False",
        OptionA: "True",
        OptionB: "False",
        questionPublicFlag: true,
      });
    } else {
      ref.current.setFieldsValue({
        questionType,
        OptionA: "",
        OptionB: "",
        OptionC: "",
        OptionD: "",
        questionPublicFlag: true,
      });
    }
  }, [ref, questionType]);
  
  const optionsQuestionType = [
    { value: "Checkbox", label: "Checkbox" },
    { value: "True/False", label: "True/False" },
    { value: "Radio Button", label: "Radio Button" },
  ];

  const answerFlagOptions = {
    "True/False": [
      { label: "Option A", value: "optionA" },
      { label: "Option B", value: "optionB" },
    ],
    default: [
      { label: "Option A", value: "optionA" },
      { label: "Option B", value: "optionB" },
      { label: "Option C", value: "optionC" },
      { label: "Option D", value: "optionD" },
    ],
  };

  const FormReset = () => form.resetFields();

  const handleChange = (newValue) => {
    form.setFieldsValue({ answer: [] });
    setQuestionType(newValue);
  };

  const handleQuestionsSubmit = (fieldsValue) => {
    const formDataObject = {
      action: "add",
      isQuestionPublic: fieldsValue?.questionPublicFlag,
      questionName: fieldsValue?.writeQuestion,
      questionType: fieldsValue?.questionType,
      questionImageURL: fieldsValue?.enterImageLink || null,
      codingQuestion: fieldsValue?.codingQuestion || null,
      explanation: fieldsValue?.explanation,
    };

    switch (fieldsValue?.questionType) {
      case "True/False":
        formDataObject.optionA = fieldsValue?.OptionA;
        formDataObject.optionB = fieldsValue?.OptionB;
        break;
      default:
        formDataObject.optionA = fieldsValue?.OptionA;
        formDataObject.optionB = fieldsValue?.OptionB;
        formDataObject.optionC = fieldsValue?.OptionC;
        formDataObject.optionD = fieldsValue?.OptionD;
        break;
    }

    formDataObject.answerFlag = Array.isArray(fieldsValue?.answer)
      ? fieldsValue?.answer
      : [fieldsValue?.answer];

    dispatch(
      addQuestion({ formDataObject, quizId, navigate, onReset: FormReset })
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Add Question</title>
      </Helmet>
      <Container fluid>
        <BackBtn
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <span>
            <BsChevronLeft />
          </span>
          <span>Go back</span>
        </BackBtn>
        <InfoForm
          layout="vertical"
          style={{ borderBottom: "0px solid #ffff" }}
          onFinish={handleQuestionsSubmit}
          form={form}
          ref={ref}
        >
          <center className="mb-4">
            <h2>New Question</h2>
          </center>
          <CustomInput
            name="writeQuestion"
            label={<strong>Write Question : </strong>}
            rules={[rules]}
          >
            <Input rows={4} placeholder="Write Question" />
          </CustomInput>
          <Row gutter={[30]}>
            <Col span={12}>
              <CustomSelect
                name="questionType"
                label={<strong>Question Type : </strong>}
                rules={[rules]}
              >
                <Select
                  // showSearch
                  placeholder="Select question type"
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={optionsQuestionType}
                  onChange={handleChange}
                  value={questionType}
                />
              </CustomSelect>
            </Col>
            <Col span={12}>
              <CustomInput
                label={<strong>Image Link : </strong>}
                name="enterImageLink"
              >
                <Input placeholder="Enter image link" />
              </CustomInput>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomInput
                label={<strong>Code : </strong>}
                name="codingQuestion"
              >
                <TextArea rows={6} placeholder="Enter Code " />
              </CustomInput>
            </Col>
          </Row>
          {questionType === "True/False" ? (
            <Row gutter={[30]}>
              <Col span={12}>
                <CustomInput
                  label={<strong>Option A : </strong>}
                  name="OptionA"
                  rules={[rules]}
                  initialValue={30}
                >
                  <Input placeholder="True" />
                </CustomInput>
              </Col>
              <Col span={12}>
                <CustomInput
                  label={<strong>Option B : </strong>}
                  name="OptionB"
                  rules={[rules]}
                  initialValue={30}
                >
                  <Input placeholder="False" />
                </CustomInput>
              </Col>
            </Row>
          ) : (
            <Row gutter={[30]}>
              <Col span={12}>
                <CustomInput
                  label={<strong>Option A : </strong>}
                  name="OptionA"
                  rules={[rules]}
                >
                  <TextArea placeholder="Option A" />
                </CustomInput>
              </Col>
              <Col span={12}>
                <CustomInput
                  label={<strong>Option B : </strong>}
                  name="OptionB"
                  rules={[rules]}
                >
                  <TextArea placeholder="Option B" />
                </CustomInput>
              </Col>
              <Col span={12}>
                <CustomInput
                  label={<strong>Option C : </strong>}
                  name="OptionC"
                  rules={[rules]}
                >
                  <TextArea placeholder="Option C" />
                </CustomInput>
              </Col>
              <Col span={12}>
                <CustomInput
                  label={<strong>Option D : </strong>}
                  name="OptionD"
                  rules={[rules]}
                >
                  <TextArea placeholder="Option D" />
                </CustomInput>
              </Col>
            </Row>
          )}
          <strong>Answer : </strong>
          <i>
            {questionType === "True/False"
              ? "Please select either option A or option B."
              : "Please choose from option A, option B, option C, or option D."}
          </i>
          <CustomSelect name="answer" rules={[rules]}>
            <Select
              placeholder="Please select Correct Answer"
              mode={questionType === "Checkbox" ? "multiple" : "default"}
              defaultValue={questionType === "Checkbox" ? [] : ""}
              style={{ width: "100%" }}
              allowClear
              options={
                questionType === "True/False"
                  ? answerFlagOptions[questionType]
                  : answerFlagOptions.default
              }
            />
          </CustomSelect>
          <CustomInput
            name="explanation"
            label={<strong>Explanation : </strong>}
            rules={[rules]}
          >
            <TextArea rows={6} placeholder="Explanation" />
          </CustomInput>
          <Form.Item
            className="checkingArea"
            name="questionPublicFlag"
            valuePropName="checked"
            rules={[rules]}
          >
            <Checkbox>Allow this question to be visible to the public</Checkbox>
          </Form.Item>
          <center>
            <CustomBtn
              htmlType="submit"
              type="submit"
              title="Add"
              loading={loadingQuestions}
              disable={loadingQuestions}
            />
          </center>
        </InfoForm>
      </Container>
    </Wrapper>
  );
};

export default AddQuizQuestion;
