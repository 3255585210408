import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/ApiRequest";
import { errorMsg, successMsg } from "../components/AlertMessage";

export const getUserSessionDetails = createAsyncThunk(
  "userInfoSession/getUserSessionDetails",
  async (
    { page, limit, sorter, filters, setPagination },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.getUserSessionDetails(page, limit);
      if (response.status === 200) {
        await setPagination((prev) => ({
          ...prev,
          page: response?.data?.pagination?.page,
          pageSize: response?.data?.pagination?.limit,
          total: response?.data?.pagination?.totalItems,
        }));
        return response?.data?.data;
      }
    } catch (error) {
      rejectWithValue(error);
      throw error;
    }
  }
);

export const fetchUserPaymentDetails = createAsyncThunk(
  "userInfoSession/fetchUserPaymentDetails",
  async ({ userId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getFetchUserIdAndPaymentDetails(
        userId
      );
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      rejectWithValue(error);
      throw error;
    }
  }
);
export const makeRoles = createAsyncThunk(
  "userInfoSession/makeRoles",
  async ({ data, onReset, userId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.makeRoles(data);
      if (response.status === 200) {
        onReset();
        successMsg(response?.data?.message);
        dispatch(fetchUserPaymentDetails({ userId }));
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg(error?.response?.data?.message);
      rejectWithValue(error);
      throw error;
    }
  }
);

export const UpdateUserPaymentStatus = createAsyncThunk(
  "userInfoSession/UpdateUserPaymentStatus",
  async (
    { data, onReset, paymentID, userId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.UpdateUserPaymentStatus(
        data,
        paymentID,
        userId
      );
      if (response.status === 200) {
        onReset();
        successMsg(response?.data?.message);
        dispatch(fetchUserPaymentDetails({ userId }));
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg(error?.response?.data?.message);
      rejectWithValue(error);
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  userSessionDetails: [],
  userPaymentDetails: [],
};

const userInfoSlice = createSlice({
  name: "userInfoSession",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserSessionDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserSessionDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userSessionDetails = action.payload;
      })
      .addCase(getUserSessionDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchUserPaymentDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserPaymentDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userPaymentDetails = action.payload;
      })
      .addCase(fetchUserPaymentDetails.rejected, (state) => {
        state.loading = false;
      })
      .addCase(UpdateUserPaymentStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateUserPaymentStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(UpdateUserPaymentStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(makeRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(makeRoles.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(makeRoles.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default userInfoSlice.reducer;
