import styled from "styled-components";

export const CardContainer = styled.div`
  .wrapper {
    margin: 2rem auto;
    border: 1px solid #d8d8d8;
    padding: 2em 2.5em;
    transition: 200ms;

    .contentWrapper {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      color: #181059;

      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
    }
    .title,
    .amount {
      margin: 0 0 1rem 0;
    }

    .featureTitle {
      font-size: 1rem;
    }
    #disable {
      pointer-events: none;
      color: gray;
      text-decoration: none;
      cursor: not-allowed;
    }

    h1 {
      margin-bottom: 0.5em;
      font-size: 24px;
      color: #313131;
    }
    span {
      font-size: 1.2em;
      margin-right: 0.3em;
    }

    strong {
      font-weight: 900;
      font-size: 26px;
      color: #181059;

      .month {
        font-size: 16px;
        font-weight: 400;
        color: #181059;
        margin-left: 0.3em;
        opacity: 0.7;
      }
    }

    p {
      font-weight: 400;
      font-size: 1em;
      display: flex;
      margin-bottom: 0.5em;
      span {
        font-size: 1.2em;
        color: #181059;
        margin-top: -0.2em;
        margin-right: 0.3em;
      }
    }

    .choose {
      background: #ffffff;
      border: 1px solid #d8d8d8;
      font-weight: 900;
      font-size: 1.1em;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 0.5em;
      height: 48px;
      border-radius: 0;
      color: #d39331;
      &:focus,
      &:hover {
        background: #ffffff;
        border-radius: 0;
        border: 1px solid #d39331;
        opacity: 0.9;
      }
    }
    &:hover {
      border: 1px solid #3f6fd8;
      background: #3f6fd8;
      color: #ffffff;
      p {
        div,
        span {
          color: #ffff;
        }
      }

      h1 {
        color: #ffffff;
      }
      strong {
        color: #ffffff;
        span {
          color: #ffffff;
        }
      }
      .choose {
        color: #3f6fd8;
      }
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
    @media (max-width: 768px) {
      min-width: 100%;
    }
  }
`;
