import { Container } from "react-bootstrap";
import { TableContainer } from "../../Forum/manageTopic/index.style";
import { CustomSelect } from "../../../common/select/index.style";
import { useEffect, useState } from "react";
import { Select, Skeleton, Space } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCourses } from "../../../redux/courseSlice";
import { paginate } from "../../../utils/helper";
import CustomPagination from "../../../common/pagination";
import { BsChevronLeft } from "react-icons/bs";
import { BackBtn, Wrapper } from "./index.style";
import { AssignmentsFilteredReview } from "../../../redux/assignmentsResSlice";
import { Helmet } from "react-helmet";

function AssignmentsReview() {
  const pageSize = 10;
  const navigate = useNavigate();
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { adminCourses } = useSelector((state) => state?.course);
  const { loading, filterAndMatchAssignments } = useSelector(
    (state) => state?.assignmentsRes
  );
  const [isSkeleton, setSkeleton] = useState(false);

  useEffect(() => {
    dispatch(getAdminCourses());
  }, [dispatch]);

  useEffect(() => {
    setSkeleton(!Object.keys(adminCourses).length > 0);
  }, [adminCourses]);

  useEffect(() => {
    if (courseId) {
      setSelectedCourse(courseId);
      // dispatch(lecturerAssignmentReview(adminCourses?.[0]?._id));
      dispatch(AssignmentsFilteredReview(courseId));
    }
  }, [courseId, dispatch]);

  const courseOptions =
    adminCourses?.map((course) => ({
      value: course?._id,
      label: course?.longName,
    })) || [];

  const dataSource = [];

  // const assignmentData = assignmentReview?.filter((state) =>
  //   ["Submitted"].includes(state.status)
  // );
  const assignmentData = filterAndMatchAssignments?.filter((state) =>
    ["Submitted"].includes(state.status)
  );

  paginate(assignmentData || [], currentPage, pageSize)?.map((review, i) => {
    dataSource.push({
      key: i,
      studentName: review?.name,
      email: review?.email,
      assignment: review?.assignmentName,
      status: (
        <div
          style={{
            color: review?.status === "Submitted" ? "#00AC47" : "#2AB2DB",
          }}
        >
          {review?.status}
        </div>
      ),
      actions: (
        <Space size="middle" className="deleteBtn">
          <Link
            to={`/lecturer/assignments/${review?.resId}/${review?.assignmentId}/tasks/review`}
          >
            <img
              src="/icons/pen-icon.svg"
              alt="i"
              width="19px"
              height="19px"                                   
              onError={(e) => {
                e.target.style.display = "none";
              }}
            />
          </Link>
        </Space>
      ),
    });
  });

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "studentName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Assignment",
      dataIndex: "assignment",
      key: "assignment",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Lecturer | Assignments Review </title>
      </Helmet>
      <Container style={{ margin: "20px 0px" }} fluid>
        <Skeleton active loading={isSkeleton}>
          <div gutter={100} className="mainHeading">
            <div>
              <h2>Reviewer Dashboard</h2>
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  placeholder="-- Select Course --"
                  defaultValue={selectedCourse}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(id) => {
                    setSelectedCourse(id);
                    navigate(`/lecturer/assignments/${id}/review`);
                    // dispatch(lecturerAssignmentReview(id));
                    dispatch(AssignmentsFilteredReview(id));
                  }}
                  options={courseOptions}
                />
              </CustomSelect>
              <div>
                <BackBtn
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <span>
                    <BsChevronLeft />
                  </span>
                  <span>Go back</span>
                </BackBtn>
              </div>

              <div className="mb-3">
                <h3>Student Assignments</h3>
                <span className="total">
                  {assignmentData.length} assignments
                </span>
              </div>
            </div>
          </div>

          <TableContainer
            loading={loading}
            dataSource={dataSource}
            columns={columns}
          />
          {assignmentData.length > 10 ? (
            <CustomPagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={assignmentData.length}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          ) : null}
        </Skeleton>
      </Container>
    </Wrapper>
  );
}

export default AssignmentsReview;
