import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Spin } from "antd";
import { BsCheck } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { CardContainer } from "./index.style";
import { useDispatch } from "react-redux";
import { HandlePayment, MakePayment } from "../../../stripe";
import { useLocation, useNavigate } from "react-router-dom";
// import ReactGA from "react-ga4";
import { AnalyticsEvents, pushPurchaseEvent } from "../../../App";
// import CustomImage from "../../../common/images";

const QuizPack = ({ plan, planPeriod }) => {
  const [loading, setLoading] = useState(false);
  const [isToken, setToken] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [geolocation, setGeolocation] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const authUser = JSON?.parse(
      localStorage.getItem("authUser") &&
        localStorage.getItem("authUser") != "undefined"
        ? localStorage.getItem("authUser")
        : null
    );
    const token = authUser && authUser?.token;
    setToken(!!token && true);
  }, []);

  const product = useMemo(
    () => ({
      name: plan.title,
      payment: plan.payment,
      quantity: 1,
      price_id: process.env[plan.price_id],
      currentURL: new URL(window.location.href).origin,
    }),
    [plan]
  );

  // eslint-disable-next-line no-unused-vars
  const getGeolocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGeolocation(
            `${position.coords.latitude},${position.coords.longitude}`
          );
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation not supported");
    }
  };
  const indianProducts = useMemo(
    () => ({
      name: plan.title,
      payment: plan.payment,
      planId: plan.planId,
      quantity: 1,
      amount: plan.amount,
      currency: plan.currency,
      currentURL: new URL(window.location.href).origin,
    }),
    [plan]
  );

  const handleChoose = ({
    category,
    action,
    label,
    transactionId,
    value,
    currency,
    items,
  }) => {
    if (isToken) {
      AnalyticsEvents({ category, action, label, value });
      pushPurchaseEvent(transactionId, value, currency, items);
      if (planPeriod === "internationalPlans") {
        MakePayment(dispatch, product, setLoading);
      } else if (planPeriod === "indiaPlans") {
        HandlePayment(dispatch, indianProducts, setLoading);
      }
    } else {
      navigate(`/login/?returnURL=${location.pathname + location.search}`);
    }
  };

  return (
    <CardContainer>
      <img
        src="/images/new.svg"
        width="77px"
        height="77px"
        alt="New"
        style={{
          position: "absolute",
          top: "14px",
          left: "-15px",
          zIndex: "1",
          transform: "scale(1.02) rotateY(0deg) rotateX(0deg)",
          transition: "transform 0.4s ease",
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.transform =
            "scale(1.1) rotateY(10deg) rotateX(5deg)";
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.transform =
            "scale(1.02) rotateY(0deg) rotateX(0deg)";
        }}
      />

      <div className="wrapper">
        <div className="contentWrapper">
          <h1 className="title">{plan.title}</h1>
          <div className="amount">
            <strong>
              {plan.price}
              <span
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  opacity: 0.7,
                  marginLeft: "0.3em",
                }}
              >
                {plan.GST}{" "}
              </span>
              {/* <span className="month">/month</span> */}
            </strong>
          </div>
        </div>
        <Row>
          <Col lg={19}>
            <Row gutter={[20, 10]}>
              {plan.features?.map(({ id, included, feature }, index) => (
                <Col lg={8} md={12} xs={24} key={id}>
                  <p
                    key={index}
                    style={{
                      color: `${
                        included ? "#181059" : "rgba(24, 16, 89, 0.4)"
                      }`,
                    }}
                  >
                    <span>
                      {included ? (
                        <BsCheck />
                      ) : (
                        <IoIosClose
                          style={{
                            color: "rgba(24, 16, 89, 0.4)",
                          }}
                        />
                      )}
                    </span>
                    <div
                      className="featureTitle"
                      dangerouslySetInnerHTML={{ __html: feature }}
                    />
                  </p>
                </Col>
              ))}
            </Row>{" "}
          </Col>
          <Col xs={24} lg={5}>
            <div id="purchase">
              <div id={plan.id}>
                <div
                  id="choose-plan"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleChoose({
                      transactionId: plan.id,
                      currency: plan.currency,
                      items: [
                        {
                          item_id: `SKU_${Date.now()}`,
                          item_name: plan.title,
                          price: Number(plan.amount),
                          quantity: 1,
                        },
                      ],
                      label: plan.title,
                      value: Number(plan.amount),
                      category: `${plan.title} ~ ${plan.price}`,
                      action: `Click: ${plan.title} ${plan.price}`,
                    })
                  }
                >
                  <div className="choose"> {loading ? <Spin /> : "Choose"}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </CardContainer>
  );
};

export default QuizPack;
