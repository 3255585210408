import React, { useEffect, useState } from "react";
import { Button, Result, Skeleton, Spin } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { successfulPaymentStorage } from "../redux/stripeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { AnalyticsEvents } from "../App";

const Success = () => {
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(null);
  const [actionId, setActionId] = useState(null);
  const [spinning, setSpinning] = useState(true);
  const [searchParams] = useSearchParams();
  const { sessionStorage, loading } = useSelector((state) => state?.stripe);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setSpinning(loading);
  }, [loading]);

  useEffect(() => {
    const sessionIdParam = searchParams.get("session_id");
    const action = searchParams.get("action") || null;
    if (action === "razorPay") {
      const sign = searchParams.get("s_id");
      const orderSubId = searchParams.get("o_s_id");
      const paymentData = {
        razorpay_order_subscrip_id: orderSubId,
        razorpay_payment_id: sessionIdParam,
        razorpay_signature: sign,
        action,
      };
      setActionId(action);
      dispatch(successfulPaymentStorage(paymentData));
    }
    if (sessionIdParam) {
      const cleanedSessionId = sessionIdParam.replace("$", "");
      setSessionId(cleanedSessionId);
      const action = searchParams.get("action") || null;
      setActionId(action);
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (sessionId && actionId === "stripe") {
      const data = { sessionId, action: actionId };
      dispatch(successfulPaymentStorage(data));
    }
  }, [sessionId, actionId, dispatch]);

  const success =
    (sessionStorage?.paymentStatus === "paid" ||
      sessionStorage?.paymentStatus === "active" ||
      sessionStorage?.paymentStatus === "captured") &&
    sessionStorage?.successOrErrorMessage === "Payment successful" &&
    !!sessionStorage?.email;

  if (success) {
    AnalyticsEvents({
      category: "payment-successful",
      action: "purchased plans",
      label: `${sessionStorage?.packageName} Plan`,
      value: sessionStorage?.value,
    });
  }
  return (
    <Spin spinning={spinning}>
      <Helmet>
        <title>
          Tansy Academy | {success ? "Success" : loading ? "Loading" : "Error"}
        </title>
      </Helmet>

      <Result
        id={success ? "plan-purchase-successful" : "plan-purchase-error"}
        status={spinning ? "info" : success ? "success" : "error"}
        title={
          spinning ? (
            <Skeleton.Input
              paragraph={{ rows: 1 }}
              active={spinning}
              block={spinning}
            />
          ) : success ? (
            `Successfully Purchased ${sessionStorage?.packageName} Plan`
          ) : (
            "Error"
          )
        }
        subTitle={
          spinning ? (
            <Skeleton.Input
              paragraph={{ rows: 1 }}
              active={spinning}
              block={spinning}
            />
          ) : success ? (
            `Order number: ${
              sessionId ? sessionId : ""
            }. Cloud server configuration takes 1-2 minutes, please wait. Check your email: ${
              sessionStorage.email
            }.`
          ) : (
            "There was an error processing your payment. Please try again later."
          )
        }
        extra={[
          <Link
            to={
              success
                ? "/courses/sql_database_programming_tutorial_courses"
                : "/contact"
            }
            key="console"
          >
            <Button
              loading={spinning}
              type="button"
              style={{
                backgroundColor: "#d39331",
                color: "#fff",
                width: "130px",
                border: hovered ? "1px solid #d39331" : "none",
              }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {success ? "Go to Courses" : "Contact Us"}
            </Button>
          </Link>,
          <Link
            to="/learn_sql_power_bi_analytics_online_training_cost"
            key="buy"
          >
            <Button loading={spinning}>Back to Previous</Button>
          </Link>,
          <Link to="/permissions" key="permissions">
            <Button loading={spinning}>Screen Permissions</Button>
          </Link>,
        ]}
      />
    </Spin>
  );
};

export default Success;
