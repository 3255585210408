import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 2em 4em;
  .mainHeading {
    display: flex;
    padding: 0 0 2em 0;

    h2 {
      margin: 1em 0;
    }
    h3 {
      text-transform: uppercase;
      color: #313131;
      font-weight: 600;
      font-size: 22px;
    }
    .total {
      font-weight: 400;
      color: #31313160;
      font-size: 16px;
    }
    .ant-form-item {
      width: 400px;
    }
  }
`;

export const BackBtn = styled("span")`
  display: inline-flex;
  align-items: center;
  color: #3f6fd8;
  font-weight: 600;
  margin-bottom: 2em;
  margin-top: 2em;
  cursor: pointer;
  svg {
    margin-right: 0.5em;
    font-size: 1.3em;
  }
`;