import React from "react";
import { Container, Section } from "./index.style";
import { Link } from "react-router-dom";
import metaHelmetData from "../../components/json/metaHelmetData.json";
import MetaHelmet from "../../common/helmet";

function TermsConditions() {
  return (
    <Container>
      <MetaHelmet {...metaHelmetData.terms_conditions} />
      <div className="container">
        <div className="row">
          <h1 className="heading">Terms & Conditions</h1>
        </div>
      </div>
      <Section>
        <TermsList />
      </Section>
    </Container>
  );
}

function TermsList() {
  const sections = [
    {
      title: "1. Acceptance of Terms",
      content: `Welcome to Tansy Academy. By accessing or using our services, 
        you agree to comply with and be bound by the following Terms of Service. 
        If you disagree with any part of these terms, please do not use our website.`,
    },
    {
      title: "2. Use of Services",
      content: [
        {
          sub_title: "2.1 Registration and Login",
          list: [
            "To access certain features of our services, you will have to signup and login via your gmail or outlook accounts.",
            "We do not store your passwords in our system.",
            "You are solely responsible for maintaining the confidentiality of your gmail and outlok password.",
          ],
        },
        {
          sub_title: "2.2 Prohibited Activities",
          text: "You agree not to:",
          list: [
            "Use our services for any illegal or unauthorized purpose.",
            "Transmit any harmful code, viruses, or disruptive content.",
            "Attempt to gain unauthorized access to our systems.",
            "Engage in any activity that could disrupt the integrity or performance of the services.",
          ],
        },
      ],
    },
    {
      title: "3. Intellectual Property",
      content: [
        {
          sub_title: "3.1 Content Ownership",
          list: [
            "Our services may offer content downloads, yet the ownership of this content remains with us. You are prohibited from claiming ownership of any downloaded content obtained through our services.",
          ],
        },
        {
          sub_title: "3.2 Trademarks",
          list: [
            "The trademarks, logos, and service marks visible on the website belong to Tansy Cloud and Tansy Academy. Any use of these trademarks requires prior written permission from the rightful owner.",
          ],
        },
      ],
    },
    {
      title: "4. Privacy",
      content: [
        {
          sub_title: "4.1 Data Collection and Usage",
          list: [
            "Our Privacy Policy explains how we collect, use, and protect your personal information.",
            "By using our services, you consent to the data practices described in our Privacy Policy.",
          ],
        },
      ],
    },
    {
      title: "5. Termination",
      content: [
        {
          sub_title: "5.1 Termination by Us",
          list: [
            "We reserve the right to suspend or terminate your account if you violate these Terms of Service.",
            "Upon termination, your right to access and use our services will cease immediately.",
          ],
        },
      ],
    },
    {
      title: "6. Disclaimer of Warranties",
      content: `We provide our services 'as is' and 'as available.' 
        We do not warrant that our services will be uninterrupted,
        error-free, or free from harmful components.`,
    },
    {
      title: "7. Contact Us",
      content:
        "If you have any questions or concerns regarding these Terms of Service, please ",
      subcontent: <Link to="/contact">Contact Us.</Link>,
    },
  ];

  return (
    <div>
      {sections?.map((section, index) => (
        <div key={index}>
          <div className="title">{section.title}</div>
          {Array.isArray(section.content) ? (
            <SubSections content={section.content} />
          ) : (
            <div className="wrapper">
              <p>{section.content}</p>
              <span>{section.subcontent}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function SubSections({ content }) {
  return (
    <div>
      {content?.map((subSection, subIndex) => (
        <div key={subIndex}>
          <div className="sub_title">{subSection.sub_title}</div>
          <div className="sub_text">{subSection.text}</div>
          {subSection.list ? (
            <ul>
              {subSection.list?.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          ) : (
            <p>{subSection.text}</p>
          )}
        </div>
      ))}
    </div>
  );
}

export default TermsConditions;
