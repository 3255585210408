import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg, successMsg } from "../components/AlertMessage";
import { ApiRequests } from "../services/ApiRequest";

export const getCourseSyllabus = createAsyncThunk(
  "interviewQuestions/getCourseSyllabus",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getCourseSyllabus(courseId);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const getInterviewQuestionsCount = createAsyncThunk(
  "interviewQuestions/getInterviewQuestionsCount",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getInterviewQuestionsCount(courseId);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const getInterviewQuestions = createAsyncThunk(
  "interviewQuestions/getInterviewQuestions",
  async (Id, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getInterviewQuestions(Id);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const getPublicInterviewQuestions = createAsyncThunk(
  "interviewQuestions/getPublicInterviewQuestions",
  async ({ courseName, interviewShortName }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getPublicInterviewQuestions(
        courseName,
        interviewShortName
      );
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const addInterviewQuestion = createAsyncThunk(
  "interviewQuestions/addInterviewQuestion ",
  async (
    { formDataObject, courseId, onReset },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.addInterviewQuestion(formDataObject);
      if (response.status === 201) {
        onReset();
        dispatch(getInterviewQuestionsCount(courseId));
        successMsg("Question Added!");
        return response?.data?.data;
      } else {
        errorMsg("Please try again.");
      }
    } catch (error) {
      errorMsg(error);
      throw rejectWithValue(error);
    }
  }
);

export const handleDeleteQuestion = createAsyncThunk(
  "interviewQuestions/handleDeleteQuestion",
  async ({ Id, QuestionId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.handleDeleteQuestion(Id, QuestionId);
      if (response.status === 200) {
        dispatch(getInterviewQuestions(Id));
        successMsg("Question Deleted!");
        return response;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);
export const handleUpdateQuestion = createAsyncThunk(
  "interviewQuestions/handleUpdateQuestion",
  async ({ Id, QuestionId, data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.handleUpdateQuestion(
        Id,
        QuestionId,
        data
      );
      if (response.status === 200) {
        dispatch(getInterviewQuestions(Id));
        successMsg("Question Updated!");
        return response;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

const initialState = {
  syllabusLoading: false,
  loading: false,
  courseData: {},
  questionsList: {},
  interviewQuestions: [],
  publicInterviewQuestions: {},
};

const interviewQuestionsSlice = createSlice({
  name: "interviewQuestions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleDeleteQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleDeleteQuestion.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(handleDeleteQuestion.rejected, (state) => {
        state.loading = false;
      })
      .addCase(handleUpdateQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleUpdateQuestion.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(handleUpdateQuestion.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getInterviewQuestionsCount.pending, (state) => {
        state.interviewQuestions = [];
        state.loading = true;
      })
      .addCase(getInterviewQuestionsCount.fulfilled, (state, action) => {
        state.interviewQuestions = action.payload;
        state.loading = false;
      })
      .addCase(getInterviewQuestionsCount.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPublicInterviewQuestions.pending, (state) => {
        state.publicInterviewQuestions = {};
        state.loading = true;
      })
      .addCase(getPublicInterviewQuestions.fulfilled, (state, action) => {
        state.publicInterviewQuestions = action.payload;
        state.loading = false;
      })
      .addCase(getPublicInterviewQuestions.rejected, (state) => {
        state.publicInterviewQuestions = {};
        state.loading = false;
      })
      .addCase(getCourseSyllabus.pending, (state) => {
        state.syllabusLoading = true;
      })
      .addCase(getCourseSyllabus.fulfilled, (state, action) => {
        state.courseData = action.payload;
        state.syllabusLoading = false;
      })
      .addCase(getCourseSyllabus.rejected, (state) => {
        state.syllabusLoading = false;
      })
      .addCase(getInterviewQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInterviewQuestions.fulfilled, (state, action) => {
        state.questionsList = action.payload;
        state.loading = false;
      })
      .addCase(getInterviewQuestions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addInterviewQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(addInterviewQuestion.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addInterviewQuestion.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default interviewQuestionsSlice.reducer;
