import { Wrapper } from "../Forum/manageTopic/index.style";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BackBtn } from "../Forum/forumlList/threadQuestion/index.style";
import { BsChevronLeft } from "react-icons/bs";
import { Row, Col, Card, Menu, Skeleton } from "antd";
import { getPublicInterviewQuestions } from "../../redux/interviewQuestionsSlice";
import sections from "../../common/sitemap/xmlContent.json";
import { HoverCard, StyledMenu } from "./index.style";
import { Container } from "react-bootstrap";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";


const PublicInterviewQuestions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const courseName = searchParams.get("course");
  const interviewShortName = searchParams.get("topic");
  const { publicInterviewQuestions, loading } = useSelector(
    (state) => state?.interviewQuestions
  );
  const [loader, setLoader] = useState(true);
  const [localDataSource, setLocalDataSource] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const titleData = `${courseName} Database Interview Questions`;

  const SidebarMenu = () => {
    const menuRef = useRef(null);
    const currentUrl = window.location.href;
    const menuItems = useMemo(
      () =>
        sections
          ?.filter(
            (section) =>
              section?.title?.toLowerCase() === titleData?.toLowerCase()
          )
          ?.flatMap(
            ({ content }) =>
              content?.flatMap(
                (item) =>
                  item?.subContent?.map((subItem) => ({
                    key: subItem?.contentUrl,
                    label: (
                      <Link className="contentUrl" to={subItem?.contentUrl}>
                        {subItem?.contentTitle}
                      </Link>
                    ),
                    url: subItem?.contentUrl,
                  })) || []
              ) || []
          ) || [],
      []
    );

    useEffect(() => {
      const match = menuItems?.find((item) => item?.url === currentUrl);
      setSelectedKey(match ? match?.key : "");

      if (match && menuRef.current) {
        const menuItem = menuRef.current.querySelector(`[url="${currentUrl}"]`);
        if (menuItem) {
          // menuItem.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [menuItems, currentUrl]);

    return (
      <StyledMenu ref={menuRef}>
        <Menu
          style={{ width: 150 }}
          selectedKeys={[selectedKey]}
          mode="vertical"
          theme="light"
          items={menuItems}
        />
      </StyledMenu>
    );
  };

  useEffect(() => {
    if (courseName && interviewShortName) {
      dispatch(getPublicInterviewQuestions({ courseName, interviewShortName }));
    } else if (!courseName && !interviewShortName) {
      setLoader(false);
    }
  }, [dispatch, courseName, interviewShortName]);

  useEffect(() => {
    if (
      publicInterviewQuestions?.lessonName &&
      publicInterviewQuestions?.courseName
    ) {
      document.title = `${publicInterviewQuestions?.courseName} - ${publicInterviewQuestions?.lessonName} : Interview Questions`;
    }
    const metaDescription =
      document.querySelector("meta[name='description']") ||
      document.createElement("meta");
    const description =
      "Prepare for your SQL job interview with our comprehensive guide of top SQL interview questions and answers. Master basic to advanced SQL concepts and boost your confidence for success.";
    metaDescription.setAttribute("name", "description");
    metaDescription.setAttribute("content", description);
    document.head.appendChild(metaDescription);

    const link =
      document.querySelector("link[rel='canonical']") ||
      document.createElement("link");
    link.setAttribute("rel", "canonical");
    link.setAttribute("href", window.location.href);
    document.head.appendChild(link);
    let icon =
      document.querySelector("link[rel='icon']") ||
      document.head.appendChild(document.createElement("link"));
    icon.rel = "icon";
    icon.href = "/images/logo.svg";
    return () => {
      document.title = "Tansy Academy";
      if (metaDescription) metaDescription.setAttribute("content", "");
    };
  }, [publicInterviewQuestions, interviewShortName]);

  const questionsLength =
    publicInterviewQuestions?.interviewQuestion?.Questions?.length || 0;

  const { questionsBank, dataSource } = useMemo(() => {
    const bank = [];
    const source = [];
    (publicInterviewQuestions?.interviewQuestion?.Questions || [])?.forEach(
      (data, i) => {
        bank.push(data?.questionName);
        source.push({
          key: i,
          questionName: data?.questionName,
          answer: data?.answer,
        });
      }
    );
    return { questionsBank: bank, dataSource: source };
  }, [publicInterviewQuestions]);

  useEffect(() => {
    setLoader(loading);
    if (loading) {
      setLocalDataSource([]);
    } else {
      setLocalDataSource(dataSource);
    }
  }, [loading, dataSource]);

  useEffect(() => {
    const metaKeywords =
      document.querySelector("meta[name='keywords']") ||
      document.createElement("meta");
    metaKeywords.setAttribute("name", "keywords");
    metaKeywords.setAttribute(
      "content",
      JSON.stringify(questionsBank).slice(1, -1)
    );
    document.head.appendChild(metaKeywords);

    return () => {
      metaKeywords.setAttribute("content", "");
    };
  }, [questionsBank]);

  const saveData = useMemo(
    () => [
      {
        title: `${courseName} Database Interview Questions`,
        course: (
          <Link
            to={`/course_detail?name=${publicInterviewQuestions?.courseName}`}
          >
            {publicInterviewQuestions?.courseName}
          </Link>
        ),
        chapter: publicInterviewQuestions?.chapterName,
        lesson: (
          <Link to={publicInterviewQuestions?.lessonUrl || "#"}>
            {publicInterviewQuestions?.lessonName || ""}
          </Link>
        ),
        questionsCount: questionsLength,
      },
    ],
    [courseName, questionsLength, publicInterviewQuestions]
  );

 const customStyle = {
   ...coy,
   'code[class*="language-"]': {
     ...coy['code[class*="language-"]'],
     background: "white",
     color: "black",
     wordWrap: "break-word",
     whiteSpace: "pre-wrap",
     fontSize: "16.5px",
     fontFamily: "Source Sans Pro",
   },
   'code[class*="language-"] .token': {
     ...coy['code[class*="language-"] .token'],
     color: "#007ACC",
     fontSize: "16.5px",
     fontFamily: "Source Sans Pro",
   },
   'pre[class*="language-"]': {
     ...coy['pre[class*="language-"]'],
     background: "white",
     color: "black",
     fontSize: "16.5px",
     fontFamily: "Source Sans Pro",
     // padding: "1em",
     // border: "1px solid #ddd",
     // borderRadius: "5px",
     wordWrap: "break-word",
     whiteSpace: "pre-wrap",
   },
 };
  
  const CustomCard = ({ data, index }) => (
    <HoverCard>
      <Card.Meta
        title={
          <p>
            <strong style={{ marginRight: "5px", fontSize: "20px" }}>
              {index + 1}. Question:
            </strong>
            <span
              style={{
                whiteSpace: "pre-line",
                fontSize: "18.5px",
                fontWeight: 600,
                fontFamily: "Source Sans Pro",
              }}
            >
              {data?.questionName}
            </span>
          </p>
        }
        description={
          <p style={{ marginBottom: "5px" }}>
            <strong style={{ marginRight: "5px", fontSize: "20px" }}>
              Explanation:
            </strong>
            <SyntaxHighlighter
              language="sql"
              style={customStyle}
              wrapLongLines={true}
            >
              {data?.answer}
            </SyntaxHighlighter>
            {/* <span
              style={{
                whiteSpace: "pre-line",
                fontSize: "16.5px",
                fontFamily: "Source Sans Pro",
              }}
            >
              {data?.answer}
            </span> */}
          </p>
        }
      />
    </HoverCard>
  );

  return (
    <Wrapper>
      <Container>
        <div className="mainHeading">
          {saveData?.map((item, index) => (
            <div key={index}>
              <h1
                style={{ fontSize: "1.2rem", fontWeight: 700 }}
                className="mt-3 mb-3"
              >
                {item?.title}
              </h1>
              <p className="mt-3 mb-3">
                Course: &nbsp;&nbsp;&nbsp;<strong>{item?.course}</strong>
              </p>
              <p className="mt-3 mb-3">
                Chapter: &nbsp;&nbsp;&nbsp;<strong>{item?.chapter}</strong>
              </p>
              <p className="mt-3 mb-3">
                Lesson Content Link: &nbsp;&nbsp;&nbsp;
                <strong>{item?.lesson}</strong>
              </p>
              <p className="mt-3 mb-3">
                Questions Count: &nbsp;&nbsp;&nbsp;
                <strong style={{ color: "#00AC47" }}>
                  {item?.questionsCount}
                </strong>
              </p>
              <BackBtn
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="mt-4"
              >
                <BsChevronLeft />
                <span>Go back</span>
              </BackBtn>
            </div>
          ))}
        </div>
        <Row gutter={10}>
          <Col xs={24} md={20}>
            {loader
              ? Array.from({ length: 10 }).map((_, i) => (
                  <HoverCard>
                    <Skeleton active paragraph={{ rows: 4 }} key={i} />
                  </HoverCard>
                ))
              : localDataSource.map((data, index) => (
                  <CustomCard data={data} index={index} key={index} />
                ))}
          </Col>
          <Col xs={0} md={4}>
            <h6 style={{ marginTop: "25px" }}>Explore More Topics...</h6>
            <div
              style={{
                marginTop: "20px",
                height: 1800,
                overflow: "auto",
                scrollbarWidth: "thin",
                scrollbarColor: "transparent transparent",
              }}
            >
              <SidebarMenu sections={sections} />
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default PublicInterviewQuestions;
