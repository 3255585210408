import { Col, Form, Modal, Row, Select, Skeleton, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllLiveClasses,
  getSingleLiveClasses,
  lecturerStudentsPair,
  removeLecturerStudentPair,
} from "../../../../redux/liveClassSlice.js";
import {
  getLecturerDetails,
  getLecturerList,
} from "../../../../redux/userSlice.js";
import { studentPairSchema } from "../../../../utils/validationSchema.js";
import { antValidator, paginate } from "../../../../utils/helper.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  TableContainer,
  Wrapper,
} from "../../../Forum/manageTopic/index.style.js";
import { Container } from "react-bootstrap";
import { InfoForm } from "../../../Forum/manageTopic/topicDetails/index.style.js";
import { CustomSelect } from "../../../../common/select/index.style.js";
import CustomPagination from "../../../../common/pagination/index.jsx";
import CustomBtn from "../../../../common/button/index.jsx";
import { Helmet } from "react-helmet";

const LecturerStudentsPairing = () => {
  const pageSize = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { classId } = useParams();
  const [rules, setRules] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLiveClass, setSelectedLiveClass] = useState("");
  const [selectedLecturer, setselectedLecturer] = useState("");
  const { allLiveClasses, singleLiveClasses, loading } = useSelector(
    (state) => state?.liveClass
  );
  const { lecturerList } = useSelector((state) => state?.user);
  const [isSkeleton, setSkeleton] = useState(true);
  const { confirm } = Modal;
  const [selectedStudent, setSelectedStudent] = useState("");

  useEffect(() => {
    dispatch(getAllLiveClasses());
    dispatch(getLecturerList());
  }, [dispatch]);

  useEffect(() => {
    if (classId) {
      dispatch(getSingleLiveClasses(classId));
    }
  }, [classId, dispatch]);

  useEffect(() => {
    setSkeleton(!Object.keys(singleLiveClasses).length > 0);
    setSelectedLiveClass(singleLiveClasses?._id);
    setRules(antValidator(studentPairSchema));
  }, [singleLiveClasses]);

  const assignedStudents = [];
  const pairingList = [];
  singleLiveClasses?.Lecturer?.forEach((lecturer) => {
    lecturer?.Student?.forEach((student) => {
      assignedStudents?.push(student?.studentId);
      pairingList?.push({
        lecturerId: lecturer?.lecturerId,
        studentId: student?.studentId,
      });
    });
  });

  const UnassignedStudents =
    (assignedStudents &&
      singleLiveClasses?.Student?.filter(
        (student) => !assignedStudents.includes(student?.userId)
      )?.map((student) => ({
        value: student?.userId,
        label: student?.name,
      }))) ||
    [];

  const handleFormReset = () => {
    form.resetFields();
  };

  const classList =
    allLiveClasses?.results?.map((liveclass) => ({
      value: liveclass?._id,
      label: liveclass?.className,
    })) || [];

  const lecturerOptions =
    lecturerList?.map((lecturer) => ({
      value: lecturer?._id,
      label: lecturer?.name,
    })) || [];

  const matchingLecturer = (studentId) => {
    const lecturerId = pairingList?.find(
      (pair) => pair?.studentId === studentId
    )?.lecturerId;
    const matchedLecturer = lecturerOptions?.find(
      (lecturer) => lecturer?.value === lecturerId
    );
    return matchedLecturer
      ? {
          matchedValue: (
            <div style={{ color: "#00AC47" }}>{matchedLecturer?.label}</div>
          ),
          matched: true,
        }
      : {
          matchedValue: <div style={{ color: "#C91D2E" }}>Not assigned</div>,
          matched: false,
        };
  };

  const getDeleteData = (studentId) => {
    const lecturerId = pairingList?.find(
      (pair) => pair?.studentId === studentId
    )?.lecturerId;
    const matchedLecturer = lecturerOptions?.find(
      (lecturer) => lecturer?.value === lecturerId
    );
    return (
      matchedLecturer && {
        liveClassId: selectedLiveClass,
        studentId,
        lecturerId: matchedLecturer?.value,
      }
    );
  };

  const dataSource = [];
  paginate(singleLiveClasses?.Student || [], currentPage, pageSize)?.map(
    (classItem, i) => {
      dataSource.push({
        key: classItem?.userId,
        studentName: classItem?.name,
        lecturer: matchingLecturer(classItem?.userId)?.matchedValue,
        actions: (
          <Space size="middle" className="deleteBtn">
            {matchingLecturer(classItem?.userId)?.matched && (
              <Link
                onClick={() =>
                  confirmDeleteLiveClass(getDeleteData(classItem?.userId))
                }
              >
                <img
                  src="/icons/bin-icon.svg"
                  alt="i"
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </Link>
            )}
          </Space>
        ),
      });
    }
  );

  const confirmDeleteLiveClass = (data) => {
    const { liveClassId, studentId, lecturerId } = data;
    const confirmModal = confirm({
      title: "Do you want to delete this Pair?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        try {
          await dispatch(
            removeLecturerStudentPair({ liveClassId, studentId, lecturerId })
          );
        } catch (error) {
          console.error("Error deleting Pair:", error);
        } finally {
          confirmModal.destroy();
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "studentName",
    },
    {
      title: "Lecturer",
      dataIndex: "lecturer",
      key: "lecturer",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const handleSubmit = async (values) => {
    const formDataObject = {
      liveClassId: selectedLiveClass,
      lecturerId: values?.lecturerId,
      studentId: values?.studentId,
    };

    dispatch(
      lecturerStudentsPair({
        classId,
        formDataObject,
        onReset: handleFormReset,
      })
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Lecturer Students Pairing </title>
      </Helmet>
      <Container style={{ margin: "20px 0px" }} fluid>
        <Skeleton active loading={isSkeleton}>
          <div className="mainHeading mb-4">
            <div>
              <h3>ASSIGN </h3>
              <span className="total">
                {singleLiveClasses?.Student?.length} ASSIGN{" "}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <CustomSelect>
                <Select
                  key={selectedLiveClass}
                  showSearch
                  placeholder="-- Select Live Class --"
                  defaultValue={selectedLiveClass}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(id) => {
                    dispatch(getSingleLiveClasses(id));
                    navigate(`/admin/lecturer/students/${id}/pair`);
                    setSelectedLiveClass(id);
                  }}
                  options={classList}
                />
              </CustomSelect>
            </div>
          </div>

          <InfoForm
            layout="vertical"
            style={{ borderBottom: "0px solid #ffff" }}
            onFinish={handleSubmit}
            form={form}
          >
            <Row gutter={32}>
              <Col span={8}>
                <CustomSelect name="lecturerId" rules={[rules]}>
                  <Select
                    key={selectedLecturer}
                    showSearch
                    value={selectedLecturer}
                    placeholder="-- Select Lecturer --"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(id) => {
                      dispatch(getLecturerDetails(id));
                      setselectedLecturer(id);
                    }}
                    options={lecturerOptions}
                  />
                </CustomSelect>{" "}
              </Col>{" "}
              <Col span={8}>
                <CustomSelect name="studentId">
                  <Select
                    key={selectedStudent}
                    showSearch
                    value={selectedStudent}
                    mode="multiple"
                    placeholder="-- Select Student --"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(id) => {
                      setSelectedStudent(id);
                    }}
                    options={UnassignedStudents}
                  />
                </CustomSelect>
              </Col>{" "}
              <Col span={8}>
                <center>
                  <CustomBtn
                    htmlType="submit"
                    type="submit"
                    title="ASSIGN"
                    loading={loading}
                    disable={loading}
                  />
                </center>{" "}
              </Col>
            </Row>
          </InfoForm>

          <TableContainer
            loading={loading}
            dataSource={dataSource}
            columns={columns}
          />
          {singleLiveClasses?.Student?.length > 10 ? (
            <CustomPagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={singleLiveClasses?.Student?.length}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          ) : null}
        </Skeleton>
      </Container>
    </Wrapper>
  );
};

export default LecturerStudentsPairing;
