import React from "react";
import { Button, List } from "antd";
import { MdSchedule } from "react-icons/md";
import { MdPayments } from "react-icons/md";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { MdOutlineContactSupport } from "react-icons/md";
import { RiBook2Line } from "react-icons/ri"; // Import icons
import { Link } from "react-router-dom";

const ChatbotOptions = ({ actionProvider }) => {
  const handleLink = (text, url) => {
    actionProvider.handleLinks(text, url);
  };

  const options = [
    {
      text: "Payments And Subscription Enquiry",
      id: 1,
      handler: () =>
        handleLink(
          "Have questions about payments or subscriptions? Let's get them answered!",
          "paymentsAndSubscriptionEnquiry"
        ),
      icon: <MdPayments className="button-icon" />,
      className: "explore-button",
    },

    {
      text: "Class Schedule Enquiry",
      id: 2,
      handler: () =>
        handleLink(
          "Our class schedule offers a variety of courses to fit your needs. Let's find the perfect schedule for you.",
          "classScheduleEnquiry"
        ),
      icon: <MdSchedule className="button-icon" />,
      className: "explore-button",
    },

    {
      text: "Online Portal Issues",
      id: 3,
      handler: () =>
        handleLink(
          "Facing any issues with our online portal? Let's troubleshoot them together.",
          "onlinePortalIssues"
        ),
      icon: <AiOutlineIssuesClose className="button-icon" />,
      className: "explore-button",
    },
    {
      text: "Contact Support",
      id: 4,
      handler: () =>
        handleLink(
          "Need assistance? Contact our support team for help.",
          "contactSupport"
        ),
      icon: <MdOutlineContactSupport className="button-icon" />,
      className: "explore-button",
    },
    {
      text: "Explore Courses",
      id: 5,
      handler: () =>
        handleLink(
          "Great choice! Let's explore our available courses together.",
          "courseLinks"
        ),
      icon: <RiBook2Line className="button-icon" />,
      className: "explore-button",
    },
  ];

  return (
    <List
      dataSource={options}
      renderItem={(item) => (
        <React.Fragment key={item.id}>
          {item.url ? (
            <Link to={item.url} target="_blank" rel="noopener noreferrer">
              <Button className={`chatbot-button ${item.className}`}>
                {item.icon}
                {item.text}
              </Button>
            </Link>
          ) : (
            <Button
              onClick={item.handler}
              className={`chatbot-button ${item.className}`}
            >
              {item.icon}
              {item.text}
            </Button>
          )}
        </React.Fragment>
      )}
    />
  );
};

export default ChatbotOptions;
