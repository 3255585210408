import React from "react";
import { Container } from "react-bootstrap";
import CustomBtn from "../../common/button";
import {
  DiscoverSection,
  ImageArea,
  SectionHeading,
  SpecialistCards,
  Wrapper,
} from "./index.style";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";
import { Link } from "react-router-dom";
import CustomImage from "../../common/images";

const Services = () => {
  // eslint-disable-next-line no-unused-vars
  const carddata = [
    {
      image: "time.png",
      desc: "Courses Length",
      month: "3 - 4 months",
    },
    {
      image: "code.png",
      desc: "Experience",
      month: "Not Required",
    },
    {
      image: "book.png",
      desc: "Number of Cources",
      month: "20+",
    },
  ];
  return (
    <Wrapper>
      <MetaHelmet {...metaHelmetData.services} />
      <DiscoverSection>
        <SectionHeading>
          <strong>Our Services</strong>
        </SectionHeading>
        <p>
          Lorem ipsum dolor sit amet consectetur. Sed egestas fermentum in dui
          ullamcorper laoreet etiam nulla eu. Sit a tempus enim pellentesque
          iaculis dictum eget elit tempus
        </p>
        <Container fluid>
          <div className="tabsBtn">
            <Link>
              <CustomImage
                src="/icons/group-user.svg"
                alt="i"
                width="100%"
                height="100%"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
            <Link>
              <CustomImage
                src="/icons/cap-icon.svg"
                alt="i"
                width="100%"
                height="100%"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
            <Link>
              <CustomImage
                src="/icons/spech-icon.svg"
                alt="i"
                width="100%"
                height="100%"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
          </div>
        </Container>
      </DiscoverSection>

      <DiscoverSection>
        <Container>
          <SpecialistCards>
            <div className="cardItems">
              <ImageArea>
                <div className="image">
                  <CustomImage
                    src="/images/img-10.png"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                </div>
                <div className="shadowImg">
                  <CustomImage
                    src="/images/img-10.png"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                </div>
              </ImageArea>
              <div className="lists">
                <div className="cardLabel">
                  <CustomImage
                    src="/icons/group-user.svg"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                  <span>Live classes</span>
                </div>
                <h1>
                  435,67 currently enrolled students in 125 different classes
                </h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Sed egestas fermentum
                  in dui ullamcorper laoreet etiam nulla eu. Sit a tempus enim
                  pellentesque iaculis dictum eget elit tempus
                </p>
                <div className="tags">
                  <div className="tagsItem">
                    <p>One-on-one Live Class</p>
                    <h5>$200</h5>
                  </div>
                  <div className="tagsItem">
                    <p>10+ students</p>
                    <h5>$100</h5>
                  </div>
                  <div className="tagsItem">
                    <p>Less than 10 students</p>
                    <h5>$50</h5>
                  </div>
                  <div className="tagsItem">
                    <p>Onsite corporate training</p>
                    <h5>Contact for Price</h5>
                  </div>
                </div>
                <CustomBtn title="Register now" />
              </div>
            </div>
            <div className="cardItems">
              <ImageArea>
                <div className="image">
                  <CustomImage
                    src="/images/img-11.png"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                </div>
                <div className="shadowImg">
                  <CustomImage
                    src="/images/img-11.png"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                </div>
              </ImageArea>
              <div className="lists">
                <div className="cardLabel">
                  <CustomImage
                    src="/icons/cap-icon.svg"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                  <span>Unpaid interships</span>
                </div>
                <h1>
                  Internships for database developer, data analysis and data
                  science roles
                </h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Sed egestas fermentum
                  in dui ullamcorper laoreet etiam nulla eu. Sit a tempus enim
                  pellentesque iaculis dictum eget elit tempus. Lorem ipsum
                  dolor sit amet consectetur. Sed egestas fermentum in dui
                  ullamcorper laoreet etiam nulla eu. Sit a tempus enim
                  pellentesque iaculis dictum eget elit tempus.Lorem ipsum dolor
                  sit amet consectetur. Sed egestas fermentum in dui ullamcorper
                  laoreet etiam nulla eu. Sit a tempus enim pellentesque iaculis
                  dictum eget elit tempus.
                </p>
                <CustomBtn title="Register now" />
              </div>
            </div>
            <div className="cardItems">
              <ImageArea>
                <div className="image">
                  <CustomImage
                    src="/images/img-12.png"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                </div>
                <div className="shadowImg">
                  <CustomImage
                    src="/images/img-12.png"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                </div>
              </ImageArea>
              <div className="lists">
                <div className="cardLabel">
                  <CustomImage
                    src="/icons/group-user.svg"
                    alt="i"
                    width="100%"
                    height="100%"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                  <span>Consultancy</span>
                </div>
                <h1>
                  Consultancy for database development, data architecture, data
                  modeling, data analysis and data science
                </h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur. Sed egestas fermentum
                  in dui ullamcorper laoreet etiam nulla eu. Sit a tempus enim
                  pellentesque iaculis dictum eget elit tempus
                </p>
                <CustomBtn title="Register now" />
              </div>
            </div>
          </SpecialistCards>
        </Container>
      </DiscoverSection>
    </Wrapper>
  );
};

export default Services;
