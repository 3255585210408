import { Container } from "react-bootstrap";
import { TableContainer, Wrapper } from "../../Forum/manageTopic/index.style";
import { CustomSelect } from "../../../common/select/index.style";
import { useEffect, useState } from "react";
import { Form, Input, Modal, Select, Skeleton, Space } from "antd";
import CustomBtn from "../../../common/button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCourses } from "../../../redux/courseSlice";
import { antValidator, paginate } from "../../../utils/helper";
// import CustomPagination from "../../../common/pagination";
import { Scroller } from "../../liveClasses/index.style";
import { CustomInput } from "../../../common/input/index.style";
import { interviewQuestionSchema } from "../../../utils/validationSchema";
import { AddQuiz } from "../adminQuizes/index.style";
import {
  addInterviewQuestion,
  getCourseSyllabus,
  getInterviewQuestionsCount,
} from "../../../redux/interviewQuestionsSlice";
import { Helmet } from "react-helmet";
import moment from "moment";

function AdminInterviewQuestions() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [selectedLessonId, setSelectedLessonId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { adminCourses, courseLoading } = useSelector((state) => state?.course);
  const { courseData, syllabusLoading, loading, interviewQuestions } =
    useSelector((state) => state?.interviewQuestions);
  const [isOpenModal, setOpenModal] = useState(false);
  const [Rules, setRules] = useState({});
  const [isSkeleton, setSkeleton] = useState(true);
  const { TextArea } = Input;

  useEffect(() => {
    dispatch(getAdminCourses());
    setRules(antValidator(interviewQuestionSchema));
  }, [dispatch]);

  useEffect(() => {
    if (adminCourses?.[0]?._id) {
      setSelectedCourse(adminCourses?.[0]?._id);
      dispatch(getInterviewQuestionsCount(adminCourses?.[0]?._id));
    }
  }, [adminCourses, dispatch]);

  useEffect(() => {
    setSkeleton(courseLoading);
  }, [courseLoading]);

  const courseOptions =
    adminCourses?.map(({ _id, longName }) => ({
      value: _id,
      label: longName,
    })) || [];

  const chapterOptions = (courseData.chapters || [])?.map(
    ({ chapterId, chapterName, lessons }) => ({
      value: chapterId,
      label: chapterName,
      lessons: lessons,
    })
  );

  const TimeAgo = ({ timestamp, delay }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const updateRelativeTime = () => {
        if (delay) {
          const timeDifference = moment(timestamp).fromNow();
          setTimeAgo(timeDifference);
        } else {
          const formattedDate = moment(timestamp).format("YYYY-DD-MM hh:mmA");
          setTimeAgo(formattedDate);
        }
      };

      updateRelativeTime();
      const interval = setInterval(updateRelativeTime, 60000);
      return () => clearInterval(interval);
    }, [timestamp, delay]);

    return <span>{timeAgo}</span>;
  };

  const lessonOptions = (
    chapterOptions?.find((chapter) => chapter.value === selectedChapterId)
      ?.lessons || []
  )?.map(({ lessonId, lessonName }) => ({
    value: lessonId,
    label: lessonName,
  }));

  const dataSource = paginate(
    interviewQuestions || [],
    currentPage,
    interviewQuestions.length
  )
    ?.sort((a, b) => a.sequence - b.sequence)
    ?.map((data, i) => ({
      key: data?.interviewquestionId,
      s_no: i + 1,
      name: data?.name,
      interviewShortName: data?.interviewShortName,
      sequence: data?.sequence,
      updatedAt: <TimeAgo timestamp={data?.updatedAt} delay={true} />,
      updatedDate: <TimeAgo timestamp={data?.updatedAt} />,
      updatedAtDate: new Date(data?.updatedAt),
      questionCount: data?.questionsCount,
      actions: (
        <Space size="middle" className="deleteBtn">
          <Link
            to={`/admin/interview/questions/${data?.interviewquestionId}/${data?.name}/snapshot`}
          >
            <img
              src="/icons/pen-icon.svg"
              alt="i"
              width="19px"
              height="19px"
              onError={(e) => {
                e.target.style.display = "none";
              }}
            />
          </Link>
        </Space>
      ),
    }));

  const columns = [
    {
      title: "S.no",
      dataIndex: "s_no",
      key: "s_no",
    },
    {
      title: "Chapter Name - Lesson Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Topic Sequence",
      dataIndex: "sequence",
      key: "sequence",
    },
    {
      title: "Interview Short Name",
      dataIndex: "interviewShortName",
      key: "interviewShortName",
    },
    {
      title: "Question Count#",
      dataIndex: "questionCount",
      key: "questionCount",
    },
    {
      title: "UpdatedDate",
      dataIndex: "updatedDate",
      key: "updatedDate",
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => (loading ? 0 : b.updatedAtDate - a.updatedAtDate),
      sortDirections: ["ascend", "descend"],
      onHeaderCell: () => ({
        style: { cursor: "default", backgroundColor: "#181059" },
      }),
    },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  const formReset = () => {
    form.resetFields();
    setSelectedCourseId(null);
    setSelectedChapterId(null);
    setSelectedLessonId(null);
    setOpenModal(false);
  };

  const handleSubmit = async (values) => {
    const courseId = values?.courseId;
    const chapterName = values?.chapterName;
    if (courseId && chapterName) {
      const formDataObject = {
        lessonId: values?.lessonId,
        interviewQuestion: values?.interviewQuestion,
        explanation: values?.explanation,
      };
      dispatch(
        addInterviewQuestion({
          formDataObject,
          courseId: selectedCourseId,
          onReset: formReset,
        })
      );
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Interview Questions </title>
      </Helmet>
      <Container style={{ margin: "20px 0px" }} fluid>
        <Skeleton active loading={isSkeleton}>
          <div className="mainHeading">
            <div>
              <h3>Interview Questions</h3>
              <span className="total">{interviewQuestions.length} lessons</span>
            </div>
            <div>
              <CustomBtn
                htmlType="submit"
                type="submit"
                title="Add Question"
                onClick={() => setOpenModal(true)}
              />
            </div>
          </div>
          <div className="mainHeading">
            <div style={{ flex: 1 }} />
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  placeholder="-- Select Course --"
                  defaultValue={selectedCourse}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(id) => {
                    dispatch(getInterviewQuestionsCount(id));
                    setSelectedCourse(id);
                  }}
                  options={courseOptions}
                />
              </CustomSelect>
            </div>
          </div>
          <TableContainer
            loading={loading}
            dataSource={loading ? [] : dataSource}
            columns={columns}
            pagination={{
              pageSize: interviewQuestions.length,
              total: interviewQuestions.length || 0,
              onChange: (page) => setCurrentPage(page),
            }}
          />
          {/* {interviewQuestions.length > 10 && (
            <CustomPagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={interviewQuestions.length}
              onChange={(page) => setCurrentPage(page)}
            />
          )} */}
          <Modal
            title={null}
            style={{ top: 20 }}
            open={isOpenModal}
            onOk={() => formReset()}
            onCancel={() => formReset()}
            footer={null}
            width={490}
          >
            <AddQuiz
              layout="vertical"
              style={{ borderBottom: "0px solid #ffff" }}
              onFinish={handleSubmit}
              form={form}
            >
              <center className="mb-4">
                <h2>Add New Questions</h2>
              </center>
              <Scroller>
                <CustomSelect name="courseId" rules={[Rules]}>
                  <Select
                    key={selectedCourseId}
                    defaultValue={selectedCourseId}
                    showSearch
                    placeholder="-- Select Course --"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(id) => {
                      dispatch(getCourseSyllabus(id));
                      setSelectedCourseId(id);
                      form.setFieldsValue({ chapterName: null });
                      form.setFieldsValue({ lessonId: null });
                      setSelectedChapterId(null);
                      setSelectedLessonId(null);
                    }}
                    options={courseOptions}
                  />
                </CustomSelect>
                {syllabusLoading ? (
                  <>
                    <CustomSelect name="chapterName" rules={[Rules]}>
                      <Skeleton.Button
                        active={syllabusLoading}
                        size={"default"}
                        shape={"default"}
                        block={syllabusLoading}
                      />
                    </CustomSelect>
                    <CustomSelect name="lessonId" rules={[Rules]}>
                      <Skeleton.Input
                        active
                        size={"default"}
                        shape={"default"}
                        loading={syllabusLoading}
                        block={syllabusLoading}
                      />
                    </CustomSelect>
                  </>
                ) : (
                  <>
                    <CustomSelect name="chapterName" rules={[Rules]}>
                      <Select
                        key={selectedChapterId}
                        defaultValue={selectedChapterId}
                        showSearch
                        placeholder="-- Select Chapter --"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        loading={syllabusLoading}
                        onChange={(id) => {
                          setSelectedChapterId(id);
                          setSelectedLessonId(null);
                          form.setFieldsValue({ lessonId: null });
                        }}
                        options={chapterOptions}
                      />
                    </CustomSelect>
                    <CustomSelect name="lessonId" rules={[Rules]}>
                      <Select
                        key={selectedLessonId}
                        defaultValue={selectedLessonId}
                        loading={syllabusLoading}
                        showSearch
                        placeholder="-- Select Lesson --"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(id) => setSelectedLessonId(id)}
                        options={lessonOptions}
                      />
                    </CustomSelect>
                  </>
                )}

                <CustomInput name="interviewQuestion" rules={[Rules]}>
                  <TextArea rows={3} placeholder="Interview Question" />
                </CustomInput>
                <CustomInput name="explanation" rules={[Rules]}>
                  <TextArea rows={3} placeholder="Explanation" />
                </CustomInput>
              </Scroller>
              <center>
                <CustomBtn
                  htmlType="submit"
                  type="submit"
                  title="Submit"
                  loading={loading}
                  disable={loading}
                />
              </center>
            </AddQuiz>
          </Modal>
        </Skeleton>
      </Container>
    </Wrapper>
  );
}

export default AdminInterviewQuestions;
