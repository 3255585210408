import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg, successMsg } from "../components/AlertMessage";
import { ApiRequests } from "../services/ApiRequest";

export const getCourseAssignment = createAsyncThunk(
  "assignments/getCourseAssignment",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getCourseAssignment(courseId);
      return response?.data?.data;
    } catch (error) {
      console.error(error);
      throw rejectWithValue(error);
    }
  }
);

export const addAssignment = createAsyncThunk(
  "assignments/addAssignment ",
  async (
    { formDataObject, courseId, onReset },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.addAssignment(formDataObject);
      if (response.status === 200) {
        onReset();
        successMsg("New Assignment Added!");
        dispatch(getCourseAssignment(courseId));
        return response?.data?.data;
      } else {
        errorMsg("Please try again.");
      }
    } catch (error) {
      errorMsg(error);
      throw rejectWithValue(error);
    }
  }
);

export const addAssignmentTask = createAsyncThunk(
  "assignments/addAssignmentTask ",
  async (
    { formDataObject, assignmentId, onReset },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.addAssignmentTask(formDataObject, assignmentId);
      if (response.status === 200) {
        onReset();
        dispatch(getAssignmentTasks({ assignmentId }));
        successMsg("New Task Added!");
        return response?.data?.data;
      } else {
        errorMsg("Please try again.");
      }
    } catch (error) {
      errorMsg(error);
      throw rejectWithValue(error);
    }
  }
);

export const getAssignmentTasks = createAsyncThunk(
  "assignments/getAssignmentTasks",
  async ({ assignmentId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAssignmentTasks(assignmentId);
      return response?.data?.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const handleAssignmentActiveFlag = createAsyncThunk(
  "assignments/handleAssignmentActiveFlag",
  async ({ assignmentId,courseId,data }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.handleAssignmentActiveFlag(assignmentId,data);
      if (response.status === 200) {
        dispatch(getCourseAssignment(courseId));
      return response?.data?.data;
      }
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const removeTask = createAsyncThunk(
  "assignments/removeTask",
  async ({Id, taskId}, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.removeTask(Id, taskId);
      if (response.status === 200) {
        successMsg("Task Deleted!");
        return response;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: false,
  assignmentTasks: {},
  courseAssignments: [],
};

const assignmentsSlice = createSlice({
  name: "assignments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourseAssignment.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourseAssignment.fulfilled, (state, action) => {
        state.courseAssignments = action.payload;
        state.loading = false;
      })
      .addCase(getCourseAssignment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(handleAssignmentActiveFlag.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleAssignmentActiveFlag.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(handleAssignmentActiveFlag.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addAssignmentTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAssignmentTask.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addAssignmentTask.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addAssignment.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAssignment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addAssignment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeTask.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removeTask.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAssignmentTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssignmentTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.assignmentTasks = action.payload;
      })
      .addCase(getAssignmentTasks.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default assignmentsSlice.reducer;
