import React, { useState } from "react";
import { CustomContainer, CustomSelect } from "./index.style";
import CustomImage from "../../common/images";

const MobPlanSelect = ({ comparison }) => {
  const [value, setValue] = useState("free");

  const data = comparison?.map((compare) => ({
    title: (
      <p>
        {compare.title}{" "}
        <CustomImage
          src=""
          alt="i"
          width="24px"
          height="24px"
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      </p>
    ),
    free: compare.free ? (
      <CustomImage
        src="/icons/check-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ) : (
      <CustomImage
        src="/icons/close-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ),
    quizPack: compare.quizPack ? (
      <CustomImage
        src="/icons/check-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ) : (
      <CustomImage
        src="/icons/close-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ),
    selfPaced: compare.selfPaced ? (
      <CustomImage
        src="/icons/check-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ) : (
      <CustomImage
        src="/icons/close-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ),
    sqlZoom: compare.sqlZoom ? (
      <CustomImage
        src="/icons/check-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ) : (
      <CustomImage
        src="/icons/close-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ),
    sqlPowerBIZoom: compare.sqlPowerBIZoom ? (
      <CustomImage
        src="/icons/check-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ) : (
      <CustomImage
        src="/icons/close-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ),
    premium: compare.premium ? (
      <CustomImage
        src="/icons/check-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ) : (
      <CustomImage
        src="/icons/close-icon.svg"
        alt="i"
        width="24px"
        height="24px"
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
    ),
  }));

  const filteredData = data?.filter((item) => item[value].props.src !== "");

  return (
    <CustomContainer>
      <CustomSelect
        value={value}
        onChange={(newValue) => setValue(newValue)}
        options={[
          { label: "Free", value: "free" },
          { label: "Quiz Pack", value: "quizPack" },
          { label: "Self-Paced", value: "selfPaced" },
          { label: "SQL Zoom", value: "sqlZoom" },
          { label: "SQL+PowerBI Zoom", value: "sqlPowerBIZoom" },
          { label: "Premium", value: "premium" },
        ]}
      />

      <div>
        {filteredData?.map((item, i) => (
          <div key={i} className="cards">
            <p>{item.title}</p>
            <CustomImage
              src={item[value].props.src}
              alt="i"
              width="24px"
              height="24px"
              onError={(e) => {
                e.target.style.display = "none";
              }}
            />
          </div>
        ))}
      </div>
    </CustomContainer>
  );
};

export default MobPlanSelect;
