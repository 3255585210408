import React from "react";
import { GoClockFill } from "react-icons/go";
import { FaBriefcase } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoLogoPwa } from "react-icons/io5";
import { Container } from "react-bootstrap";
import { Card, Col, Row } from "antd";
import { Wrapper } from "../Forum/manageTopic/index.style";
import { ContainerWrapper } from "./index.style";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";

const jobs = [
  { id: 1, jobstitle: "Data analyst" },
  { id: 2, jobstitle: "Technician" },
  { id: 3, jobstitle: "Lead analyst" },
  { id: 4, jobstitle: "Database administrator" },
];

const locations = [
  { id: 1, locationtitle: "USA" },
  { id: 2, locationtitle: "Canada" },
  { id: 3, locationtitle: "UAE" },
  { id: 4, locationtitle: "India" },
  { id: 5, locationtitle: "Salary" },
];

const JobsListing = () => {
  return (
    <Wrapper>
       <MetaHelmet {...metaHelmetData.jobs_listing} />
      <Container fluid>
        <ContainerWrapper className="container">
          <h1 className="title">JOBS</h1>
          <div className="content-container">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={16}>
                <div className="job-list">
                  <strong>Available Jobs</strong>
                  <div className="job-count">1 job found</div>
                  <div className="jobs">
                    {jobs?.map((job) => (
                      <div className="job" key={job.id}>
                        {job.jobstitle}
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
              <Col xs={24} md={16}>
                <div className="location-list">
                  {locations?.map((location) => (
                    <div className="location" key={location.id}>
                      {location.locationtitle}
                    </div>
                  ))}
                </div>
                <hr />
              </Col>
            </Row>
            <Card className="job-details">
              <div className="company-info">
                <IoLogoPwa className="logo" />
                <div className="company-name">Tech Mahindra</div>
              </div>
              <h5 className="job-title">
                Tech Mahindra SQL Developer Job Opportunity
              </h5>
              <div className="description">
                Exciting job opening for SQL Developers at Tech Mahindra Pune!
                Eligible candidates with SQL expertise can apply for this role.
                Check out the eligibility criteria and application process
              </div>
              <div className="details-footer">
                <div className="details">
                  <div className="detail">
                    <GoClockFill className="icon" />
                    <div className="detail-text">2 days ago</div>
                  </div>
                  <div className="detail">
                    <FaBriefcase className="icon" />
                    <div className="detail-text">Full-time</div>
                  </div>
                  <div className="detail">
                    <FaLocationDot className="icon" />
                    <div className="detail-text">Pune, Maharashtra, India</div>
                  </div>
                </div>
                <div className="apply-button">Apply now</div>
              </div>
            </Card>
            <div className="load-more-button">Load more</div>
          </div>
        </ContainerWrapper>
      </Container>
    </Wrapper>
  );
};

export default JobsListing;
