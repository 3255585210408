import React from "react";
import { Collapse } from "antd";
import { BsCheckLg, BsPlayCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Popup } from "./index.style";

const { Panel } = Collapse;

export const Syllabus = ({ courseData }) => {
  return (
    <Popup>
      <Collapse accordion className="accordion">
        {courseData.length > 0 ? (
          courseData?.map((chapter) => (
            <Panel
              key={chapter._id}
              header={
                <div className="accordionBtn">
                  <h4>{chapter.chapterName}</h4>
                </div>
              }
            >
              {chapter.lessons && chapter.lessons.length > 0 ? (
                chapter.lessons?.map((item) => (
                  <Link key={item._id} className="allItems">
                    <div className="items">
                      <div className="lesson-item">
                        <div
                          className={`lessonName ${
                            item.completedFlag ? "text-green" : ""
                          }`}
                        >
                          <BsPlayCircleFill
                            className={`icon ${
                              item.completedFlag ? "playIcon" : ""
                            }`}
                          />
                          <span
                            className={`${
                              item.completedFlag ? "markedlesson" : "lesson"
                            }`}
                          >
                            {item.lessonName.length > 35
                              ? `${item.lessonName.substr(0, 30)}...`
                              : item.lessonName}
                            {item.completedFlag && (
                              <BsCheckLg className="checkIcon" />
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <center style={{ color: "#ffffff30" }}>
                  No Topic Found
                </center>
              )}
            </Panel>
          ))
        ) : (
          <center>No Chapter Found</center>
        )}
      </Collapse>
    </Popup>
  );
};
