class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse = (message) => {
    const lowerCaseMessage = message.toLowerCase();

    if (
      lowerCaseMessage.includes("hello") ||
      lowerCaseMessage.includes("hi") ||
      lowerCaseMessage.includes("hai") ||
      lowerCaseMessage.includes("good morning") ||
      lowerCaseMessage.includes("good evening") ||
      lowerCaseMessage.includes("good afternoon") ||
      lowerCaseMessage.includes("hey")
    )
      this.actionProvider.greet();

    if (
      lowerCaseMessage.includes("bye") ||
      lowerCaseMessage.includes("goodbye")
    )
      this.actionProvider.farewell();

    if (
      lowerCaseMessage.includes("schedule") ||
      lowerCaseMessage.includes("timetable")
    )
      this.actionProvider.showClassSchedule();

    if (
      lowerCaseMessage.includes("payment") ||
      lowerCaseMessage.includes("subscribe")
    )
      this.actionProvider.inquirePaymentsAndSubscription();

    if (
      lowerCaseMessage.includes("issue") ||
      lowerCaseMessage.includes("problem")
    )
      this.actionProvider.reportPortalIssue();

    if (
      lowerCaseMessage.includes("support") ||
      lowerCaseMessage.includes("help")
    )
      this.actionProvider.contactSupport();
    else {
      this.actionProvider.complaints();
    }
  };
}

export default MessageParser;
