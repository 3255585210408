import { Button } from "antd";
import { Container } from "react-bootstrap";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 3em 4em;
  font-family: "Source Sans Pro";

  .Container {
    margin: 20px 0;
  }

  .Heading {
    margin: 50px 0;
  }

  .assignmentDetails {
    padding: 30px;
    color: black;
  }

  .taskList {
    gap: 30px;
  }

  .taskName {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
    line-height: 23px;
    letterspacing: 0;
    text-align: left;
  }

  .taskDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letterspacing: 0;
    text-align: left;
  }

  .pasteUrlSection {
    border: 1px solid #3131311a;
    padding: 30px;
    display: flex;
    flex-direction: column;
  }

  .sectionHeading {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letterspacing: 0;
    text-align: left;
  }

  .sectionDescription {
    font-weight: 400;
    font-size: 14px;
    line-height: 23.66px;
    margin-top: 8px;
    margin-bottom: 25px;
    color: rgba(49, 49, 49, 1);
    opacity: 0.6;
  }

  .HeadingText {
    width: 280px;
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: 0;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #3f6fd8;
  }

  .mainHeading {
    display: flex;
    justify-content: space-between;

    .CompletedSection {
      width: 300px;
    }

    .CompletedText {
      font-weight: 400;
      font-size: 15px;
      line-height: 26.62px;
    }

    h3 {
      color: #313131;
      font-weight: 600;
      font-size: 22px;
    }

    .ant-form-item {
      width: 350px;
    }
  }
`;
export const Content = styled(Container)`
  .subTitle {
    padding: 1.5em 0;
    .jsonDescription {
      font-weight: 400;
      font-size: 16px;
      line-height: 147.9%;
      color: #313131;
      margin-top: 0.5em;
    }
    .jsonTitle {
      font-weight: 600;
      font-size: 20px;
      text-transform: uppercase;
      color: #313131;
      margin-bottom: 0.5em;
    }
    .images {
      margin: 1em 0;
    }

    .code {
      margin: 1rem 0 0 0;
    }
  }

  @media (max-width: 992px) {
    & {
      padding: 1em 0.5em;
      img {
        max-width: 100%;
      }
    }
  }
`;

export const CustomBtn = styled(Button)`
  background: #d39331 !important;
  border: 1px solid #d39331 !important;
  width: 280px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;

  .loader {
    font-size: 2rem !important;
  }

  &:disabled {
    background: #d39331;
    border: 1px solid #d39331;
    width: 280px;
    height: 50px;
    border-radius: 0;
    opacity: 0.5;

    &:hover {
      background: #d39331;
      border: 1px solid #d39331;
      border-radius: 0;
      opacity: 0.5;
    }
  }

  span {
    font-size: 16px;
    color: #ffffff;
  }

  &:focus,
  &:hover {
    background: #d39331;
    border-radius: 0;
    border: 1px solid #d39331;
    opacity: 0.9;

    span {
      color: #ffffff;
    }
  }
  @media (max-width: 992px) {
    & {
      width: 250px;
      height: 45px;
      padding: 0px 0px;
      span {
        // font-size: 1em !important;
      }
      span {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      width: 180px;
      height: 40px;
      span {
        font-size: 12px !important;
      }
    }
  }
`;

export const UploadBtn = styled("span")`
  width: 166px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #d39331;
  border: 1px solid #d39331;
  cursor: pointer;
  @media (max-width: 768px) {
    & {
      width: 100px;
      height: 38px;
      span {
        font-size: 12px !important;
      }
    }
  }
`;
