import styled from "styled-components";

export const ChatBotStyled = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;

  .chatbot-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: fit-content;
  }
  .explore-button {
    // background-color: #1890ff;
    border: 1px solid #1890ff;
    color: #1890ff;
  }
  .talk-links-button {
    margin: 5px 0;
  }
  .whatsapp-button {
    background-color: #25d366;
    border: 1px solid #25d366;
    color: white;
  }
  .slack-button {
    background-color: #4a154b;
    border: 1px solid #4a154b;
    color: #fff;
  }
  .button-icon {
    width: 20px;
    margin-right: 5px;
    height: 20px;
  }

  .ant-btn {
    margin: 5px 3px;
  }
  .ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.85);
  }

  .react-chatbot-kit-chat-container {
    position: relative;
    width: 320px;
  }
  .react-chatbot-kit-chat-message-container {
    padding: 0 5px 15px 17.5px;
    overflow: scroll;
    height: 400px;
  }
  // .react-chatbot-kit-chat-message-container {
  //   padding: 0 5px 15px 17.5px !important;
  //   overflow-y: auto !important;
  //   overflow-x: hidden !important;
  //   height: 400px !important;
  // }
  // .react-chatbot-kit-chat-input-container {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   display: none;
  // }
  .react-chatbot-kit-chat-input-form {
    width: 100%;
    display: flex;
    margin: 10px 10px 10px 0px;
  }
  .react-chatbot-kit-chat-header {
    border-radius: 5px;
    background-color: #1e3465;
    font-family: Arial;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    padding: 12.5px;
    font-weight: bold;
  }
  .react-chatbot-kit-chat-inner-container {
    height: 500px;
    background-color: #fff;
    border-radius: 3px;
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .react-chatbot-kit-user-avatar-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 12.5px;
    background-color: #3d4e8d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-chatbot-kit-chat-bot-message,
  .react-chatbot-kit-user-chat-message {
    padding: 10px;
    margin-bottom: 1px;
    border-radius: 5px;
    font-size: 0.85rem;
    font-weight: medium;
    position: relative;
    text-align: left;
    p {
      color: "#fff";
    }
  }
  .react-chatbot-kit-chat-bot-message {
    background-color: #2898ec;
    color: #fff;
    width: auto;
    margin-left: 12.5px;
  }
  .react-chatbot-kit-user-chat-message {
    font-size: 0.85rem;
    background-color: #1e3465;
    color: #fff;
    padding: 10px;
    .react-chatbot-kit-user-chat-message-arrow {
      border-left: 8px solid #1e3465;
      position: absolute;
      right: -7px;
      top: 13px;
    }
  }
  .react-chatbot-kit-chat-input {
    width: 100%;
    padding: 12.5px;
    font-size: 0.85rem;
    border: 1px solid #d8d8d8;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(216, 216, 216);
  }
`;
