import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

function MetaHelmet({
  title,
  description,
  keywordContent,
  canonicalUrl,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  ogType,
  creatorName,
  twitterTitle,
  twitterDescription,
  twitterImage,
  twitterUrl,
}) {
  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content = description;
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content = description;
      document.head.appendChild(newMetaDescription);
    }

    const metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.content = keywordContent.join(', ');
    } else {
      const newMetaKeywords = document.createElement("meta");
      newMetaKeywords.name = "keywords";
      newMetaKeywords.content = keywordContent.join(', ');
      document.head.appendChild(newMetaKeywords);
    }

    const linkCanonical = document.querySelector('link[rel="canonical"]');
    if (linkCanonical) {
      linkCanonical.href = canonicalUrl;
    } else {
      const newLinkCanonical = document.createElement("link");
      newLinkCanonical.rel = "canonical";
      newLinkCanonical.href = canonicalUrl;
      document.head.appendChild(newLinkCanonical);
    }
  }, [title, description, keywordContent, canonicalUrl]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:type" content={ogType || "website"} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta
        property="og:image"
        content={ogImage || "https://tansyacademy.com/images/logo.svg"}
      />
      <meta property="og:url" content={ogUrl || canonicalUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={creatorName || "@TansyAcademy"} />
      <meta name="twitter:title" content={twitterTitle || title} />
      <meta
        name="twitter:description"
        content={twitterDescription || description}
      />
      <meta
        name="twitter:image"
        content={twitterImage || "https://twitter.com/Tansyacademy/photo"}
      />
      <meta
        name="twitter:url"
        content={twitterUrl || "https://twitter.com/Tansyacademy"}
      />
    </Helmet>
  );
}

export default MetaHelmet;
