import React from "react";
import { CardContainer } from "./index.style";
import CustomImage from "../../../common/images";

const SqlCard = (props) => {
  const data = props.data;
  return (
    <CardContainer>
      <div>
        {data.image && (
          <CustomImage
            src={`/images/${data.image}`}
            alt="i"
            width="60px"
            height="60px"
                                 
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        )}
        <p>{data.desc}</p>
        <strong>{data.month}</strong>
      </div>
    </CardContainer>
  );
};

export default SqlCard;
