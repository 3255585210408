// MakePayment.js
import { loadStripe } from "@stripe/stripe-js";
import { processPayment } from "../redux/stripeSlice";
import { getRazorPay } from "../redux/stripeSlice";

export async function MakePayment(dispatch, product, setLoading) {
  try {
    setLoading(true);
    const response = await dispatch(processPayment({ data: product }));
    const session = await response?.payload?.data;
    if (!session) {
      throw new Error("Failed to retrieve payment session data");
    }
    const publishableKey = process.env.REACT_APP_STRIPE_UI_KEY_PROD;
    if (!publishableKey) {
      throw new Error("Publishable key is missing in environment variables");
    }
    const stripe = await loadStripe(publishableKey);
    const result = await stripe.redirectToCheckout({ sessionId: session?.id });
    if (result.error) {
      throw new Error(result.error.message);
    }
  } catch (error) {
    console.error("Error during payment:", error.message);
  } finally {
    setLoading(false);
  }
}

export async function HandlePayment(dispatch, productData, setLoadingState) {
  try {
    setLoadingState(true);
    const authorInfo = {
      name: "Tansy Cloud Private Limited",
      author: "Tansy Cloud Private Limited",
      img: "/images/logo.svg",
      description: "RazorPay",
    };

    const initializeRazorpay = () => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    };

    const initializePayment = async (checkoutDetails) => {
      const razorpayKeyId = process.env.REACT_APP_RAZORPAY_KEY_ID_PROD;
      const paymentOptions = {
        key: razorpayKeyId,
        name: authorInfo.name,
        description: authorInfo.description,
        image: authorInfo.img,
        notes: checkoutDetails.notes,
        handler: async (paymentData) => {
          try {
            if (checkoutDetails.notes.payment === "subscription") {
              const {
                razorpay_payment_id,
                razorpay_subscription_id,
                razorpay_signature,
              } = paymentData;
              const successUrl = `${productData?.currentURL}/success?session_id=${razorpay_payment_id}&s_id=${razorpay_signature}&o_s_id=${razorpay_subscription_id}&action=razorPay`;
              window.location.href = successUrl;
              // window.location.href = checkoutDetails.url;
            } else {
              const {
                razorpay_payment_id,
                razorpay_order_id,
                razorpay_signature,
              } = paymentData;
              const successUrl = `${productData?.currentURL}/success?session_id=${razorpay_payment_id}&s_id=${razorpay_signature}&o_s_id=${razorpay_order_id}&action=razorPay`;
              window.location.href = successUrl;
            }
          } catch (error) {
            console.error("Error navigating to success page:", error);
          }
        },
        prefill: {
          name: checkoutDetails.notes.userName,
          email: checkoutDetails.notes.userEmail,
        },
        theme: {
          color: "#1e3465",
        },
      };

      if (checkoutDetails.notes.payment === "subscription") {
        paymentOptions.subscription_id = checkoutDetails.id;
      } else {
        paymentOptions.order_id = checkoutDetails.id;
      }

      try {
        const razorpayInstance = new window.Razorpay(paymentOptions);
        razorpayInstance.open();
      } catch (error) {
        console.error("Error initializing payment:", error);
      }
    };
    try {
      const res = await initializeRazorpay();
      if (!res) {
        alert("Razorpay SDK Failed to load");
        return;
      }

      const response = await dispatch(getRazorPay({ data: productData }));
      const checkoutDetails = response?.payload?.data;

      if (checkoutDetails) {
        await initializePayment(checkoutDetails);
      } else {
        console.error("Error: No order details received.");
      }
    } catch (error) {
      console.error("Error", error);
    }
  } catch (error) {
    console.error("Error handling payment:", error);
  } finally {
    setLoadingState(false);
  }
}
