import styled from "styled-components";

export const ContainerWrapper = styled.div`
  .container {
    font-family: Source Sans Pro, sans-serif;
    color: #313131;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    padding: 0 20px;
  }

  .title {
    text-transform: uppercase;
    margin: 3rem 0;
    text-align: center;
  }

  .content-container {
    display: flex;
    flex-direction: column;
  }

  .job-list {
    margin-bottom: 3rem;
  }

  .job-count {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .jobs {
    display: flex;
    color: #3f6fd8;
    flex-wrap: wrap;
    text-align: center;
    gap: 10px;
    margin-top: 1rem;
  }

  .job {
    justify-content: center;
    background-color: rgba(63, 111, 216, 0.1);
    flex-grow: 1;
    padding: 5px 24px;
  }

  .location-list {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .location {
    font-weight: 600;
  }

  .job-details {
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.07);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    width: 100%;
    padding: 2em 2.5em;
  }

  .logo {
    aspect-ratio: 1.72;
    object-fit: auto;
    object-position: center;
    width: 26px;
    margin: auto 0;
  }

  .company-info {
    align-self: start;
    display: flex;
    gap: 7px;
    font-size: 14px;
    color: #000;
    white-space: nowrap;
  }

  .company-name {
    flex-grow: 1;
  }

  .job-title {
    margin: 1rem 0;
  }

  .description {
    margin-top: 0.5rem;
  }

  .details-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }

  .icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
    margin-top: 4px;
  }

  .details {
    display: flex;
    justify-content: space-between;
    color: #181059;
    margin: auto 0;
    gap: 20px;
  }

  .detail {
    display: flex;
    gap: 10px;
  }

  .detail-text {
    flex-grow: 1;
  }

  .apply-button {
    background-color: rgba(211, 147, 49, 1);
    color: #fff;
    padding: 14px 60px;
    text-align: center;
    cursor: pointer;
  }

  .load-more-button {
    color: #3f6fd8;
    text-decoration-line: underline;
    align-self: center;
    margin-top: 3rem;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .company-info {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .company-name {
      margin-top: 0.5rem;
    }

    .details-footer {
      flex-direction: column;
      align-items: center;
    }

    .apply-button {
      width: 100%;
    }
  }
`;
