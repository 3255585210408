import React, { useState, useEffect } from "react";
import { Card, Image, Spin } from "antd";
import projectsData from "./projectsData.json";
import { Wrapper, StyledCard } from "./index.style";
import { Container } from "react-bootstrap";
import { Marquee } from "../../common/sitemap/index.style";
import { Link } from "react-router-dom";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";

const { Meta } = Card;

function SQLProjectsPage() {
  const [projectCategories, setProjectCategories] = useState([]);

  useEffect(() => {
    setProjectCategories(projectsData);
  }, []);

  return (
    <Container>
      <MetaHelmet {...metaHelmetData.sql_database_projects} />
      <Wrapper>
        <div className="content">
          <center>
            <h1 className="heading">SQL Database Projects</h1>
          </center>
          {projectCategories?.length > 0 && (
            <Marquee className="marquee" style={{ color: "#666" }}>
              Join our Zoom session, where we’ll provide guidance for
              successfully completing these SQL assignments.{" "}
              <Link to="/learn_sql_class_online_sql_training_course">
                Join now
              </Link>
            </Marquee>
          )}
          {projectCategories?.map((category) => (
            <CategoryContainer
              key={category?.category}
              categoryData={category}
            />
          ))}
        </div>
      </Wrapper>
    </Container>
  );
}

const CategoryContainer = ({ categoryData }) => {
  const [projectsCategory, setProjectsCategory] = useState([]);

  useEffect(() => {
    setProjectsCategory(categoryData);
  }, [categoryData]);

  return (
    <div className="categoryContainer">
      <h3>{projectsCategory?.category}</h3>
      <div className="projectsContainer">
        {projectsCategory?.projects?.map((project) => (
          <ProjectCard key={project?.title} project={project} />
        ))}
      </div>
    </div>
  );
};

const ProjectCard = ({ project }) => {
  const { title, subTitle, description, url, image } = project || {};
  const [isLoading, setIsLoading] = useState(true);

  const handleImageError = (e) => {
    e.target.style.display = "none";
    setIsLoading(false);
  };

  const handleImageLoad = () => setIsLoading(false);

  return (
    <Link to={url} className="cards">
      <StyledCard>
        <Spin spinning={isLoading} tip="Loading...">
          <Image
            alt={`${title} Image`}
            width={280}
            height={150}
            preview={false}
            src={image}
            onLoad={handleImageLoad}
            onError={handleImageError}
            loading="lazy"
          />
        </Spin>
        <Meta
          title={title}
          description={
            <>
              <div className="subtitle">{subTitle || ""}</div>
              <p className="description">
                {description?.slice(0, 500)}
                {description?.length > 500 && "..."}
              </p>
            </>
          }
        />
      </StyledCard>
    </Link>
  );
};

export default SQLProjectsPage;
