import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Modal,
  Space,
  Select,
  Input,
  Form,
  Skeleton,
  InputNumber,
  Checkbox,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { getAdminList } from "../../../redux/userSlice";
import { getAdminCourses } from "../../../redux/courseSlice";
import {
  addAssignmentTask,
  getAssignmentTasks,
  removeTask,
} from "../../../redux/assignmentSlice";
import { antValidator, paginate } from "../../../utils/helper";
import { assignmentSchema, taskSchema } from "../../../utils/validationSchema";
import CustomBtn from "../../../common/button/index.jsx";
import CustomPagination from "../../../common/pagination/index.jsx";
import { TableContainer, Wrapper } from "../../Forum/manageTopic/index.style";
import { InfoForm } from "../../Forum/manageTopic/topicDetails/index.style";
import { Scroller } from "../../liveClasses/index.style.js";
import { CustomInput } from "../../../common/input/index.style.js";
import { Helmet } from "react-helmet";

const AddTask = () => {
  const { assignmentId } = useParams();
  const { confirm } = Modal;
  const { assignmentTasks, loading } = useSelector(
    (state) => state.assignments
  );
  const pageSizeAssignments = 10;
  const dispatch = useDispatch();
  const [taskForm] = Form.useForm();
  const [assignmentForm] = Form.useForm();
  const editTaskRef = useRef(null);
  const { TextArea } = Input;
  const [assignmentPagination, setAssignmentPagination] = useState({
    current: 1,
    pageSize: pageSizeAssignments,
  });
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [isSkeleton, setSkeleton] = useState(false);
  const [isAction, setAction] = useState(null);
  const [taskHandleEdit, setTaskHandleEdit] = useState(null);
  const [taskRules, setTaskRules] = useState({});
  const [activeFlag, setActiveFlag] = useState(null);
  const [adminUser, setAdminUser] = useState(null);
  const { adminUserList } = useSelector((state) => state?.user);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { adminCourses } = useSelector((state) => state?.course);
  const [assignmentRules, setAssignmentRules] = useState({});

  useEffect(() => {
    dispatch(getAdminList());
    dispatch(getAdminCourses());
    dispatch(getAssignmentTasks({ assignmentId }));
    setAssignmentRules(antValidator(assignmentSchema));
    setTaskRules(antValidator(taskSchema));
  }, [dispatch,assignmentId]);

  useEffect(() => {
    setSkeleton(!Object.keys(assignmentTasks).length > 0);
  }, [loading, assignmentTasks]);

  const courseOptions =
    adminCourses?.map((course) => ({
      value: course?._id,
      label: course?.longName,
    })) || [];

  const adminUserListOptions =
    adminUserList?.map((user) => ({
      value: user?._id,
      label: user?.name,
    })) || [];

  useEffect(() => {
    if (assignmentTasks?.AdminUser !== undefined) {
      assignmentForm.setFieldsValue({
        _id: assignmentTasks?.id,
        sequence: assignmentTasks?.sequence,
        assignmentName: assignmentTasks?.longName,
        contentURL: assignmentTasks?.contentURL,
        userId: assignmentTasks?.AdminUser?.map((i) => i?.userId),
        courseId: assignmentTasks?.Course?.map((i) => i?.courseId),
      });
      setActiveFlag(assignmentTasks?.ActiveFlag);
    }
  }, [assignmentTasks, assignmentForm]);

  useEffect(() => {
    if (editTaskRef.current) {
      editTaskRef.current.setFieldsValue({
        _id: taskHandleEdit?.id,
        sequence: taskHandleEdit?.sequence,
        name: taskHandleEdit?.name,
        description: taskHandleEdit?.description,
      });
    }
  }, [taskHandleEdit, editTaskRef]);

  const assignmentDataSource = (assignmentTasks?.Tasks || [])?.map((task) => ({
    key: task?._id,
    sequence: task?.sequence,
    name: task?.name,
    description: task?.description,
    actions: (
      <Space size="middle" className="deleteBtn">
        <Link
          onClick={() => {
            setAction("edit");
            setTaskModalOpen(true);
            setTaskHandleEdit({
              id: task?._id,
              sequence: task?.sequence || null,
              name: task?.name || null,
              description: task?.description || null,
            });
          }}
        >
          <img
            src="/icons/pen-icon.svg"
            alt="i"
            width="19px"
            height="19px"
                                 
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        </Link>
        <Link
          onClick={() => {
            confirmDeleteAssignment({
              Id: assignmentId,
              taskId: task?._id,
            });
          }}
        >
          <img
            src="/icons/bin-icon.svg"
            alt="i"
            width="19px"
            height="19px"
                                 
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        </Link>
      </Space>
    ),
  }));
  const confirmDeleteAssignment = (Id, taskId) => {
    const confirmModal = confirm({
      title: "Do you want to delete this Assignment?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        try {
          const removeAssignmentResult = await dispatch(removeTask(Id, taskId));
          if (removeAssignmentResult) {
            await dispatch(getAssignmentTasks({ assignmentId }));
          } else {
            console.error("Error deleting Assignment");
          }
        } catch (error) {
          console.error("Error deleting Assignment:", error);
        } finally {
          confirmModal.destroy();
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const assignmentColumns = [
    { title: "Sequence", dataIndex: "sequence", key: "sequence" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  const assignmentFormReset = () => {
    taskForm.resetFields();
    setTaskModalOpen(false);
    setAction(null);
    setTaskHandleEdit(null);
  };

  const handleAssignmentsSubmit = (fieldsValue) => {
    const formDataObject = {
      action: isAction,
      _id: fieldsValue?._id || null,
      sequence: fieldsValue?.sequence,
      name: fieldsValue?.name,
      description: fieldsValue?.description,
    };
    dispatch(
      addAssignmentTask({
        formDataObject,
        assignmentId,
        onReset: assignmentFormReset,
      })
    );
  };

  const assignmentData = paginate(
    assignmentDataSource,
    assignmentPagination?.current,
    assignmentPagination?.pageSize
  );

  const isReadOnly = true;

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Add Assignment TAsk</title>
      </Helmet>
      <Container fluid>
        <Skeleton active loading={isSkeleton}>
          <Form
            style={{ borderBottom: "0px solid #ffff" }}
            form={assignmentForm}
          >
            <Row gutter={30}>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Assignment Name:"
                  name="assignmentName"
                  rules={[assignmentRules]}
                >
                  <Input
                    placeholder="Assignment Name"
                    bordered={!isReadOnly}
                    readOnly={isReadOnly}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Sequence :"
                  name="sequence"
                  rules={[assignmentRules]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Sequence"
                    bordered={!isReadOnly}
                    readOnly={isReadOnly}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Course :"
                  name="courseId"
                  rules={[assignmentRules]}
                >
                  <Select
                    key={selectedCourse}
                    mode="multiple"
                    showSearch
                    bordered={!isReadOnly}
                    disabled={isReadOnly}
                    placeholder="-- Select Course --"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(id) => {
                      setSelectedCourse(id);
                    }}
                    options={courseOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Admin User :"
                  name="userId"
                  rules={[assignmentRules]}
                >
                  <Select
                    bordered={!isReadOnly}
                    disabled={isReadOnly}
                    key={adminUser}
                    mode="multiple"
                    showSearch
                    placeholder="-- Select Admin User --"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(id) => {
                      setAdminUser(id);
                    }}
                    options={adminUserListOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label="Content URL :"
                  name="contentURL"
                  rules={[assignmentRules]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Image URL"
                    bordered={!isReadOnly}
                    readOnly={isReadOnly}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }}>
                <p className="checkingArea">
                  <Checkbox
                    checked={activeFlag}
                    rules={[assignmentRules]}
                    onClick={(e) => {
                      if (!isReadOnly) {
                        setActiveFlag(e.target.checked);
                      }
                    }}
                  >
                    Active Flag
                  </Checkbox>
                </p>
              </Col>{" "}
            </Row>
          </Form>
        </Skeleton>
        <Row style={{ marginBottom: "40px" }} gutter={30}>
          <Col
            style={{ marginTop: "40px" }}
            xs={{ span: 24 }}
            md={{ span: 24 }}
          >
            <Skeleton active loading={isSkeleton}>
              <div
                style={{ margin: "2px", marginBottom: "30px" }}
                className="mainHeading"
              >
                <div>
                  <h3>Task</h3>
                  <span className="total">
                    {assignmentDataSource.length} tasks
                  </span>
                </div>
                <CustomBtn
                  htmlType="submit"
                  type="submit"
                  title="Add Task"
                  onClick={() => {
                    setAction("add");
                    setTaskModalOpen(true);
                  }}
                />
              </div>

              <TableContainer
                loading={loading}
                dataSource={assignmentData}
                columns={assignmentColumns}
              />
              {assignmentDataSource.length > 10 ? (
                <CustomPagination
                  style={{ marginTop: "20px", textAlign: "center" }}
                  current={assignmentPagination.current}
                  pageSize={assignmentPagination.pageSize}
                  total={assignmentDataSource.length}
                  onChange={(page, pageSize) => {
                    setAssignmentPagination({
                      ...assignmentPagination,
                      current: page,
                    });
                  }}
                />
              ) : null}
              <Modal
                title={null}
                style={{ top: 20 }}
                open={isTaskModalOpen}
                onOk={() => assignmentFormReset()}
                onCancel={() => assignmentFormReset()}
                footer={null}
                width={470}
              >
                <InfoForm
                  form={taskForm}
                  ref={editTaskRef}
                  onFinish={handleAssignmentsSubmit}
                  layout="vertical"
                  style={{ borderBottom: "0px solid #ffff" }}
                >
                  <center className="mb-4">
                    <h2>Add New Task</h2>
                  </center>

                  <Scroller>
                    {isAction === "edit" ? (
                      <CustomInput name="_id" hidden />
                    ) : null}
                    <CustomInput name="sequence" rules={[taskRules]}>
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Sequence"
                      />
                    </CustomInput>
                    <CustomInput name="name" rules={[taskRules]}>
                      <Input placeholder="Task Name" />
                    </CustomInput>

                    <CustomInput name="description" rules={[taskRules]}>
                      <TextArea
                        rows={7}
                        placeholder="Description"
                        showCount
                        maxLength={1000}
                      />
                    </CustomInput>
                  </Scroller>
                  <center>
                    <CustomBtn
                      htmlType="submit"
                      type="submit"
                      title="Add"
                      loading={loading}
                      disable={loading}
                    />
                  </center>
                </InfoForm>
              </Modal>
            </Skeleton>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default AddTask;
