import React from "react";
import { SiPhonepe } from "react-icons/si";
import { Link } from "react-router-dom";
import {
  DollarButton,
  DollarCustomTooltip,
  DollarFloatingContainer,
  PhonePeButton,
  PhonePeCustomTooltip,
  PhonePeFloatingContainer,
} from "./index.style";
import { AiFillDollarCircle } from "react-icons/ai"; 

export const PhonePeBtn = () => {
  return (
    <PhonePeFloatingContainer>
      <Link to="/learn_sql_power_bi_analytics_online_training_cost_india">
        <PhonePeButton>
          <PhonePeCustomTooltip placement="right" title="Pay with PhonePe">
            <SiPhonepe size={24} />
          </PhonePeCustomTooltip>
        </PhonePeButton>
      </Link>
    </PhonePeFloatingContainer>
  );
};

export const DollarBtn = () => {
  return (
    <DollarFloatingContainer>
      <Link to="/learn_sql_power_bi_analytics_online_training_cost">
        <DollarButton>
          <DollarCustomTooltip placement="right" title="Pay with Dollars">
            <AiFillDollarCircle size={24} />
          </DollarCustomTooltip>
        </DollarButton>
      </Link>
    </DollarFloatingContainer>
  );
};
