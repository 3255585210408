import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { TableContainer, Wrapper } from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import { getSubscribedPlans } from "../../redux/paymentSlice";
import { Helmet } from "react-helmet";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const { subscribedPlans, loading } = useSelector(
    (state) => state?.payment
  );
  useEffect(() => {
    dispatch(getSubscribedPlans());
  }, [dispatch]);

  const dataSource = (subscribedPlans || [])?.map((data, i) => ({
    key: i,
    id: data?.id,
    email: data?.email,
    date: data?.date,
    amount: data?.amount,
    method: data?.method,
    subscription: data?.subscription,
    timeline: (
      <div style={{ color: data?.subscriptionStatus ? "#00AC47" : "#C91D2E" }}>
        {data?.timeline}
      </div>
    ),
  }));

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Payment Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment method",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
    },
    {
      title: "Subscription Timeline",
      dataIndex: "timeline",
      key: "timeline",
    },
  ];
  return (
    <Wrapper>
      <Helmet>
      <title>Tansy Academy | Payment History</title>
      </Helmet>
      <Container>
        <div>
          <h1>Payment History</h1>
          <p>Recent payment records</p>
        </div>

        <TableContainer
          loading={loading}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            pageSize: subscribedPlans.length,
            total: subscribedPlans.length || 0,
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default PaymentHistory;
