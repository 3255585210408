import styled from "styled-components";

export const Container = styled.div`
  padding: 3em 0;
  width: 85%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 92%;
  }
  h1 {
    font-size: 46px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 1.5em;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .section {
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 1rem;
    font-size: 1rem;
  }

  ul {
    margin-left: 2rem;
    padding-left: 1rem;
    @media (max-width: 768px) {
      margin-left: 0rem;
      padding-left: 0rem;
    }
  }

  li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  .subtitle {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .content {
    margin-bottom: 1rem;
    padding: 0 2rem;
  }
`;
