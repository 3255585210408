import { Col, Form, Input, Row } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
import { GrSearch } from "react-icons/gr";
import CustomBtn from "../../common/button";
import SqlCard from "../../components/cards/homeSql";
import CodeSection from "../../components/CodeSection";
import {
  DiscoverSection,
  FormArea,
  HomeSection,
  ImageArea,
  MainHeading,
  MobileImg,
  SectionHeading,
  SpecialistCards,
  Wrapper,
} from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import { searchTool } from "../../redux/toolSlice";
import { Link, useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";
import CustomImage from "../../common/images";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.tool);

  const carddata = [
    {
      image: "time.png",
      desc: "SQL Quiz Questions",
      month: "4500+",
      url: "/sql_database_quiz_questions",
    },
    {
      image: "code.png",
      desc: "SQL Projects",
      month: "15+",
      url: "/sql_database_projects",
    },
    {
      image: "book.png",
      desc: "SQL Interview Questions",
      month: "1000+",
      url: "/mysql/database_interview_questions",
    },
  ];

  const onFinish = ({ search }) => {
    const data = {
      search,
      searchIn: [],
    };

    dispatch(searchTool({ data, navigate }));
  };

  return (
    <Wrapper>
      <MetaHelmet {...metaHelmetData.home} />
      <HomeSection>
        <MobileImg>
          <CustomImage
            src="/images/cu-m.png"
            alt="i"
            width="100%"
            height="100%"
                                 
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
          <div className="visits">
            <p>Students visits</p>
            <h2>10M</h2>
          </div>
        </MobileImg>
        <Form onFinish={onFinish}>
          <MainHeading>
            Make <br />
            learning <strong>SQL&nbsp;</strong>
            <br />
            very simple
          </MainHeading>
          <p>
            Tansy Academy's live online SQL database training provides
            interactive, hands-on learning experiences led by experienced
            instructors.
          </p>
          <FormArea>
            <Row gutter={12}>
              <Col xs={{ span: 24 }} lg={{ span: 14 }}>
                <Form.Item name="search">
                  <Input
                    prefix={<GrSearch />}
                    placeholder="Find the course you are looking for"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                <div className="searchBtn">
                  <CustomBtn
                    htmlType="submit"
                    type="submit"
                    title="Search"
                    loading={loading}
                    disable={loading}
                  />
                </div>
              </Col>
            </Row>
          </FormArea>
        </Form>

        <ImageArea>
          <CustomImage
            src="/images/cu.png"
            alt="i"
            width="100%"
            height="100%"
                                 
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
          <div className="visits">
            <p>Students visits</p>
            <h2>10M</h2>
          </div>
        </ImageArea>
      </HomeSection>

      <DiscoverSection>
        <SectionHeading>
          Discover the fundamentals of&nbsp;
          <strong>MySQL™,Oracle, PostgreSQL & Microsoft SQL Server</strong>
        </SectionHeading>
        <p>
          Our SQL boot camp offers an intensive, comprehensive curriculum
          designed to quickly equip participants with the skills needed to
          effectively manage and analyze databases using SQL.
        </p>
        <Container fluid>
          <Row gutter={0}>
            {carddata?.map((item, i) => (
              <Col key={i} xs={{ span: 24 }} lg={{ span: 8 }}>
                <Link to={item.url}>
                  <SqlCard data={item} />
                </Link>
              </Col>
            ))}
          </Row>
          {/* <div className="tryBtn">
            <CustomBtn title="Try it now" />
          </div> */}
        </Container>
      </DiscoverSection>

      <DiscoverSection>
        <SectionHeading>
          Become a&nbsp;
          <strong>Data Specialist</strong>
        </SectionHeading>
        <p>
          Our online SQL query editor allows you to practice and enhance your
          SQL skills without the need for local database installations. With our
          web-based platform, you can easily write, execute, and test SQL
          queries, making it convenient to develop your SQL expertise from
          anywhere.
        </p>
        <Container fluid>
          <SpecialistCards>
            <div className="cardItems">
              <div>
                <CustomImage
                  src="/images/mysql_live_bootcamp_database_virtual_training.png"
                  alt="i"
                  width="100%"
                  height="100%"
                                       
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </div>
              <div className="lists">
                <h1>MySQL</h1>
                <p>Basic to advanced Training</p>
                <ul>
                  <li>40+ hours of Live zoom class.</li>
                  <li>50+ hours of recorded videos.</li>
                  <li>1000+ code snippets.</li>
                  <li>Support for final capstone project.</li>
                </ul>
                <Link to={"https://tansyacademy.com/course_detail?name=MySQL"}>
                  <CustomBtn title="Get Enrolled" />
                </Link>
              </div>
            </div>
            <div className="cardItems">
              <div>
                <CustomImage
                  src="/images/postgresql_live_bootcamp_database_virtual_training.png"
                  alt="i"
                  width="100%"
                  height="100%"
                                       
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </div>
              <div className="lists">
                <h1>Oracle</h1>
                <p>Basic to advanced Training</p>
                <ul>
                  <li>40+ hours of Live zoom class.</li>
                  <li>50+ hours of recorded videos.</li>
                  <li>1000+ code snippets.</li>
                  <li>Support for final capstone project.</li>
                </ul>
                <Link to={"https://tansyacademy.com/course_detail?name=Oracle"}>
                  <CustomBtn title="Get Enrolled" />
                </Link>
              </div>
            </div>
            <div className="cardItems">
              <div>
                <CustomImage
                  src="/images/oracle_live_bootcamp_database_virtual_training.png"
                  alt="i"
                  width="100%"
                  height="100%"
                                       
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </div>
              <div className="lists">
                <h1>PostgreSQL</h1>
                <p>Basic to advanced Training</p>
                <ul>
                  <li>40+ hours of Live zoom class.</li>
                  <li>50+ hours of recorded videos.</li>
                  <li>1000+ code snippets.</li>
                  <li>Support for final capstone project.</li>
                </ul>
                <Link
                  to={"https://tansyacademy.com/course_detail?name=PostgreSQL"}
                >
                  <CustomBtn title="Get Enrolled" />
                </Link>
              </div>
            </div>
            <div className="cardItems">
              <div>
                <CustomImage
                  src="/images/ms_sql_server_live_bootcamp_database_virtual_training.png"
                  alt="i"
                  width="100%"
                  height="100%"
                                       
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </div>
              <div className="lists">
                <h1>Microsoft SQL Server</h1>
                <p>Basic to advanced Training</p>
                <ul>
                  <li>40+ hours of Live zoom class.</li>
                  <li>50+ hours of recorded videos.</li>
                  <li>1000+ code snippets.</li>
                  <li>Support for final capstone project.</li>
                </ul>
                <Link
                  to={
                    "https://tansyacademy.com/course_detail?name=Microsoft%20SQL%20Server"
                  }
                >
                  <CustomBtn title="Get Enrolled" />
                </Link>
              </div>
            </div>
          </SpecialistCards>
        </Container>
        <CodeSection />
      </DiscoverSection>
    </Wrapper>
  );
};

export default Home;
