import React, { useState, useEffect } from "react";
import Chatbot from "react-chatbot-kit";
import { Avatar, Affix, Tooltip } from "antd";
import { BsRobot } from "react-icons/bs";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import { ChatBotStyled } from "./index.style";
import { createChatBotMessage } from "react-chatbot-kit";
import BotAvatar from "./BotAvatar";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import { Widgets } from "./widgets";

const ChatBot = () => {
  const [chatState, setChatState] = useState({
    isVisible: false,
    isValidElement: false,
  });

  useEffect(() => {
    const toggleChat = () => {
      if (chatState.isVisible && !chatState.isValidElement) {
        const timeout = setTimeout(() => {
          setChatState((prevState) => ({
            ...prevState,
            isValidElement: true,
          }));
        }, 200);
        return () => clearTimeout(timeout);
      } else if (!chatState.isVisible && chatState.isValidElement) {
        const timeout = setTimeout(() => {
          setChatState((prevState) => ({
            ...prevState,
            isValidElement: false,
          }));
        }, 500);
        return () => clearTimeout(timeout);
      }
    };

    const timeout = toggleChat();

    return () => clearTimeout(timeout);
  }, [chatState]);

  const handleToggleChatON = () => {
    setChatState({ isVisible: true });
  };

  const handleToggleChatOff = () => {
    setChatState({ isVisible: false });
  };

  const today = moment().format("dddd, MMMM Do YYYY");

  const config = {
    botName: "Tansy Assist",
    initialMessages: [
      createChatBotMessage(
        <div>
          <p style={{ color: "#fff" }}>Hi I'm Tansy Assist !!!</p>
          <p style={{ color: "#fff" }}>
            I'm here to help or chat with you. What do you want to go ahead
            with?.
          </p>
        </div>,
        {
          widget: "chatbotOptions",
          withAvatar: true,
        }
      ),
    ],
    customComponents: {
      header: () => (
        <div className="react-chatbot-kit-chat-header">
          <div>
            Conversation with {config.botName}
            <div
              style={{
                fontSize: "0.65rem",
                opacity: 0.7,
                fontWeight: "normal",
              }}
            >
              {today}
            </div>
          </div>
          <div onClick={handleToggleChatOff} style={{ cursor: "pointer" }}>
            <AiOutlineClose />
          </div>
        </div>
      ),

      botAvatar: (props) => <BotAvatar {...props} />,
    },
    customStyles: {
      botMessageBox: { backgroundColor: "#1e3465" },
      chatButton: { backgroundColor: "#1e3465" },
    },
    widgets: Widgets,
  };

  return (
    <ChatBotStyled>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          position: "relative",
        }}
      >
        {chatState.isVisible && chatState.isValidElement ? (
          <Affix
            offsetBottom={20}
            style={{
              position: "absolute",
            }}
          >
            <Chatbot
              config={config}
              actionProvider={ActionProvider}
              messageParser={MessageParser}
            />
          </Affix>
        ) : null}

        {!chatState.isVisible && !chatState.isValidElement ? (
          <Affix
            offsetBottom={20}
            style={{
              position: "absolute",
            }}
          >
            <Tooltip title="Initiate Chat with the Bot!" placement="left">
              <Avatar
                size={40}
                onClick={handleToggleChatON}
                style={{
                  backgroundColor: "#1e3465",
                  color: "#fff",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                icon={<BsRobot size={22} />}
              />
            </Tooltip>
          </Affix>
        ) : null}
      </div>
    </ChatBotStyled>
  );
};

export default ChatBot;
