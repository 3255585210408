import { Col, Input, Row } from "antd";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { GrSearch } from "react-icons/gr";
import CustomBtn from "../../../common/button";
import CourseSqlCard from "../../../components/cards/courseSql";
// import SearchedSql from "../../../components/searchedSql";
import { FormArea, SectionHeading } from "../../home/index.style";
import { Wrapper } from "./index.style";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCourses,
  // getCoursesTags,
  // imageURLUpdateCourse,
  setCourseLoading,
} from "../../../redux/courseSlice";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
// import LoadingSpinner from "../../../common/Loading/LoadingSpinner";
import MetaHelmet from "../../../common/helmet";
import metaHelmetData from "../../../components/json/metaHelmetData.json";
// import { getFileURLFromAzure } from "../../../services/FileRequest";

const CoursesList = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = searchParams.get("search");
  const { allCourses, courseLoading, tags } = useSelector(
    (state) => state?.course
  );
  const effectiveTags = tags?.length > 0 ? tags : ["SQL"];
  // const [loadingStatus, setLoadingStatus] = useState(false);

  // useEffect(() => {
  //   setLoadingStatus(true);
  //   setLoadingStatus(false);
  // }, [loadingStatus]);

  const courses = allCourses?.results;
  // const [updateCourses, setUpdateCourses] = useState([]);
  // const updateCourse = [];
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (courses) {
  //       for (const item of courses) {
  //         const response = await getFileURLFromAzure(
  //           { fileName: item?.imageURL, fileType: "Image" },
  //           null
  //         );
  //         if (response) {
  //           const temp = { ...item, imageURL: response.data.url };
  //           updateCourse.push(temp);
  //         }
  //       }
  //       setUpdateCourses(updateCourse);
  //     }
  //   };
  //   fetchData();
  // }, [courses]);

  // useEffect(() => {
  //   dispatch(imageURLUpdateCourse(updateCourses));
  // }, [updateCourses]);

  const { control, handleSubmit } = useForm();

  useEffect(() => {
    dispatch(setCourseLoading(true));
    dispatch(getAllCourses(search));
  }, [dispatch,search]);

  // useEffect(() => {
  //   dispatch(getCoursesTags());
  // }, []);

  const searchSubmit = ({ search }) => {
    if (search !== undefined) {
      setSearchParams({ search });
    }
    if (search === "" || search === undefined) {
      navigate("/courses/sql_database_programming_tutorial_courses");
    }
  };

  return (
    <Wrapper>
      <MetaHelmet
        {...metaHelmetData.sql_database_programming_tutorial_courses}
      />
      <SectionHeading>
        {search !== undefined && search !== "" && search !== null ? (
          <strong>{search}&nbsp;</strong>
        ) : (
          ""
        )}
        Courses
      </SectionHeading>
      <FormArea>
        <form onSubmit={handleSubmit(searchSubmit)}>
          <Row gutter={12} align="middle">
            <Col xs={{ span: 16, offset: 0 }} lg={{ span: 6, offset: 7 }}>
              <Controller
                name="search"
                control={control}
                render={({ field }) => (
                  <Input prefix={<GrSearch />} placeholder="SQL" {...field} />
                )}
              />
            </Col>
            <Col xs={{ span: 8 }} lg={{ span: 6 }}>
              <CustomBtn
                // disapled={courseLoading}
                // loading={courseLoading}
                type="submit"
                title="Search"
              />
            </Col>
          </Row>
        </form>
      </FormArea>
      <div>
        <Container>
          <div className="topHeader">
            <div className="linkPagination">
              <Link to="/">Home</Link> &gt;{" "}
              <Link to="/courses/sql_database_programming_tutorial_courses">
                Courses
              </Link>{" "}
              {search !== undefined && search !== "" && search !== null ? (
                <>&gt; {search}</>
              ) : (
                ""
              )}
            </div>
            <div className="tabArea">
              <p>
                <strong>{courses?.length ?? 0}</strong> courses available
                {search !== undefined && search !== "" && search !== null
                  ? ` for ${search}`
                  : ""}
                .
              </p>
              <div className="tabLinks">
                {effectiveTags?.length > 0
                  ? !courseLoading &&
                    effectiveTags?.map((tag) => {
                      return (
                        <button
                          key={tag}
                          type="button"
                          onClick={() => setSearchParams({ search: tag })}
                        >
                          {tag}
                        </button>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>

          {/* {courseLoading && <LoadingSpinner />}
          {updateCourses?.length > 0 ? (
            updateCourses?.map((item, i) => (
              <Link
                to={item?.longName && `/course_detail?name=${item.longName}`}
                key={i}
              >
                <CourseSqlCard data={item} />
              </Link>
            ))
          ) : !updateCourses.length && courseLoading ? (
            <h1>No Courses</h1>
          ) : (
            <LoadingSpinner />
          )} */}
          {courseLoading ? (
            [1, 2, 3, 4]?.map((e) => <CourseSqlCard loading={courseLoading} />)
          ) : courses?.length ? (
            courses?.map((item, i) => (
              <Link
                to={item?.longName && `/course_detail?name=${item.longName}`}
                key={i}
              >
                <CourseSqlCard data={item} loading={courseLoading} />
              </Link>
            ))
          ) : (
            <h1>No Courses</h1>
          )}
        </Container>
      </div>
      {/* <SearchedSql /> */}
    </Wrapper>
  );
};

export default CoursesList;
