import React from "react";
import { Container, Section } from "./index.style";
import { Link } from "react-router-dom";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";

const About = () => {
  const aboutUsData = {
    title: "About Us",
    whoWeAre: {
      title: "Who Are We",
      content:
        "The foundation of Tansy Academy comprises a varied group of professionals, each contributing a distinct viewpoint and expertise. Our team spans from the innovative founder to skilled developers, designers, mentors, and support personnel. Together, we are united by a singular objective: empowering individuals to thrive in the IT industry.",
    },
    ourMission: {
      title: "Our Mission",
      content:
        "We hold the belief that every individual has the capacity to pursue a career within the IT field. Our training programs are designed to accommodate both newcomers and seasoned IT professionals, enriching their skills and unlocking pathways to lucrative employment opportunities.",
    },
    founderDetails: {
      title: "Our Founder",
      content: (
        <>
          The{" "}
          <Link
            to="https://linkedin.com/in/data-champion/"
            target="_blank"
            rel="noopener noreferrer"
          >
            founder
          </Link>{" "}
          and primary instructor bring over 25 years of invaluable IT expertise,
          acquired across a diverse spectrum of countries including the USA,
          Canada, Saudi Arabia, Kuwait, UAE, and India.
        </>
      ),
    },
  };

  const { title, whoWeAre, ourMission, founderDetails } = aboutUsData;

  return (
    <Container>
      <MetaHelmet {...metaHelmetData.about} />
      <div className="container">
        <div className="row">
          <h1 className="heading">{title}</h1>
        </div>
      </div>
      <Section className="content">
        <div className="title">{whoWeAre.title}</div>
        <p>{whoWeAre.content}</p>
        <div className="title">{ourMission.title}</div>
        <p>{ourMission.content}</p>
        <div className="title">{founderDetails.title}</div>
        <p>{founderDetails.content}</p>
      </Section>
    </Container>
  );
};

export default About;
