import { Wrapper } from "../../Forum/manageTopic/index.style";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BackBtn } from "../../Forum/forumlList/threadQuestion/index.style";
import { BsChevronLeft } from "react-icons/bs";
import { Container } from "react-bootstrap";
import {
  IconLinkWrapper,
  EditDeleteWrapper,
  QuestionWrapper,
  QuestionText,
  ExplanationText,
  ButtonGroup,
  ButtonConst,
} from "./index.style";
import { Form, Button, Spin, Input } from "antd";
import {
  getInterviewQuestions,
  handleDeleteQuestion,
  handleUpdateQuestion,
} from "../../../redux/interviewQuestionsSlice";
import { antValidator } from "../../../utils/helper";
import { updateInterviewQuestionsSchema } from "../../../utils/validationSchema";
import { Helmet } from "react-helmet";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";

function InterviewQuestionsSnapshot() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Id, name } = useParams();
  const { questionsList, loading } = useSelector(
    (state) => state?.interviewQuestions
  );
  const [editableQuestionId, setEditableQuestionId] = useState(null);
  const [editForm] = Form.useForm();
  const [rules, setRules] = useState({});

  useEffect(() => {
    dispatch(getInterviewQuestions(Id));
  }, [dispatch, Id]);

  useEffect(() => {
    setRules(antValidator(updateInterviewQuestionsSchema));
  }, []);

  const handleEditClick = (data) => {
    setEditableQuestionId(data.id);
    editForm.setFieldsValue({
      questionName: data.questionName,
      answer: data.answer,
    });
  };

  const handleCancelEdit = () => {
    setEditableQuestionId(null);
    editForm.resetFields();
  };

  const handleUpdateSubmit = () => {
    editForm
      .validateFields()
      .then((values) => {
        dispatch(
          handleUpdateQuestion({
            QuestionId: editableQuestionId,
            Id,
            data: values,
          })
        );
        handleCancelEdit();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const dataSource =
    questionsList?.Questions?.map((data) => ({
      key: data?._id,
      id: data?._id,
      questionName: data?.questionName,
      answer: data?.answer,
    })) || [];

  const questionsLength = questionsList?.Questions?.length || 0;

  const customStyle = {
    ...coy,
    'code[class*="language-"]': {
      ...coy['code[class*="language-"]'],
      background: "white",
      color: "black",
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
    'code[class*="language-"] .token': {
      ...coy['code[class*="language-"] .token'],
      color: "#007ACC",
    },
    'pre[class*="language-"]': {
      ...coy['pre[class*="language-"]'],
      background: "white",
      color: "black",
      // padding: "1em",
      // border: "1px solid #ddd",
      // borderRadius: "5px",
      wordWrap: "break-word",
      whiteSpace: "pre-wrap",
    },
  };
  
  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Interview Questions Snapshot</title>
      </Helmet>
      <Container>
        <Spin spinning={loading}>
          <div className="mainHeading">
            <div>
              <p className="mt-3 mb-3">
                Name :&nbsp;&nbsp;&nbsp;
                <span>
                  <strong>{name}</strong>
                </span>
              </p>
              <div>
                <p className="mt-3 mb-3">
                  Questions Count :&nbsp;&nbsp;&nbsp;
                  <span style={{ color: "#00AC47" }}>
                    <strong>{questionsLength} </strong>
                  </span>
                </p>
              </div>
              <div></div>
              <div className="mt-4">
                <BackBtn
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                >
                  <span>
                    <BsChevronLeft />
                  </span>
                  <span>Go back</span>
                </BackBtn>
              </div>
            </div>
          </div>
          {dataSource?.map((data, index) => (
            <QuestionWrapper key={data?.id}>
              <EditDeleteWrapper>
                <IconLinkWrapper onClick={() => handleEditClick(data)}>
                  <img src="/icons/pen-icon.svg" alt="Edit" />
                </IconLinkWrapper>
                <IconLinkWrapper
                  onClick={() =>
                    dispatch(handleDeleteQuestion({ QuestionId: data.id, Id }))
                  }
                >
                  <img src="/icons/bin-icon.svg" alt="Delete" />
                </IconLinkWrapper>
              </EditDeleteWrapper>
              {editableQuestionId === data.id ? (
                <Form form={editForm} layout="vertical">
                  <Form.Item
                    name="questionName"
                    label={
                      <div>
                        <strong
                          style={{
                            marginRight: "5px",
                            width: "98%",
                            fontSize: "20px",
                            display: "inline-flex",
                          }}
                        >
                          {index + 1}. Question:
                        </strong>
                      </div>
                    }
                    rules={[rules]}
                  >
                    <Input placeholder="Enter Question" />
                  </Form.Item>
                  <Form.Item
                    name="answer"
                    label={
                      <div>
                        <strong
                          style={{
                            marginRight: "5px",
                            width: "98%",
                            display: "inline-flex",
                          }}
                        >
                          Explanation:
                        </strong>
                      </div>
                    }
                    rules={[rules]}
                  >
                    <Input.TextArea rows={8} placeholder="Enter Answer" />
                  </Form.Item>
                  <ButtonGroup>
                    <ButtonConst
                      type="primary"
                      onClick={handleUpdateSubmit}
                      className="mt-2"
                    >
                      Submit
                    </ButtonConst>
                    <Button
                      type="secondary"
                      onClick={handleCancelEdit}
                      className="mt-2"
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </Form>
              ) : (
                <div>
                  <QuestionText>
                    <strong style={{ marginRight: "5px" }}>
                      {index + 1}. Question:
                    </strong>
                    <span style={{ whiteSpace: "pre-line" }}>
                      {data?.questionName}
                    </span>
                  </QuestionText>
                  <ExplanationText>
                    <strong style={{ marginRight: "5px" }}>Explanation:</strong>
                    <SyntaxHighlighter
                      language="sql"
                      style={customStyle}
                      wrapLongLines={true}
                    >
                      {data?.answer}
                    </SyntaxHighlighter>
                  </ExplanationText>
                </div>
              )}
            </QuestionWrapper>
          ))}
        </Spin>
      </Container>
    </Wrapper>
  );
}

export default InterviewQuestionsSnapshot;
