import { Link } from "react-router-dom";

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  handleLinks = (message, widget) => {
    const msg = this.createChatBotMessage(message, { widget });
    this.updateChatbotState(msg);
  };

  greet = () =>
    this.handleLinks("Hello! How can I assist you today?", "chatbotOptions");

  showClassSchedule = () =>
    this.handleLinks(
      "Sure! Let me help you explore our class schedule.",
      "classScheduleEnquiry"
    );

  inquirePaymentsAndSubscription = () =>
    this.handleLinks(
      "Interested in our payment and subscription options? Let's find the best plan for you.",
      "paymentsAndSubscriptionEnquiry"
    );

  reportPortalIssue = () =>
    this.handleLinks(
      "Facing issues with our online portal? Let me guide you to resolve them.",
      "onlinePortalIssues"
    );

  contactSupport = () =>
    this.handleLinks(
      "Need assistance? Our support team is here to help!",
      "contactSupport"
    );

  complaints = () =>
    this.handleLinks(
      <p style={{ color: "white" }}>
        If you have a query or complaint, please click{" "}
        <Link to="https://tansyacademy.com/contact">here</Link> to submit it.
      </p>
    );

  farewell = () =>
    this.handleLinks("Thank you for your time. Farewell and take care! 👋", "");

  updateChatbotState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };
}

export default ActionProvider;
