import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Modal,
  Space,
  Select,
  Input,
  Form,
  Skeleton,
  InputNumber,
  Checkbox,
  Table,
  Button,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import CustomBtn from "../../../common/button/index.jsx";
import {
  TableContainer,
  Wrapper,
} from "../../Forum/manageTopic/index.style.js";
import { antValidator, paginate } from "../../../utils/helper.js";
import { quizSchema } from "../../../utils/validationSchema.js";
import {
  getQuizQuestions,
  removeQuestion,
  toggleQuestionVisibility,
  updateQuizFilled,
} from "../../../redux/questionsSlice.js";
import {
  ExclamationCircleFilled,
  FlagTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { getAdminCourses } from "../../../redux/courseSlice.js";
import { Helmet } from "react-helmet";
import { BsChevronLeft } from "react-icons/bs";
import { BackBtn } from "../../Forum/forumlList/threadQuestion/index.style.js";
import {
  ButtonConst,
  EditButtonGroup,
} from "../adminInterviewQuestions/index.style.js";

const QuestionsGrid = () => {
  const navigate = useNavigate();
  const { quizId } = useParams();
  const { confirm } = Modal;
  const { quizQuestions, loadingQuestions, loading } = useSelector(
    (state) => state.questions
  );

  const dispatch = useDispatch();
  const [quizForm] = Form.useForm();
  const [questionPagination, setQuestionPagination] = useState({
    current: 1,
    pageSize: 100,
  });
  const [isSkeleton, setSkeleton] = useState(false);
  const [quizRules, setQuizRules] = useState({});
  const [selectedCourse, setSelectedCourse] = useState(null);
  const { adminCourses } = useSelector((state) => state?.course);
  const [loadingQuestionId, setLoadingQuestionId] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(true);

  useEffect(() => {
    dispatch(getAdminCourses());
    dispatch(getQuizQuestions({ quizId }));
    setQuizRules(antValidator(quizSchema));
  }, [dispatch, quizId]);

  useEffect(() => {
    setSkeleton(!Object.keys(quizQuestions).length > 0);
    setLoadingQuestionId(null);
  }, [loadingQuestions, quizQuestions]);

  const courseOptions =
    adminCourses?.map((course) => ({
      value: course?._id,
      label: course?.longName,
    })) || [];

  useEffect(() => {
    if (quizQuestions) {
      setSelectedCourse(quizQuestions?.courseId);
      quizForm.setFieldsValue({
        key: quizQuestions?.id || "",
        _id: quizQuestions?.id || "",
        lessonId:
          `${quizQuestions?.lessonId?.course?.[0]?.chapterName} - ${quizQuestions?.lessonId?.name}` ||
          "",
        courseId: quizQuestions?.courseId || "",
        randomQuestionCount: quizQuestions?.randomQuestionCount || 0,
        publicQuestionCount: quizQuestions?.publicQuestionCount || 0,
        activeFlag: quizQuestions?.activeFlag || false,
      });
    }
  }, [quizQuestions, quizForm]);

  const counts = {
    public: {
      trueFalse: 0,
      radioButton: 0,
      checkbox: 0,
      total: 0,
    },
    subscription: {
      trueFalse: 0,
      radioButton: 0,
      checkbox: 0,
      total: 0,
    },
  };

  const handleVisibilityToggle = ({ Id, QuestionId, activeFlag }) => {
    setLoadingQuestionId(QuestionId);
    dispatch(
      toggleQuestionVisibility({
        Id,
        QuestionId,
        data: { activeFlag },
      })
    ).finally(() => {
      setLoadingQuestionId(null);
    });
  };

  const questionDataSource = (quizQuestions?.Questions || [])?.map(
    (task, i) => {
      if (task?.isQuestionPublic) {
        if (task?.questionType === "True/False") {
          counts.public.trueFalse++;
          counts.public.total++;
        } else if (task?.questionType === "Radio Button") {
          counts.public.radioButton++;
          counts.public.total++;
        } else if (task?.questionType === "Checkbox") {
          counts.public.checkbox++;
          counts.public.total++;
        }
      } else if (!task?.isQuestionPublic) {
        if (task?.questionType === "True/False") {
          counts.subscription.trueFalse++;
          counts.subscription.total++;
        } else if (task?.questionType === "Radio Button") {
          counts.subscription.radioButton++;
          counts.subscription.total++;
        } else if (task?.questionType === "Checkbox") {
          counts.subscription.checkbox++;
          counts.subscription.total++;
        }
      }

      return {
        key: `${task?._id}_${i}`,
        question: i + 1,
        questionName: task?.questionName,
        questionType: task?.questionType,
        isQuestionPublic: (
          <div
            style={{ color: task?.isQuestionPublic ? "#00AC47" : "#C91D2E" }}
          >
            {task?.isQuestionPublic ? "Yes" : "No"}
          </div>
        ),
        actions: (
          <Space size="middle" className="deleteBtn" key={`task_${i}`}>
            <Link
              onClick={() =>
                handleVisibilityToggle({
                  Id: quizId,
                  QuestionId: task?._id,
                  activeFlag: !task?.isQuestionPublic,
                })
              }
            >
              {loading && loadingQuestionId === task?._id ? (
                <LoadingOutlined />
              ) : (
                <FlagTwoTone
                  twoToneColor={task?.isQuestionPublic ? "#00AC47" : "#C91D2E"}
                  style={{ fontSize: "19px" }}
                />
              )}
            </Link>
            <Link to={`/admin/quiz/${quizId}/edit-question/${task?._id}`}>
              <img
                src="/icons/pen-icon.svg"
                alt="Edit"
                width="19px"
                height="19px"
                onError={(e) => (e.target.style.display = "none")}
              />
            </Link>
            <Link
              onClick={() =>
                confirmDeleteQuestion({ Id: quizId, QuestionId: task?._id })
              }
            >
              <img
                src="/icons/bin-icon.svg"
                alt="Delete"
                width="19px"
                height="19px"
                onError={(e) => (e.target.style.display = "none")}
              />
            </Link>
          </Space>
        ),
      };
    }
  );

  const confirmDeleteQuestion = (Id, QuestionId) => {
    const confirmModal = confirm({
      title: "Do you want to delete this Question?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        try {
          const removeQuestionResult = await dispatch(
            removeQuestion(Id, QuestionId)
          );
          if (removeQuestionResult) {
            await dispatch(getQuizQuestions({ quizId }));
          } else {
            console.error("Error deleting Question");
          }
        } catch (error) {
          console.error("Error deleting Question:", error);
        } finally {
          confirmModal.destroy();
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const questionColumns = [
    { title: "Question#", dataIndex: "question", key: "question" },
    { title: "Question Name", dataIndex: "questionName", key: "questionName" },
    { title: "Question Type", dataIndex: "questionType", key: "questionType" },
    {
      title: "Public Visible",
      dataIndex: "isQuestionPublic",
      key: "isQuestionPublic",
    },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  const menuDataSource = [
    {
      key: "MDS_1",
      id: "TQ0001",
      metric: "Total Questions",
      public: counts.public.total || 0,
      subscription: counts.subscription.total || 0,
      total: counts.public.total + counts.subscription.total || 0,
    },
    {
      key: "MDS_2",
      id: "TF0002",
      metric: "True/False Questions",
      public: counts.public.trueFalse || 0,
      subscription: counts.subscription.trueFalse || 0,
      total: counts.public.trueFalse + counts.subscription.trueFalse || 0,
    },
    {
      key: "MDS_3",
      id: "RB0003",
      metric: "Radio Button Questions",
      public: counts.public.radioButton || 0,
      subscription: counts.subscription.radioButton || 0,
      total: counts.public.radioButton + counts.subscription.radioButton || 0,
    },
    {
      key: "MDS_4",
      id: "CB0004",
      metric: "Checkbox Questions",
      public: counts.public.checkbox || 0,
      subscription: counts.subscription.checkbox || 0,
      total: counts.public.checkbox + counts.subscription.checkbox || 0,
    },
  ];

  const menuColumns = [
    {
      title: "Metric",
      dataIndex: "metric",
      key: "metric",
    },
    {
      title: "Public View",
      dataIndex: "public",
      key: "public",
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
    },
    {
      title: "Total Questions",
      dataIndex: "total",
      key: "total",
    },
  ];
  const questionData = paginate(
    questionDataSource,
    questionPagination?.current,
    questionDataSource?.length
  );

  const FormReset = () => {
    quizForm.resetFields();
    setIsReadOnly(!isReadOnly);
  };

  const onSubmit = async (values) => {
    const formDataObject = {
      courseId: values?.courseId,
      lessonId: values?.lessonId,
      randomQuestionCount: Number(values?.randomQuestionCount),
      publicQuestionCount: Number(values?.publicQuestionCount),
      activeFlag: values?.activeFlag,
    };

    dispatch(
      updateQuizFilled({
        data: formDataObject,
        quizId,
        onReset: FormReset,
      })
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Questions Grid</title>
      </Helmet>
      <Container fluid>
        <BackBtn
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <span>
            <BsChevronLeft />
          </span>
          <span>Go back</span>
        </BackBtn>
        <Skeleton active loading={isSkeleton}>
          <Row gutter={[30, 30]}>
            <Col xs={24} lg={13}>
              <Form
                style={{ borderBottom: "0px solid #ffff" }}
                onFinish={onSubmit}
                form={quizForm}
              >
                <Row gutter={30}>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      label="Lesson Name:"
                      name="lessonId"
                      rules={[quizRules]}
                    >
                      {!isReadOnly ? (
                        <Input
                          placeholder="Lesson Name"
                          bordered={!isReadOnly}
                          readOnly={isReadOnly}
                          disabled={!isReadOnly}
                        />
                      ) : (
                        <p>{`${quizQuestions?.lessonId?.course?.[0]?.chapterName} - ${quizQuestions?.lessonId?.name}`}</p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      label="Course Name :"
                      name="courseId"
                      rules={[quizRules]}
                    >
                      <Select
                        key={selectedCourse}
                        showSearch
                        suffixIcon={null}
                        bordered={!isReadOnly}
                        disabled={isReadOnly || !isReadOnly}
                        placeholder="-- Select Course --"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(id) => {
                          setSelectedCourse(id);
                        }}
                        options={courseOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      label="Random Question Count :"
                      name="randomQuestionCount"
                      rules={[quizRules]}
                    >
                      <InputNumber
                        bordered={!isReadOnly}
                        readOnly={isReadOnly}
                        style={{ width: "100%" }}
                        placeholder="Random Question Count"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      label="Public Question Count :"
                      name="publicQuestionCount"
                      rules={[quizRules]}
                    >
                      <InputNumber
                        bordered={!isReadOnly}
                        readOnly={isReadOnly}
                        style={{ width: "100%" }}
                        placeholder="Public Question Count"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      className="checkingArea"
                      name="activeFlag"
                      rules={[quizRules]}
                      valuePropName="checked"
                    >
                      <Checkbox disabled={isReadOnly}>Active Flag</Checkbox>
                    </Form.Item>
                  </Col>{" "}
                </Row>
                <EditButtonGroup>
                  {isReadOnly ? null : (
                    <ButtonConst
                      htmlType="submit"
                      type="submit"
                      title="Submit"
                      loading={loading}
                      disabled={loading}
                      className="mt-2"
                    >
                      Submit
                    </ButtonConst>
                  )}
                  <Button
                    type="secondary"
                    onClick={() => {
                      setIsReadOnly(!isReadOnly);
                    }}
                    className="mt-2"
                  >
                    {isReadOnly ? "Edit Button" : "Cancel"}
                  </Button>
                </EditButtonGroup>
              </Form>
            </Col>
            <Col xs={24} lg={11}>
              <Row gutter={30}>
                <Table
                  dataSource={menuDataSource}
                  columns={menuColumns}
                  loading={loading}
                  pagination={false}
                  bordered={true}
                />
              </Row>
            </Col>
          </Row>
        </Skeleton>
        <Row style={{ marginBottom: "40px" }} gutter={30}>
          <Col
            style={{ marginTop: "40px" }}
            xs={{ span: 24 }}
            md={{ span: 24 }}
          >
            <Skeleton active loading={isSkeleton}>
              <div
                style={{ margin: "2px", marginBottom: "30px" }}
                className="mainHeading"
              >
                <div>
                  <h3>Questions</h3>
                  <span className="total">
                    {questionDataSource.length} questions
                  </span>
                </div>
                <CustomBtn
                  htmlType="submit"
                  type="submit"
                  title="Add Question"
                  onClick={() => {
                    navigate(`/admin/quiz/${quizId}/add-question`);
                  }}
                />
              </div>

              <TableContainer
                loading={loadingQuestions}
                dataSource={questionData}
                columns={questionColumns}
                pagination={{
                  pageSize: questionDataSource.length,
                  total: questionDataSource.length || 0,
                  onChange: (page) =>
                    setQuestionPagination({
                      ...questionPagination,
                      current: page,
                    }),
                }}
              />
            </Skeleton>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default QuestionsGrid;
