import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 0em 3em 2em;
  // h1 {
  //   margin: 0 auto 1em;
  // }
  .userCard {
    max-width: 260px;
    a {
      display: inline-block;
      margin-top: 1rem;
    }
    p {
      font-style: italic;
      font-weight: 300;
      font-size: 22px;
      margin: 1rem 0;
      strong {
        font-weight: 900;
      }
    }
  }
  .about {
    p {
      font-size: 16px;
      line-height: 20px;
      color: #313131;
      opacity: 0.6;
    }
  }
  @media (max-width: 992px) {
    & {
      padding: 0px 0px;
    }
  }
`;
export const CardHeader = styled("div")`
  margin-bottom: 1em;
  img {
    margin: 0 0 1em;
    box-shadow: 20px 20px 0 1px #f5f8fd;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 20px;
  position: relative;
  letter-spacing: 0em;
  font-family: "Source Sans Pro";
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  margin-top: 2.2rem;
  margin-bottom: 6rem;

  .heading {
    text-align: center;
    font-size: 46px;
    font-weight: 300;
    margin-bottom: 2em;
    line-height: 58px;
    color: #313131;
  }

  @media (max-width: 768px) {
    padding: 10px;

    .heading {
      font-size: 36px;
      line-height: 48px;
    }
  }
`;

export const Section = styled("div")`
  margin-top: 20px 0;
  margin: 0 auto;
  justify-content: center;
  color: #313131;
  width: 80%;

  .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin: 20px 0;
  }

  ol {
    margin-left: 10px;
    margin: 10px 0;

    li {
      font-size: 16px;
      line-height: 1.89;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.89;
    display: inline;
    margin: 10px 0;
  }

  span {
    font-weight: 600;
    display: inline;
    margin: 10px 0;
  }

  @media (max-width: 768px) {
    width: 90%;

    .title {
      font-size: 18px;
      line-height: 24px;
    }

    ol {
      margin-left: 0;
      padding-left: 20px;

      li {
        font-size: 16px;
      }
    }

    p {
      font-size: 16px;
      line-height: 1.89;
      display: inline;
    }

    span {
      font-weight: 600;
      display: inline;
    }
  }
`;
