import { Button, Checkbox, Radio } from "antd";
import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 2em 4em;
  font-family: "Source Sans Pro";
  .Container {
    margin: 20px 0px;
  }
  .Heading {
    margin: 50px 0px;
  }
  .imageContainer {
    width: auto;
    height: auto;
    margin-bottom: 10px;
    margin-top: 35px;
  }

  .question {
    margin-top: 2em;
    margin-bottom: 10px;
    font-size: 28px;
    color: black;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }
  .questionCount {
    margin-top: 2em;
  }
  .HeadingText {
    font-size: 15px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #3f6fd8;
  }
  .mainHeading {
    display: flex;
    justify-content: space-between;
    .CompletedSection {
      width: 300px;
    }
    .CompletedText {
      font-weight: 400;
      font-size: 15px;
      line-height: 26.62px;
    }
    h3 {
      text-transform: uppercase;
      color: #313131;
      font-weight: 600;
      font-size: 22px;
    }
    .total {
      font-weight: 400;
      color: #31313160;
      font-size: 16px;
    }
    .ant-form-item {
      width: 350px;
    }
  }
`;

export const CustomBtn = styled(Button)`
  background: #d39331 !important;
  border: 1px solid #d39331 !important;
  width: 280px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;

  .loader {
    font-size: 2rem !important;
  }

  &:disabled {
    background: #d39331;
    border: 1px solid #d39331;
    width: 280px;
    height: 50px;
    border-radius: 0;
    opacity: 0.5;

    &:hover {
      background: #d39331;
      border: 1px solid #d39331;
      border-radius: 0;
      opacity: 0.5;
    }
  }

  span {
    font-size: 16px;
    color: #ffffff;
  }

  &:focus,
  &:hover {
    background: #d39331;
    border-radius: 0;
    border: 1px solid #d39331;
    opacity: 0.9;

    span {
      color: #ffffff;
    }
  }
  @media (max-width: 992px) {
    & {
      width: 250px;
      height: 45px;
      padding: 0px 0px;
      span {
        // font-size: 1em !important;
      }
      span {
        font-size: 12px;
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    & {
      width: 180px;
      height: 40px;
      span {
        font-size: 12px !important;
      }
    }
  }
`;

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  padding: 14px 2px 4px;
  // .ant-form-item {
  //   margin-bottom: 2em;
  // }
  .ant-checkbox-wrapper {
    margin-right: 1em;
    span {
      font-size: 17.5px;
      margin-right: 0px !important;
    }
    .ant-checkbox-inner {
      // border-color: ${({ lockedflag }) => lockedflag && "#f7dede !important"}
    }
    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-inner:focus {
      background-color: ${({ lockedflag }) => lockedflag && "green !important"};
      border-color: ${({ lockedflag }) => lockedflag && "green !important"};
    }
  }
`;

export const StyledRadioGroup = styled(Radio.Group)`
  font-size: 18px !important;
  padding: 32px 0;

  .ant-radio-inner {
    // border-color: ${({ lockedflag }) => lockedflag && "#f7dede !important"}
  }

  span {
    font-size: 17.5px;
    margin-right: 0px !important;
  }

  .ant-radio-checked .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner:focus {
    border-color: ${({ lockedflag }) => lockedflag && "green !important"};
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${({ lockedflag }) => lockedflag && "green !important"};
  }
`;
