import { Container } from "react-bootstrap";
import { TableContainer, Wrapper } from "../../Forum/manageTopic/index.style";
import { CustomSelect } from "../../../common/select/index.style";
import { useEffect, useState } from "react";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Skeleton,
  Space,
} from "antd";
import CustomBtn from "../../../common/button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCourses } from "../../../redux/courseSlice";
import { antValidator, paginate } from "../../../utils/helper";
import CustomPagination from "../../../common/pagination";
import { Scroller } from "../../liveClasses/index.style";
import { CustomInput } from "../../../common/input/index.style";
import { assignmentSchema } from "../../../utils/validationSchema";
import { AddQuiz } from "../adminQuizes/index.style";
import { getAdminList } from "../../../redux/userSlice";
import {
  addAssignment,
  getCourseAssignment,
  handleAssignmentActiveFlag,
} from "../../../redux/assignmentSlice";
import { Helmet } from "react-helmet";

function AssignmentsTasks() {
  const pageSize = 10;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [assignmentForm] = Form.useForm();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [adminUser, setAdminUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { courseAssignments, loading } = useSelector(
    (state) => state?.assignments
  );
  const { adminUserList } = useSelector((state) => state?.user);
  const { adminCourses, courseLoading = true } = useSelector(
    (state) => state?.course
  );
  const [isOpenAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [assignmentRules, setAssignmentRules] = useState({});
  const [activeFlag, setActiveFlag] = useState(null);

  useEffect(() => {
    dispatch(getAdminCourses());
    dispatch(getAdminList());
    setAssignmentRules(antValidator(assignmentSchema));
  }, [dispatch]);

  useEffect(() => {
    if (adminCourses?.[0]?._id) {
      setSelectedCourse(adminCourses?.[0]?._id);
      dispatch(getCourseAssignment(adminCourses?.[0]?._id));
    }
  }, [adminCourses, dispatch]);

  const courseOptions =
    adminCourses?.map((course) => ({
      value: course?._id,
      label: course?.longName,
    })) || [];

  const adminUserListOptions =
    adminUserList?.map((user) => ({
      value: user?._id,
      label: user?.name,
    })) || [];

  const dataSource = [];
  paginate(courseAssignments || [], currentPage, pageSize)?.map(
    (assignmentData, i) => {
      dataSource.push({
        key: i,
        sequence: assignmentData?.sequence,
        assignmentName: assignmentData?.longName,
        taskCount: assignmentData?.Tasks?.length || 0,
        activeFlag: (
          <div
            style={{
              color: assignmentData?.activeFlag ? "#00AC47" : "#C91D2E",
            }}
          >
            {assignmentData?.activeFlag ? "Yes" : "No"}
          </div>
        ),
        actions: (
          <Space size="middle" className="deleteBtn">
            <Link to={`/admin/assignment/${assignmentData?._id}/tasks`}>
              <img
                src="/icons/pen-icon.svg"
                alt="i"
                width="19px"
                height="19px"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
            <Link
              onClick={() => {
                const data = { activeFlag: !assignmentData?.activeFlag };
                dispatch(
                  handleAssignmentActiveFlag({
                    assignmentId: assignmentData?._id,
                    courseId: selectedCourse,
                    data,
                  })
                );
              }}
            >
              <img
                src="/icons/bin-icon.svg"
                alt="i"
                width="19px"
                height="19px"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
          </Space>
        ),
      });
    }
  );

  const columns = [
    {
      title: "Sequence",
      dataIndex: "sequence",
      key: "sequence",
    },
    {
      title: "Assignment Name",
      dataIndex: "assignmentName",
      key: "assignmentName",
    },
    {
      title: "Task Count#",
      dataIndex: "taskCount",
      key: "taskCount",
    },
    {
      title: "Active",
      dataIndex: "activeFlag",
      key: "activeFlag",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const assignmentsFormReset = () => {
    assignmentForm.resetFields();
    setOpenAssignmentModal(false);
  };

  const handleAssignmentSubmit = async (values) => {
    const formDataObject = {
      courseArray: values?.courseId,
      userArray: values?.userId,
      activeFlag: activeFlag,
      contentURL: values?.assignmentContentURL,
      longName: values?.assignmentLongName,
      shortName: values?.assignmentShortName,
      totalMarks: values?.totalMarks,
      description: values?.description,
      sequence: Number(values?.sequence),
      action: "add",
    };
    dispatch(
      addAssignment({
        formDataObject,
        courseId: selectedCourse,
        onReset: assignmentsFormReset,
      })
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Assignment</title>
      </Helmet>
      <Container style={{ margin: "20px 0px" }} fluid>
        <Skeleton active loading={courseLoading}>
          <div className="mainHeading">
            <div>
              <h3>Assignment</h3>
              <span className="total">
                {courseAssignments.length} Assignments
              </span>
            </div>
            <CustomBtn
              htmlType="submit"
              type="submit"
              title="Add Assignment"
              onClick={() => {
                setOpenAssignmentModal(true);
              }}
            />
          </div>
          <div className="mainHeading">
            <div style={{ flex: 1 }} />
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  placeholder="-- Select Course --"
                  defaultValue={selectedCourse}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(id) => {
                    dispatch(getCourseAssignment(id));
                    setSelectedCourse(id);
                  }}
                  options={courseOptions}
                />
              </CustomSelect>
            </div>
          </div>
          <TableContainer
            loading={loading}
            dataSource={dataSource}
            columns={columns}
          />
          {courseAssignments.length > 10 ? (
            <CustomPagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={courseAssignments.length}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          ) : null}
          <Modal
            title={null}
            style={{ top: 20 }}
            open={isOpenAssignmentModal}
            onOk={() => assignmentsFormReset()}
            onCancel={() => assignmentsFormReset()}
            footer={null}
            width={490}
          >
            <AddQuiz
              layout="vertical"
              style={{ borderBottom: "0px solid #ffff" }}
              onFinish={handleAssignmentSubmit}
              form={assignmentForm}
            >
              <center className="mb-4">
                <h2>Add New Assignment</h2>
              </center>
              <Scroller>
                <CustomInput
                  name="assignmentLongName"
                  rules={[assignmentRules]}
                >
                  <Input placeholder="Assignment Long Name" />
                </CustomInput>
                <CustomInput
                  name="assignmentShortName"
                  rules={[assignmentRules]}
                >
                  <Input placeholder="Assignment Short Name" />
                </CustomInput>
                <CustomInput name="sequence" rules={[assignmentRules]}>
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Sequence"
                  />
                </CustomInput>
                <CustomInput name="totalMarks" rules={[assignmentRules]}>
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Total marks"
                  />
                </CustomInput>
                <CustomInput name="description" rules={[assignmentRules]}>
                  <TextArea
                    rows={7}
                    placeholder="Description"
                    showCount
                    maxLength={1000}
                  />
                </CustomInput>

                <CustomInput name="assignmentContentURL">
                  <Input placeholder="Assignment Content URL" />
                </CustomInput>

                <CustomSelect name="courseId" rules={[assignmentRules]}>
                  <Select
                    key={selectedCourse}
                    mode="multiple"
                    showSearch
                    placeholder="-- Select Course --"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={courseOptions}
                  />
                </CustomSelect>
                <CustomSelect name="userId" rules={[assignmentRules]}>
                  <Select
                    key={adminUser}
                    mode="multiple"
                    showSearch
                    placeholder="-- Select Admin User --"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(id) => {
                      setAdminUser(id);
                    }}
                    options={adminUserListOptions}
                  />
                </CustomSelect>

                <p className="checkingArea">
                  <Checkbox
                    checked={activeFlag}
                    rules={[assignmentRules]}
                    onClick={(e) => setActiveFlag(e.target.checked)}
                  >
                    Active Flag
                  </Checkbox>
                </p>
              </Scroller>
              <center>
                <CustomBtn
                  htmlType="submit"
                  type="submit"
                  title="Submit"
                  loading={loading}
                  disable={loading}
                />
              </center>
            </AddQuiz>
          </Modal>
        </Skeleton>
      </Container>
    </Wrapper>
  );
}

export default AssignmentsTasks;
