import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg } from "../components/AlertMessage";
import { ApiRequests } from "../services/ApiRequest";

export const getSubscribedPlans = createAsyncThunk(
  "payment/getSubscribedPlans",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getSubscribedPlans();
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw error;
    }
  }
);

export const getTransactions = createAsyncThunk(
  "payment/transactions",
  async (selectedPaymentGateway, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.transactions(selectedPaymentGateway);
      if (response.status === 200) {
        return response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const getPaymentHistory = createAsyncThunk(
  "payment/getPaymentHistory",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getPaymentHistory();
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  loading: false,
  subscribedPlans: [],
  paymentHistory: [],
  transactions: {},
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscribedPlans.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSubscribedPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.subscribedPlans = action.payload;
      })
      .addCase(getSubscribedPlans.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getTransactions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(getTransactions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPaymentHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentHistory = action.payload;
      })
      .addCase(getPaymentHistory.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default paymentSlice.reducer;
