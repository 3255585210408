import React from "react";
import { Avatar } from "antd";
import { BsRobot } from "react-icons/bs";

const BotAvatar = () => {
  return (
    <div className="chatbot-avatar">
      <Avatar
        size={30}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#1e3465",
          color: "#fff",
        }}
        icon={<BsRobot />}
      />
    </div>
  );
};

export default BotAvatar;
