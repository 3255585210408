import styled, { keyframes } from "styled-components";
import { Input, Button } from "antd";

export const Container = styled.div`
  margin: 20px auto;
  padding: 20px;
  // border: 1px solid #ccc;
  border-radius: 5px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  color: #333;
  background-color: #fff;
  .contentUrl {
    font-size: 13px;
    width: 95%;
  }
  .chapter {
    margin: 10px 0 20px 0;
    // color: #555;
    font-weight: 600;
  }

  // Mobile styles
  @media (max-width: 768px) {
    margin: 0 auto;
    padding: 0;
    .contentUrl {
      font-size: 11px;
      margin-right: 20px;
    }

    .chapter {
      font-size: 13px;
      margin: 8px 0 18px 0;
    }
  }
`;

export const Title = styled.h1`
  margin: 1em auto;
  text-align: center;
  color: #333;
`;

export const LiveClass = styled.div`
  margin: 20px 0;
  background-color: rgba(211, 147, 49, 0.1);
  padding: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center; 
`;

export const FlashNewsIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-color: #ffd700;
  border-radius: 50%;
  animation: ${keyframes`
    0%, 50%, 100% {
      opacity: 0;
    }
    25%, 75% {
      opacity: 1;
    }
  `} 1.5s linear infinite;
`;

export const BlinkingText = styled.span`
  width: 100%;

  animation: ${keyframes`
    0%, 25%, 50%, 75%, 100% {
      opacity: 0;
    }
    12.5%, 37.5%, 62.5%, 87.5% {
      opacity: 1;
    }
  `} 10s linear infinite;
`;

export const Marquee = styled.marquee`
  animation: marquee 15s linear infinite;
  transition: transform 4s ease;
`;

export const StyledInput = styled(Input)`
  width: 200px;
  margin: 1em;
  color: black;
  background-color: rgba(211, 147, 49, 0.1);
  border: 1px solid #d39331;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  outline: none;

  &:hover {
    background-color: rgba(211, 147, 49, 0.2);
  }
`;

export const StyledButton = styled(Button)`
  margin: 1em;
  color: #fff;
  height: 100%;
  background-color: #d39331;
  border: 1px solid #d39331;
  border-radius: 3px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  outline: none;

  &:hover {
    background-color: #af7d26;
    border-color: #af7d26;
  }
`;

export const Centered = styled.div`
  text-align: center;
`;

export const Error = styled.div`
  color: red;
  margin-top: 5px;
`;

export const XmlContent = styled.pre`
  white-space: pre-wrap;
`;
