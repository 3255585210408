import { Container } from "react-bootstrap";
import { TableContainer } from "../Forum/manageTopic/index.style";
import { CustomSelect } from "../../common/select/index.style";
import { useEffect, useState } from "react";
import { Select, Space, Spin } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCourses } from "../../redux/courseSlice";
import { getPublicCourseQuiz } from "../../redux/questionsSlice";
import { InfoCircleFilled } from "@ant-design/icons";
import { QuizContainer } from "./index.style";
import moment from "moment";
import { getUserCourses } from "../../redux/userSlice";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";

function PublicQuizList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseName } = useParams();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [enrolled, setEnrolled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const { adminCourses, courseLoading } = useSelector((state) => state?.course);
  const { userProfile } = useSelector((state) => state?.auth);
  const { userCourses } = useSelector((state) => state?.user);
  const [selectedCourseName, setSelectedCourseName] = useState(null);

  const { publicCourseQuiz, loading } = useSelector(
    (state) => state?.questions
  );
  useEffect(() => {
    dispatch(getAdminCourses());
    dispatch(getUserCourses());
  }, [dispatch]);

  useEffect(() => {
    if (adminCourses?.length > 0) {
      const searchCourse = adminCourses?.filter(
        ({ shortName }) => shortName === courseName
      );
      const firstCourseId = searchCourse[0]?._id;
      setSelectedCourse(firstCourseId);
      setSelectedCourseName(searchCourse[0]?.shortName);
      dispatch(getPublicCourseQuiz(firstCourseId));
    }
  }, [adminCourses, dispatch, courseName]);

  const handleCourseChange = (id, name) => {
    navigate(`/${name?.shortName}/database_quiz_questions`);
  };

  useEffect(() => {
    const checkEnrollmentAndSubscription = () => {
      if (!userProfile || !userProfile.subscription) {
        setEnrolled(false);
        return;
      }
      const subscriptionForSelectedCourse =
        userProfile?.subscription?.quizAccess?.find(
          (access) => access?.courseId === selectedCourse
        );
      if (!subscriptionForSelectedCourse) {
        setEnrolled(false);
        return;
      }
      const expiryDate = moment(subscriptionForSelectedCourse?.expiryDate);
      const isSubscriptionValid = expiryDate.isAfter(moment(), "day");
      const differenceInDays = expiryDate.diff(moment(), "days");
      setEnrolled(isSubscriptionValid || differenceInDays >= 0);
    };
    checkEnrollmentAndSubscription();
  }, [selectedCourse, userProfile]);

  const courseOptions =
    adminCourses?.map(({ _id, longName, shortName }) => ({
      value: _id,
      label: longName,
      shortName: shortName,
    })) || [];

  const dataSource = [];
  (publicCourseQuiz || [])?.forEach((data, i) => {
    dataSource.push({
      key: i,
      s_no: i + 1,
      name: `${data?.lessonId?.chapterName?.[0]} - ${data?.lessonId?.name}`,
      questionCount:
        data?.questionsCount >= data.publicQuestionCount
          ? data.publicQuestionCount
          : 0,
      actions: (
        <Space size="middle" className="deleteBtn">
          <Link
            disabled={!(data?.questionsCount >= data.publicQuestionCount)}
            to={`/quiz_questions?course=${selectedCourseName}&topic=${data?.lessonId?.quizShortName}`}
          >
            Practice Test
          </Link>
          {userCourses && userCourses[0]?.courseId && enrolled ? (
            <Link
              style={{ color: "#00AC47" }}
              to={`/quiz/response/${userCourses?.[0]?.courseId}/questions`}
            >
              Attempt Test
            </Link>
          ) : (
            <Link
              style={{ color: "#d39331" }}
              to="/learn_sql_power_bi_analytics_online_training_cost"
            >
              Subscribe Now
            </Link>
          )}
        </Space>
      ),
    });
  });

  const columns = [
    {
      title: "S.No",
      dataIndex: "s_no",
      key: "s_no",
    },
    {
      title: "Quiz Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Question Count#",
      dataIndex: "questionCount",
      key: "questionCount",
    },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  return (
    <QuizContainer>
      <MetaHelmet {...metaHelmetData[`${courseName}_database_quiz_questions`]} />
      <Container style={{ margin: "20px 0px" }} fluid>
        <Spin spinning={courseLoading}>
          <div className="mainHeading">
            <div>
              <h1 className="heading">SQL Database Quiz Questions</h1>
              <div className="total mt-2 mb-2">
                This course contains {publicCourseQuiz?.courseQCount} questions,
                while there are {publicCourseQuiz?.allCourseQCount} questions in
                total across all courses.
              </div>

              <div className="subscriptionWrapper">
                <div className="subscriptionBox">
                  <InfoCircleFilled className="subscriptionIcon" />
                  {Object.keys(userProfile).length > 0 ? (
                    !enrolled ? (
                      <p>
                        Subscribe for complete access &nbsp;
                        <Link
                          to="/learn_sql_power_bi_analytics_online_training_cost"
                          style={{ textDecoration: "underline" }}
                        >
                          click here
                        </Link>
                      </p>
                    ) : (
                      <p>Enroll to monitor your progress</p>
                    )
                  ) : (
                    <p>
                      To gain complete access, login with gmail or outlook
                      &nbsp;
                      <Link to="/login" style={{ textDecoration: "underline" }}>
                        click here
                      </Link>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  disabled={!courseLoading && loading}
                  placeholder="-- Select Course --"
                  value={selectedCourse}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={handleCourseChange}
                  options={courseOptions}
                />
              </CustomSelect>
            </div>
          </div>

          <TableContainer
            loading={!courseLoading && loading}
            dataSource={dataSource}
            columns={columns}
            pagination={{
              pageSize: publicCourseQuiz.length,
              total: publicCourseQuiz.length || 0,
              onChange: (page) => setCurrentPage(page),
            }}
          />
        </Spin>
      </Container>
    </QuizContainer>
  );
}

export default PublicQuizList;
