import { Container } from "react-bootstrap";
import { TableContainer, Wrapper } from "../../Forum/manageTopic/index.style";
import { useEffect, useState } from "react";
import { Skeleton, Space, Table } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paginate } from "../../../utils/helper";
import CustomPagination from "../../../common/pagination";
import { getALLContactUs, toggleMessageRead } from "../../../redux/contactSlice";
import moment from "moment";
import { Helmet } from "react-helmet";

function AdminContactUs() {
  const pageSize = 10;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, contactUs } = useSelector((state) => state?.contact);
  const [isSkeleton, setSkeleton] = useState(true);

  useEffect(() => {
    dispatch(getALLContactUs());
  }, [dispatch]);
  
  useEffect(() => {
    setSkeleton(!((contactUs?.length)>=0))
  }, [contactUs]);

  const dataSource = [];
  paginate(contactUs || [], currentPage, pageSize)?.map((data) =>
    dataSource.push({
      key: data?._id,
      date: moment(data?.createdAt).format("DD-MM-YYYY"),
      name: data?.name,
      email: data?.email,
      purpose: data?.purpose,
      message: data?.message,
      readFlag: (
        <Space size="middle" className="deleteBtn">
          <Link
            onClick={() => {
              dispatch(toggleMessageRead({ id: data?._id }));
            }}
            style={{ color: data?.messageRead ? "#00AC47" : "#C91D2E" }}
          >
            {data?.messageRead ? "Yes" : "No"}
          </Link>
        </Space>
      ),
    })
  );

  const columns = [
    Table.EXPAND_COLUMN,

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "Read Status",
      dataIndex: "readFlag",
      key: "readFlag",
    },
  ];

  return (
    <Wrapper>
        <Helmet>
      <title>Tansy Academy Admin | Contact Us </title>
    </Helmet>
      <Container style={{ margin: "20px 0px" }} fluid>
        <Skeleton active loading={isSkeleton}>
          <div className="mainHeading">
            <div>
              <h3 style={{ textDecoration: "uppercase" }}>Contact Us</h3>
              <div className="total mt-2 mb-3">
                {contactUs.length} contact us
              </div>
            </div>
            <div style={{ flex: 1 }} />
          </div>
          <TableContainer
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => (
                <span style={{ whiteSpace: "pre-line" }}>{record.message}</span>
              ),
            }}
          />
          {contactUs.length > 10 && (
            <CustomPagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={contactUs.length}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          )}
        </Skeleton>
      </Container>
    </Wrapper>
  );
}

export default AdminContactUs;
