// import { Table } from "antd";
import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 2em 0;

  h1 {
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
    color: #313131;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    color: #31313160;
    .note {
      margin-bottom: 2em;
    }
  }
  .switch {
    margin: 0 1em;
    .ant-switch {
      background: #3468d6;
      min-width: 60px;
    }
    .activePlan {
      color: #000000 !important;
      opacity: 1;
      transition: all;
      transition-duration: 0.5s;
    }
    strong {
      margin: 1em;
      color: #313131;
      opacity: 0.4;
      transition-duration: 0.5s;
    }
  }
`;
