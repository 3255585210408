import { Container } from "react-bootstrap";
import { TableContainer, Wrapper } from "../Forum/manageTopic/index.style";
import { CustomSelect } from "../../common/select/index.style";
import { useEffect, useState } from "react";
import { Select, Skeleton, Space, Spin } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCourses } from "../../redux/courseSlice";
// import { paginate } from "../../utils/helper";
// import CustomPagination from "../../common/pagination";
import { getInterviewQuestionsCount } from "../../redux/interviewQuestionsSlice";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";

function PublicInterviewQuestionsList() {
  const dispatch = useDispatch();
  const { courseName } = useParams();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseName, setSelectedCourseName] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const { adminCourses, courseLoading } = useSelector((state) => state?.course);
  const { loading, interviewQuestions } = useSelector(
    (state) => state?.interviewQuestions
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAdminCourses());
  }, [dispatch]);

  useEffect(() => {
    if (adminCourses?.length > 0) {
      const searchCourse = adminCourses?.filter(
        ({ shortName }) => shortName === courseName
      );
      const firstCourseId = searchCourse[0]?._id;
      setSelectedCourse(firstCourseId);
      setSelectedCourseName(searchCourse[0]?.shortName);
      dispatch(getInterviewQuestionsCount(firstCourseId));
    }
  }, [adminCourses, dispatch, courseName]);

  const courseOptions =
    adminCourses?.map(({ _id, longName, shortName }) => ({
      value: _id,
      label: longName,
      shortName: shortName,
    })) || [];

  const interviewQuestionsData = (interviewQuestions || [])
    .filter((data) => data?.questionsCount > 0)
    .sort((a, b) => a.sequence - b.sequence);

  const handleCourseChange = (id, name) => {
    navigate(`/${name?.shortName}/database_interview_questions`);
  };

  const dataSource = [];
  (interviewQuestionsData || [])?.map((data, i) =>
    dataSource.push({
      key: data?.interviewquestionId,
      s_no: i + 1,
      name: data?.name,
      courseName: data?.courseName,
      // interviewShortName: data?.interviewShortName,
      questionCount: data?.questionsCount,
      actions: (
        <Space size="middle" className="deleteBtn">
          <Link
            to={`/interview_questions?course=${selectedCourseName}&topic=${data?.interviewShortName}`}
          >
            View Questions
          </Link>
        </Space>
      ),
    })
  );

  const columns = [
    {
      title: "S.No",
      dataIndex: "s_no",
      key: "s_no",
    },
    {
      title: "Chapter Name - Lesson Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Question Count#",
      dataIndex: "questionCount",
      key: "questionCount",
    },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  return (
    <Wrapper>
      <MetaHelmet
        {...metaHelmetData[`${courseName}_database_interview_questions`]}
      />
      <Container style={{ margin: "2rem 0px" }} fluid>
        <Spin spinning={courseLoading}>
          <div className="mainHeading">
            <div>
              {!(dataSource?.length >= 0) || courseLoading || loading ? (
                <Skeleton.Input active size="default" />
              ) : (
                <>
                  <h3>
                    {selectedCourseName?.toUpperCase()} Interview Questions
                  </h3>
                  <span className="total">
                    {dataSource.length || 0} lessons
                  </span>
                </>
              )}
            </div>

            <div>
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  disabled={!courseLoading && loading}
                  placeholder="-- Select Course --"
                  value={selectedCourse}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={handleCourseChange}
                  options={courseOptions}
                />
              </CustomSelect>
            </div>
          </div>

          <TableContainer
            loading={!courseLoading && loading}
            dataSource={loading ? [] : dataSource.length > 0 ? dataSource : []}
            columns={columns}
            pagination={{
              pageSize: interviewQuestions.length,
              total: interviewQuestions.length || 0,
              onChange: (page) => setCurrentPage(page),
            }}
          />
        </Spin>
      </Container>
    </Wrapper>
  );
}

export default PublicInterviewQuestionsList;
