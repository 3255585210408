import { Container } from "react-bootstrap";
import { TableContainer } from "../Forum/manageTopic/index.style";
import { CustomSelect } from "../../common/select/index.style";
import { useEffect, useState } from "react";
import { Select, Skeleton, Space } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { paginate } from "../../utils/helper";
import CustomPagination from "../../common/pagination";
import { LoadingOutlined, InfoCircleFilled } from "@ant-design/icons";
import moment from "moment";
import {
  addQuestionsResponse,
  getQuizResponse,
} from "../../redux/questionsResSlice";
import { getUserCourses } from "../../redux/userSlice";
import { Wrapper } from "./index.style";
import { Helmet } from "react-helmet";

function QuizResponse() {
  const [isSkeleton, setSkeleton] = useState(true);
  const pageSize = 10;
  const { courseId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    quizResponse = {},
    quizLoading,
    loading = true,
  } = useSelector((state) => state?.questionsRes);
  const { userCourses, loading: courseLoading = true } = useSelector(
    (state) => state?.user
  );
  const {
    quizQuestions = [],
    isUserEnrollCourse = false,
    isSubscriptionActive = true,
  } = quizResponse || {};
  
  useEffect(() => {
    dispatch(getUserCourses());
  }, [dispatch]);

  useEffect(() => {
    setSkeleton(!Object.keys(quizResponse).length > 0);
  }, [quizResponse]);

  useEffect(() => {
    setSelectedCourse(courseId);
    dispatch(getQuizResponse({ courseId, navigate }));
  }, [dispatch, navigate, courseId]);

  const { userProfile } = useSelector((state) => state.auth);
  const quizExpiry = userProfile?.subscription?.quizAccess?.reduce(
    (array, { courseId: id, expiryDate }) => {
      if (id === courseId) array.push(expiryDate);
      return array;
    },
    []
  );

  const quizSubscription = quizExpiry?.length
    ? moment(quizExpiry[0]).diff(moment(), "days") > 0 &&
      moment(quizExpiry[0]).isValid()
    : false;

  const courseOptions = userCourses?.map(({ courseId, longName }) => ({
    value: courseId,
    label: longName,
  }));

  const dataSource = paginate(
    (quizQuestions || [])
      .filter(({ activeFlag }) => activeFlag)
      .sort((a, b) => a.sequence - b.sequence)
      .map((quizData, i) => {
        const {
          lockedFlag: isLocked = false,
          attemptFlag: isCompleted = false,
          totalScore,
          totalQuestions,
          lessonId,
          quizId,
          randomQuestionCount,
        } = quizData || {};

        const date = Date.now();
        const isEligible =
          isUserEnrollCourse && isSubscriptionActive && quizSubscription;

        const handleClick = async () => {
          setLoader(quizId);
          await dispatch(
            addQuestionsResponse({
              courseId: selectedCourse,
              data: { quizId, randomQuestionCount },
              navigate,
              date,
              lockedFlag: true,
              quizId,
            })
          );
        };

        return {
          key: i,
          s_no: i + 1,
          quizName: `${lessonId?.course?.[0]?.chapterName || ""} - ${
            lessonId?.name || ""
          }`,
          score: (
            <center>
              {isCompleted ? ( `${totalScore || 0} / ${totalQuestions || 0}` ) :
              isLocked ? ("_") : ( "N/A" )}
            </center>
          ),
          completed: (
            <div style={{ color: isCompleted ? "#00AC47" : "#C91D2E" }}>
              {isCompleted ? "Yes" : "No"}
            </div>
          ),
          locked: (
            <div style={{ color: isLocked ? "#00AC47" : "#C91D2E" }}>
              {isLocked ? "Yes" : "No"}
            </div>
          ),
          actions: (
            <Space size="middle" className="deleteBtn">
              {!isCompleted && !isLocked && isEligible && (
                <Link style={{ color: "#D39331" }} onClick={handleClick}>
                  {quizLoading && quizId === loader ? (
                    <LoadingOutlined className="loader" />
                  ) : (
                    "Start"
                  )}
                </Link>
              )}
              {!isCompleted && isLocked && isEligible && (
                <Link
                  style={{ color: "#3F6FD8" }}
                  to={`/quiz/response/${courseId}/${quizId}/question?status=active&isLockFlag=${isLocked}&date=${date}`}
                >
                  Test
                </Link>
              )}
              {!isCompleted && !isEligible && !isEligible && (
                <Link
                  style={{ color: "#D39331" }}
                  to="/learn_sql_power_bi_analytics_online_training_cost"
                >
                  Enroll
                </Link>
              )}
              {isCompleted && isEligible && isCompleted && (
                <Link
                  to={`/quiz/response/${courseId}/${quizId}/question?status=result&isCompletedFlag=${isCompleted}&date=${date}`}
                  style={{ color: "#00AC47", cursor: "pointer" }}
                >
                  View
                </Link>
              )}
            </Space>
          ),
        };
      }),
    currentPage,
    pageSize
  );

  const columns = [
    { title: "S.No", dataIndex: "s_no", key: "s_no" },
    { title: "Quiz Name", dataIndex: "quizName", key: "quizName" },
    { title: "Completed", dataIndex: "completed", key: "completed" },
    { title: "Locked", dataIndex: "locked", key: "locked" },
    { title: "Score", dataIndex: "score", key: "score" },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy | Quiz Course</title>
      </Helmet>
      <Container style={{ margin: "20px 0px" }} fluid>
        <Skeleton active loading={courseLoading}>
          <div className="mainHeading">
            <div>
              <h3 style={{ marginBottom: "10px" }}>
                {`Quizzes for `}
                {courseOptions?.find((option) => option.value === courseId)
                  ?.label || ""}
              </h3>
              <span className="total">
                {quizQuestions?.length > 0
                  ? `There are ${quizQuestions.length} quizzes available for this course.`
                  : "No quizzes are available for this course at the moment."}
              </span>
              {!isSkeleton && !quizSubscription && !isSubscriptionActive && (
                <div className="subscriptionWrapper">
                  <div className="subscriptionBox">
                    <InfoCircleFilled className="subscriptionIcon" />
                    <p>
                      Subscription is required for online quiz attempts with
                      scoring.{" "}
                      <Link
                        to="/learn_sql_power_bi_analytics_online_training_cost"
                        style={{ textDecoration: "underline" }}
                      >
                        Click here
                      </Link>{" "}
                      for more details.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  placeholder="-- Select Course --"
                  defaultValue={selectedCourse}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(courseId) => {
                    navigate(`/quiz/response/${courseId}/questions`);
                  }}
                  options={courseOptions}
                />
              </CustomSelect>
            </div>
          </div>

          <TableContainer
            loading={loading}
            dataSource={dataSource}
            columns={columns}
          />
          {quizQuestions?.length > 10 && (
            <CustomPagination
              current={currentPage}
              defaultPageSize={pageSize}
              total={quizQuestions?.length}
              onChange={(page) => {
                setCurrentPage(page);
              }}
            />
          )}
        </Skeleton>
      </Container>
    </Wrapper>
  );
}

export default QuizResponse;
