import { Card } from "antd";
import styled from "styled-components";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  .heading {
    padding: 0.5em 0 1.5em 0;
    font-size: 3rem;
    font-weight: 300;
    @media (max-width: 992px) {
      font-size: 2rem;
      font-weight: 400;
    }
  }
  .marquee {
    margin: 0 0 2em 0;
    padding: 10px;
    background-color: #f5f5f5;
  }
  .content {
    width: 100%;
  }
  .categoryContainer {
    margin-bottom: 30px;
    width: 100%;
  }
  h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: 400;
    color: #333;
  }
  .projectsContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .cards {
    text-decoration: none;
    color: inherit;
    margin: 10px;
  }
`;

export const StyledCard = styled(Card)`
  width: 280px;
  height: 340px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .ant-card-cover {
    height: 150px;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .ant-image-img {
      transition: transform 0.3s ease;
    }
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:hover {
      .img {
        transform: scale(1.1);
      }
    }
    &:hover .ant-image-img {
      transform: scale(1.05);
    }
  }

  .ant-card-body {
    padding: 0px;
  }
  .ant-card-meta {
    padding: 16px;
  }

  .ant-card-meta-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #333;
  }

  .subTitle {
    font-size: 14.5px !important;
    padding: 0 0 4px 0;
    color: #007bff;
  }

  .subTitle:hover {
    color: #0056b3;
  }

  .description {
    height: 105px;
    color: #666;
    font-size: 15px !important;
    line-height: 1.4 !important;
    transition: height 0.3s ease, overflow 0.3s ease;
  }

  .description:hover {
    height: 88px;
    transition: height 1s ease;
    overflow-y: auto;
  }

  .ant-card-actions {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-btn {
      padding: 0;
      color: #1890ff;
      border-color: #1890ff;
    }

    .ant-btn:hover {
      color: #40a9ff;
      border-color: #40a9ff;
    }
  }
`;
