import { Container, Row } from "react-bootstrap";
import { TableContainer, Wrapper } from "../../Forum/manageTopic/index.style";
import { CustomSelect } from "../../../common/select/index.style";
import { useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Form,
  InputNumber,
  Modal,
  Select,
  Skeleton,
  Space,
} from "antd";
import CustomBtn from "../../../common/button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAdminCourses } from "../../../redux/courseSlice";
import { antValidator, paginate } from "../../../utils/helper";
import { Scroller } from "../../liveClasses/index.style";
import { CustomInput } from "../../../common/input/index.style";
import { quizSchema } from "../../../utils/validationSchema";
import {
  addQuiz,
  getCourseQuiz,
  handleActiveFlag,
} from "../../../redux/questionsSlice";
import { AddQuiz } from "./index.style";
import { Helmet } from "react-helmet";
import { getCourseSyllabus } from "../../../redux/interviewQuestionsSlice";
import { FlagTwoTone } from "@ant-design/icons";

function QuizQuestions() {
  const dispatch = useDispatch();
  const [quizForm] = Form.useForm();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { courseQuiz, loading } = useSelector((state) => state?.questions);
  const { courseData, syllabusLoading } = useSelector(
    (state) => state?.interviewQuestions
  );
  const { adminCourses } = useSelector((state) => state?.course);
  const [isOpenQuizModal, setOpenQuizModal] = useState(false);
  const [quizRules, setQuizRules] = useState({});
  const [activeFlag, setActiveFlag] = useState(true);
  const [isSkeleton, setSkeleton] = useState(true);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [selectedLessonId, setSelectedLessonId] = useState(null);

  useEffect(() => {
    dispatch(getAdminCourses());
    setQuizRules(antValidator(quizSchema));
  }, [dispatch]);

  useEffect(() => {
    if (adminCourses?.[0]?._id) {
      setSelectedCourse(adminCourses?.[0]?._id);
      dispatch(getCourseQuiz(adminCourses?.[0]?._id));
    }
  }, [dispatch, adminCourses]);

  useEffect(() => {
    setSkeleton(!(Object.keys(adminCourses).length >= 0));
  }, [adminCourses]);

  const courseOptions =
    adminCourses?.map((course) => ({
      value: course?._id,
      label: course?.longName,
    })) || [];

  const chapterOptions = (courseData?.chapters || [])?.map(
    ({ chapterId, chapterName, lessons }) => ({
      value: chapterId,
      label: chapterName,
      lessons: lessons,
    })
  );

  const lessonOptions = (
    chapterOptions?.find((chapter) => chapter.value === selectedChapterId)
      ?.lessons || []
  )?.map(({ lessonId, lessonName }) => ({
    value: lessonId,
    label: lessonName,
  }));

  const dataSource = [];
  let totalQuestions = 0;
  paginate(courseQuiz || [], currentPage, courseQuiz?.length)?.map(
    (quizData, i) => {
      const questionsCount = quizData?.questionsCount || 0;
      totalQuestions += questionsCount;
      dataSource.push({
        key: i,
        s_no: i + 1,
        sequence: quizData?.lessonId?.sequence,
        name: `${quizData?.lessonId?.chapterName} - ${quizData?.lessonId?.name}`,
        randomQuestionCount: quizData?.randomQuestionCount,
        publicQuestionCount: quizData?.publicQuestionCount,
        questionCount: quizData?.questionsCount || 0,
        activeFlag: (
          <div style={{ color: quizData?.activeFlag ? "#00AC47" : "#C91D2E" }}>
            {quizData?.activeFlag ? "Yes" : "No"}
          </div>
        ),
        actions: (
          <Space size="middle" className="deleteBtn">
            <Link to={`/admin/quiz/${quizData?._id}/questions`}>
              <img
                src="/icons/pen-icon.svg"
                alt="i"
                width="19px"
                height="19px"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            </Link>
            <Link
              onClick={() => {
                const data = { activeFlag: !quizData?.activeFlag };
                dispatch(
                  handleActiveFlag({
                    quizId: quizData?._id,
                    courseId: selectedCourse,
                    data,
                  })
                );
              }}
            >
              <FlagTwoTone
                twoToneColor={quizData?.activeFlag ? "#00AC47" : "#C91D2E"}
                style={{ fontSize: "19px" }}
              />
            </Link>
          </Space>
        ),
      });
    }
  );

  const columns = [
    {
      title: "S.No",
      dataIndex: "s_no",
      key: "s_no",
    },
    {
      title: "Chapter Name - Lesson Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Topic Sequence",
      dataIndex: "sequence",
      key: "sequence",
    },
    {
      title: "Random Question Count#",
      dataIndex: "randomQuestionCount",
      key: "randomQuestionCount",
    },
    {
      title: "Public Question Count#",
      dataIndex: "publicQuestionCount",
      key: "publicQuestionCount",
    },
    {
      title: "Total Question Count#",
      dataIndex: "questionCount",
      key: "questionCount",
    },
    {
      title: "Active",
      dataIndex: "activeFlag",
      key: "activeFlag",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const questionsFormReset = () => {
    quizForm.resetFields();
    setOpenQuizModal(false);
  };

  const handleQuizSubmit = async (values) => {
    const formDataObject = {
      courseId: values?.courseId,
      lessonId: values?.lessonId,
      chapterName: values?.chapterName,
      randomQuestionCount: Number(values?.randomQuestionCount),
      publicQuestionCount: Number(values?.publicQuestionCount),
      activeFlag: activeFlag,
      action: "add",
    };
    dispatch(
      addQuiz({
        formDataObject,
        courseId: selectedCourse,
        onReset: questionsFormReset,
      })
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Quiz</title>
      </Helmet>
      <Container style={{ margin: "20px 0px" }} fluid>
        <Skeleton active loading={isSkeleton}>
          <div className="mainHeading">
            <div>
              <h3>Quizes : {courseQuiz.length}</h3>
              <div className="total mt-2">
                Total Questions : {totalQuestions}{" "}
              </div>
            </div>
            <div>
              <CustomBtn
                htmlType="submit"
                type="submit"
                title="Add Quiz"
                onClick={() => {
                  setOpenQuizModal(true);
                }}
              />
            </div>
          </div>
          <div className="mainHeading">
            <div style={{ flex: 1 }} />
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <CustomSelect>
                <Select
                  key={selectedCourse}
                  showSearch
                  placeholder="-- Select Course --"
                  value={selectedCourse}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(id) => {
                    dispatch(getCourseQuiz(id));
                    setSelectedCourse(id);
                  }}
                  options={courseOptions}
                />
              </CustomSelect>
            </div>
          </div>
          <TableContainer
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={{
              pageSize: courseQuiz.length,
              total: courseQuiz.length || 0,
              onChange: (page) => setCurrentPage(page),
            }}
          />
          <Modal
            title={null}
            style={{ top: 20 }}
            open={isOpenQuizModal}
            onOk={() => questionsFormReset()}
            onCancel={() => questionsFormReset()}
            footer={null}
            width={490}
          >
            <AddQuiz
              layout="vertical"
              style={{ borderBottom: "0px solid #ffff" }}
              onFinish={handleQuizSubmit}
              form={quizForm}
            >
              <center className="mb-4">
                <h2>Add New Quiz</h2>
              </center>
              <Scroller>
                <CustomSelect
                  label="Course Name:"
                  name="courseId"
                  rules={[quizRules]}
                >
                  <Select
                    key={selectedCourseId}
                    initialValues={selectedCourseId}
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="-- Select Course --"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(id) => {
                      dispatch(getCourseSyllabus(id));
                      setSelectedCourseId(id);
                      quizForm.setFieldsValue({ chapterName: null });
                      quizForm.setFieldsValue({ lessonId: null });
                      setSelectedChapterId(null);
                      setSelectedLessonId(null);
                    }}
                    options={courseOptions}
                  />
                </CustomSelect>
                {syllabusLoading ? (
                  <>
                    <CustomSelect
                      name="chapterName"
                      label="Chapter Name:"
                      rules={[quizRules]}
                    >
                      <Skeleton.Button
                        active={syllabusLoading}
                        size={"default"}
                        shape={"default"}
                        block={syllabusLoading}
                      />
                    </CustomSelect>
                    <CustomSelect
                      name="lessonId"
                      label="Lesson Name:"
                      rules={[quizRules]}
                    >
                      <Skeleton.Input
                        active
                        size={"default"}
                        shape={"default"}
                        loading={syllabusLoading}
                        block={syllabusLoading}
                      />
                    </CustomSelect>
                  </>
                ) : (
                  <>
                    <CustomSelect
                      name="chapterName"
                      label="Chapter Name:"
                      rules={[quizRules]}
                    >
                      <Select
                        key={selectedChapterId}
                        initialValues={selectedChapterId}
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="-- Select Chapter --"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        loading={syllabusLoading}
                        onChange={(id) => {
                          setSelectedChapterId(id);
                          setSelectedLessonId(null);
                          quizForm.setFieldsValue({ lessonId: null });
                        }}
                        options={chapterOptions}
                      />
                    </CustomSelect>
                    <CustomSelect
                      name="lessonId"
                      label="Lesson Name:"
                      rules={[quizRules]}
                    >
                      <Select
                        key={selectedLessonId}
                        initialValues={selectedLessonId}
                        loading={syllabusLoading}
                        style={{ width: "100%" }}
                        showSearch
                        placeholder="-- Select Lesson --"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(id) => setSelectedLessonId(id)}
                        options={lessonOptions}
                      />
                    </CustomSelect>
                  </>
                )}
                <Row>
                  <Col span={12}>
                    {" "}
                    <CustomInput
                      name="publicQuestionCount"
                      rules={[quizRules]}
                      initialValue={30}
                      label="Number of Public Questions:"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Number of Public Questions"
                      />
                    </CustomInput>
                  </Col>
                  <Col span={12}>
                    <CustomInput
                      name="randomQuestionCount"
                      rules={[quizRules]}
                      initialValue={20}
                      label="Subscription Quiz Questions Count:"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Number of Subscription Quiz Questions"
                      />
                    </CustomInput>
                  </Col>
                </Row>

                <p className="checkingArea">
                  <Checkbox
                    checked={activeFlag}
                    rules={[quizRules]}
                    onClick={(e) => setActiveFlag(e.target.checked)}
                  >
                    Active Flag
                  </Checkbox>
                </p>
              </Scroller>
              <center>
                <CustomBtn
                  htmlType="submit"
                  type="submit"
                  title="Submit"
                  loading={loading}
                  disable={loading || syllabusLoading}
                />
              </center>
            </AddQuiz>
          </Modal>
        </Skeleton>
      </Container>
    </Wrapper>
  );
}

export default QuizQuestions;
