import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Select, Spin } from "antd";
import moment from "moment";
import { Container } from "react-bootstrap";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { getAdminCourses } from "../../redux/courseSlice";
import { getAllLiveClasses } from "../../redux/liveClassSlice";
import { AssignmentsFilteredReview } from "../../redux/assignmentsResSlice";
import { Heading, ShowCoursesCard, Wrapper } from "./index.style";
import { paginate } from "../../utils/helper";
import { Helmet } from "react-helmet";

const LecturerDashboard = () => {
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.auth);
  const { adminCourses } = useSelector((state) => state.course);
  const { filterAndMatchAssignments, loading } = useSelector(
    (state) => state.assignmentsRes
  );
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    dispatch(getAllLiveClasses());
    dispatch(getAdminCourses());
  }, [dispatch]);

  useEffect(() => {
    if (adminCourses?.[0]?._id) {
      setSelectedCourse(adminCourses?.[0]?._id);
      dispatch(AssignmentsFilteredReview(adminCourses?.[0]?._id));
    }
  }, [adminCourses, dispatch]);

  const assignments = paginate(filterAndMatchAssignments || [], 1, 2)
    ?.filter((assignment) => assignment.status === "Submitted")
    ?.map((assignment, index) => ({
      key: index,
      name: assignment.assignmentName || "",
      submissionTime:
        moment(assignment.submitTime).format("hh:mm A - MMMM DD, YYYY") || "",
    }));

  const assignmentReviewsData = [
    {
      backgroundColor: "#E04F5F",
      iconSrc: "/icons/liveclassIcon.svg",
      reviewType: "Assignment Reviews",
      pendingCount: filterAndMatchAssignments.length,
      assignments:
        filterAndMatchAssignments.length > 0
          ? assignments
          : [
              {
                name: "Assignment ",
                submissionTime: "",
              },
            ],
    },
  ];

  const courseOptions =
    adminCourses?.map((course) => ({
      value: course._id,
      label: course.longName,
    })) || [];

  const handleCourseChange = (id) => {
    setSelectedCourse(id);
    dispatch(AssignmentsFilteredReview(id));
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Lecturer | DashBoard </title>
      </Helmet>
      <Container fluid>
        <Heading>
          <p>Welcome!</p>
          <h3>{userProfile?.name}.</h3>
        </Heading>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 16 }}>
            <Row gutter={16}>
              {assignmentReviewsData?.map((review, index) => (
                <Col key={index} xs={{ span: 24 }} md={{ span: 9 }}>
                  <ShowCoursesCard
                    style={{ backgroundColor: review.backgroundColor }}
                  >
                    <Col className="cardHeader">
                      {review.iconSrc && (
                        <img
                          className="imgStyle"
                          src={review.iconSrc}
                          alt="i"
                          onError={(e) => {
                            e.target.style.display = "none";
                          }}
                        />
                      )}
                    </Col>
                    <Col className="topics">
                      <p className="review">{review.reviewType}</p>
                      <Select
                        key={selectedCourse}
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="-- Select Course --"
                        defaultValue={selectedCourse}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={handleCourseChange}
                        options={courseOptions}
                      />
                      <p className="pending-title">Pending</p>
                      <p className="review-count">{review.pendingCount}</p>
                      {loading ? (
                        <center>
                          <Spin />
                        </center>
                      ) : (
                        review.assignments?.map((assignment) => (
                          <div key={assignment.key} className="review-details">
                            <p className="assignment-name">{assignment.name}</p>
                            <p className="submission-time">
                              {assignment.submissionTime}
                            </p>
                            <Link
                              className="review-link"
                              to={`/lecturer/assignments/${selectedCourse}/review`}
                            >
                              Review now{" "}
                              <span>
                                <MdOutlineArrowBackIos
                                  style={{
                                    fontSize: "14px",
                                    transform: "rotate(180deg)",
                                    marginLeft: "5px",
                                  }}
                                />
                              </span>
                            </Link>
                          </div>
                        ))
                      )}
                    </Col>
                  </ShowCoursesCard>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default LecturerDashboard;
