import ChatbotOptions from "./ChatbotOptions";
import {
  ClassScheduleEnquiry,
  ContactSupport,
  OnlinePortalIssues,
  PaymentsAndSubscriptionEnquiry,
} from "./TalkLinks";
import { CourseLinks ,SocialMediaLinks} from "./LinkList";

export const Widgets = [
  {
    widgetName: "chatbotOptions",
    widgetFunc: (props) => <ChatbotOptions {...props} />,
  },
  {
    widgetName: "socialMediaLinks",
    widgetFunc: (props) => <SocialMediaLinks {...props} />,
  },
  {
    widgetName: "courseLinks",
    widgetFunc: (props) => <CourseLinks {...props} />,
  },
  {
    widgetName: "classScheduleEnquiry",
    widgetFunc: (props) => <ClassScheduleEnquiry {...props} />,
  },
  {
    widgetName: "paymentsAndSubscriptionEnquiry",
    widgetFunc: (props) => <PaymentsAndSubscriptionEnquiry {...props} />,
  },
  {
    widgetName: "onlinePortalIssues",
    widgetFunc: (props) => <OnlinePortalIssues {...props} />,
  },
  {
    widgetName: "contactSupport",
    widgetFunc: (props) => <ContactSupport {...props} />,
  },
];
// eslint-disable-next-line no-unused-vars
const courseLinks = [
    {
      text: "MySQL",
      url: "https://tansyacademy.com/course_detail?name=MySQL",
      id: 1,
    },
    {
      text: "PostgreSQL",
      url: "https://tansyacademy.com/course_detail?name=PostgreSQL",
      id: 2,
    },
    {
      text: "Oracle",
      url: "https://tansyacademy.com/course_detail?name=Oracle",
      id: 3,
    },
    {
      text: "Microsoft SQL Server",
      url: "https://tansyacademy.com/course_detail?name=Microsoft%20SQL%20Server",
      id: 4,
    },
  ];