import { Col, Form, Input, Rate, Row } from "antd";
import React from "react";
// import { FaPen } from "react-icons/fa";
import CustomBtn from "../../../common/button";
import CountrySelect from "../../../components/CountrySelect";
import { MainHeading } from "../../home/index.style";
import { Wrapper } from "../index.style";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CustomImage from "../../../common/images";

const ProfileChange = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy | Edit Profile </title>
      </Helmet>
      <section className="profileSection">
        <MainHeading>Profile</MainHeading>
        <div className="container">
          {/* <!-- social inputs --> */}
          <div className="socialInputs">
            <div className="socialLogin">
              <h3>social login</h3>
              <span>
                <CustomImage
                  src="./images/googleImage.svg"
                  alt="i"
                  width="100%"
                  height="100%"
                                       
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
                @123Jane
              </span>

              <div className="userprofile">
                <CustomImage
                  src="/images/loginImage.svg"
                  alt="i"
                  width="100%"
                  height="100%"
                                       
                  onError={(e) => {
                    e.target.style.display = "none";
                  }}
                />
              </div>
              <div className="socialLinks">
                <Link className="btn">Hide</Link>
                <Link className="btn">Remove</Link>
              </div>
            </div>
            <Form>
              <div className="changeProfile">
                <Row gutter={16}>
                  <Col xs={{ span: 24 }}>
                    <Form.Item>
                      {/* <span className="labelName">Name</span>
                      <Input /> */}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item>
                      <span className="labelName">Name</span>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item>
                      <span className="labelName">Email</span>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item>
                      <span className="labelName">Country</span>
                      <CountrySelect />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item>
                      <span className="labelName">City</span>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </div>
      </section>
      {/* <!-- rate section --> */}
      <section className="rateSection">
        <h2>Rate Us</h2>
        <p>Please share yor experience how did you feel using our platform </p>
        <Rate defaultValue={2.5} />
        <div className="rateHeading">
          <h6>
            “ It was a great experience. Got all I need, Would definitely
            recommend to my friends as well . ”
          </h6>
        </div>
        <CustomBtn title="Save changes" />
      </section>

      {/* <!-- subscription section --> */}
    </Wrapper>
  );
};

export default ProfileChange;
