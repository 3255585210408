import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg, successMsg } from "../components/AlertMessage";
import { ApiRequests } from "../services/ApiRequest";

export const getAllLiveClasses = createAsyncThunk(
  "liveClass/getAllLiveClasses",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAllLiveClasses();
      return response?.data?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const listLiveClasses = createAsyncThunk(
  "liveClass/listLiveClasses",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.listLiveClasses();
      return response?.data?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const FetchliveClassData = createAsyncThunk(
  "liveClass/FetchliveClassData",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.listLiveClasses();
      return response?.data?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getSingleLiveClasses = createAsyncThunk(
  "liveClass/getSingleLiveClasses",
  async (classId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getSingleLiveClasses(classId);
      return response?.data?.data;
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "An error occurred";
      return rejectWithValue(errorMessage);
    }
  }
);

export const addLiveClasses = createAsyncThunk(
  "liveClass/addLiveClasses",
  async ({ formDataObject, onReset }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.addLiveClasses(formDataObject);
      if (response.status === 201) {
        successMsg("New LiveClasses Added!");
        onReset();
        dispatch(getAllLiveClasses());
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg(
        "Please check your form, try to change the short name and submit again."
      );
      throw error;
    }
  }
);

export const updateStudentData = createAsyncThunk(
  "liveClass/updateStudentData",
  async ({ updatedData, classId, onReset }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.updateStudentData(updatedData);
      successMsg("New LiveClasses Added!");
      dispatch(getSingleLiveClasses(classId));
      onReset();
      return response?.data;
    } catch (error) {
      errorMsg(
        "Please check your form, try to change the short name and submit again."
      );
      throw error;
    }
  }
);

export const updateLessonCompletion = createAsyncThunk(
  "liveClass/updateLessonCompletion",
  async ({ updatedData, classId, onReset }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.updateLessonCompletion(updatedData);
      successMsg("New LiveClasses Added!");
      dispatch(getSingleLiveClasses(classId));
      onReset();
      return response?.data;
    } catch (error) {
      errorMsg(
        "Please check your form, try to change the short name and submit again."
      );
      throw error;
    }
  }
);

export const removeLiveClass = createAsyncThunk(
  "liveClass/removeLiveClass",
  async ({ Id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.removeLiveClass(Id);
      if (response.status === 200) {
        successMsg("Live Class Deleted!");
        dispatch(getAllLiveClasses());
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("An error occurred while deleting the Live Class.");
      throw error;
    }
  }
);

export const removeLecturerStudentPair = createAsyncThunk(
  "liveClass/removeLiveClass",
  async (
    { liveClassId, studentId, lecturerId },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.removeLecturerStudentPair(
        liveClassId,
        studentId,
        lecturerId
      );
      if (response.status === 200) {
        successMsg("Pair successfully removed!");
        dispatch(getSingleLiveClasses(liveClassId));
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("An error occurred while deleting the Live Class.");
      throw error;
    }
  }
);

export const joinLiveClass = createAsyncThunk(
  "liveClass/joinLiveClass",
  async ({ dataObject, onReset }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.joinLiveClass(dataObject);
      if (response?.data?.code === 201) {
        successMsg(response?.data?.status);
        dispatch(FetchliveClassData());
        return response?.data;
      }
      onReset();
    } catch (error) {
      const errorCode = error?.response?.data?.code;
      const errorMessage = error?.response?.data?.status;

      if (errorCode === 400) {
        successMsg(errorMessage);
      } else {
        errorMsg(errorMessage);
      }
      return error?.response?.data;
    } finally {
      onReset();
    }
  }
);

export const lecturerStudentsPair = createAsyncThunk(
  "liveClass/lecturerStudentsPair",
  async (
    { classId, formDataObject, onReset },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.lecturerStudentsPair(formDataObject);
      if (response.status === 200) {
        successMsg("Pair updated successfully!");
        dispatch(getSingleLiveClasses(classId));
        onReset();
        return response.data.data;
      } else {
        errorMsg("Try Again!");
        return rejectWithValue("Failed to update lecturer-students pair");
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(
        "An error occurred while updating lecturer-students pair"
      );
    }
  }
);

const initialState = {
  loading: false,
  joinLoading: false,
  allLiveClasses: {},
  listLiveClass: [],
  singleLiveClasses: [],
  enrollingLiveClass: null,
};

const liveClassSlice = createSlice({
  name: "liveClass",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(joinLiveClass.pending, (state) => {
        state.joinLoading = true;
      })
      .addCase(joinLiveClass.fulfilled, (state, action) => {
        state.joinLoading = false;
        state.enrollingLiveClass = action.payload;
      })
      .addCase(joinLiveClass.rejected, (state) => {
        state.joinLoading = false;
      })
      .addCase(lecturerStudentsPair.pending, (state) => {
        state.loading = true;
      })
      .addCase(lecturerStudentsPair.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(lecturerStudentsPair.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllLiveClasses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLiveClasses.fulfilled, (state, action) => {
        state.loading = false;
        state.allLiveClasses = action.payload;
      })
      .addCase(getAllLiveClasses.rejected, (state) => {
        state.loading = false;
      })
      .addCase(listLiveClasses.pending, (state) => {
        state.loading = true;
      })
      .addCase(listLiveClasses.fulfilled, (state, action) => {
        state.loading = false;
        state.listLiveClass = action.payload;
      })
      .addCase(listLiveClasses.rejected, (state) => {
        state.loading = false;
      })
      .addCase(FetchliveClassData.pending, (state) => {
        state.joinLoading = true;
      })
      .addCase(FetchliveClassData.fulfilled, (state, action) => {
        state.joinLoading = false;
        state.listLiveClass = action.payload;
      })
      .addCase(FetchliveClassData.rejected, (state) => {
        state.joinLoading = false;
      })
      .addCase(getSingleLiveClasses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleLiveClasses.fulfilled, (state, action) => {
        state.loading = false;
        state.singleLiveClasses = action.payload;
      })
      .addCase(getSingleLiveClasses.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addLiveClasses.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLiveClasses.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addLiveClasses.rejected, (state) => {
        state.loading = false;
      })
      .addCase(removeLiveClass.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeLiveClass.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removeLiveClass.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default liveClassSlice.reducer;
