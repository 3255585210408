import React from "react";
import { Button, Result } from "antd";
import { Helmet } from "react-helmet";

const cancel = () => (
  <>
    <Helmet>
      <title>Tansy Academy | Cancel </title>
    </Helmet>
    <Result
      status="warning"
      title="There are some problems with your operation."
      extra={
        <Button type="primary" key="console">
          Go Console
        </Button>
      }
    />
  </>
);
export default cancel;
