import React, { useEffect, useMemo, useState } from "react";
import { CardContainer, Wrapper } from "./index.style";
import { BsCheck } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { HandlePayment, MakePayment } from "../../../stripe";
import { useLocation, useNavigate } from "react-router-dom";
// import ReactGA from "react-ga4";
import { AnalyticsEvents, pushPurchaseEvent } from "../../../App";

const ListCard = ({ plan, planPeriod }) => {
  const [loading, setLoading] = useState(false);
  const [isToken, setToken] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [geolocation, setGeolocation] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const authUser = JSON?.parse(
      localStorage.getItem("authUser") &&
        localStorage.getItem("authUser") != "undefined"
        ? localStorage.getItem("authUser")
        : null
    );
    const token = authUser && authUser?.token;
    setToken(!!token && true);
  }, []);

  const product = useMemo(
    () => ({
      name: plan.title,
      payment: plan.payment,
      quantity: 1,
      price_id: process.env[plan.price_id],
      currentURL: new URL(window.location.href).origin,
    }),
    [plan]
  );
  // eslint-disable-next-line no-unused-vars
  const getGeolocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGeolocation(
            `${position.coords.latitude},${position.coords.longitude}`
          );
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation not supported");
    }
  };
  const indianProducts = useMemo(
    () => ({
      name: plan.title,
      payment: plan.payment,
      planId: plan.planId,
      quantity: 1,
      amount: plan.amount,
      currency: plan.currency,
      currentURL: new URL(window.location.href).origin,
    }),
    [plan]
  );

  const handleChoose = ({
    category,
    action,
    label,
    transactionId,
    value,
    currency,
    items,
  }) => {
    if (isToken) {
      AnalyticsEvents({ category, action, label, value });
      pushPurchaseEvent(transactionId, value, currency, items);
      if (planPeriod === "internationalPlans") {
        MakePayment(dispatch, product, setLoading);
      } else if (planPeriod === "indiaPlans") {
        HandlePayment(dispatch, indianProducts, setLoading);
      }
    } else {
      navigate(`/login/?returnURL=${location.pathname + location.search}`);
    }
  };

  return (
    <Wrapper>
      <CardContainer className={plan.title === "Premium" ? "" : ""}>
        <h1>{plan.title}</h1>
        {plan.features?.map(({ id, included, feature }) => (
          <p
            key={id}
            style={{
              color: `${included ? "#181059" : "rgba(24, 16, 89, 0.4)"}`,
            }}
          >
            <span>
              {included ? (
                <BsCheck />
              ) : (
                <IoIosClose
                  style={{
                    color: "rgba(24, 16, 89, 0.4)",
                  }}
                />
              )}
            </span>
            <div
              className="featureTitle"
              dangerouslySetInnerHTML={{ __html: feature }}
            />
          </p>
        ))}
        <div className="amount">
          {plan.title === "Self Paced" ? (
            <strong>
              {plan.price}
              {planPeriod === "indiaPlans" && (
                <span style={{ fontWeight: 400, fontSize: "14px" }}>
                  {plan.GST}
                </span>
              )}
              <span>/per month </span>
            </strong>
          ) : (
            <strong>
              {plan.price}
              <span style={{ fontWeight: 400, fontSize: "14px" }}>
                {plan.GST}{" "}
              </span>
              {/* <span>&nbsp;/{planPeriod}</span> */}
            </strong>
          )}
        </div>

        {plan.title === "Free" ? (
          <div>
            <div id="disable choose">Choose</div>
          </div>
        ) : (
          <div id="purchase">
            <div id={plan.id}>
              <div
                id="choose-plan"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleChoose({
                    transactionId: plan.id,
                    currency: plan.currency,
                    items: [
                      {
                        item_id: `SKU_${Date.now()}`,
                        item_name: plan.title,
                        price: Number(plan.amount),
                        quantity: 1,
                      },
                    ],
                    label: plan.title,
                    value: Number(plan.amount),
                    category: `${plan.title} ~ ${plan.price}`,
                    action: `Click: ${plan.title} ${plan.price}`,
                  })
                }
              >
                <div className="choose"> {loading ? <Spin /> : "Choose"}</div>
              </div>
            </div>
          </div>
        )}
      </CardContainer>
    </Wrapper>
  );
};

export default ListCard;
