import React from "react";
// import { BsCheck } from "react-icons/bs";
// import { IoIosClose } from "react-icons/io";
import { CustomTable } from "./index.style";
import CustomImage from "../../../common/images";

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Free",
    dataIndex: "free",
    key: "free",
  },
  {
    title: "Quiz Pack",
    dataIndex: "quizPack",
    key: "quizPack",
  },
  {
    title: "Self-Paced",
    dataIndex: "selfPaced",
    key: "selfPaced",
  },
  {
    title: "SQL Zoom",
    dataIndex: "sqlZoom",
    key: "sqlZoom",
  },
  {
    title: "SQL+PowerBI Zoom",
    dataIndex: "sqlPowerBIZoom",
    key: "sqlPowerBIZoom",
  },
  {
    title: "Premium",
    dataIndex: "premium",
    key: "premium",
  },
];

const PlanDataTable = ({ comparison }) => {
  const rows = [];
  comparison?.map((compare) => {
    rows.push({
      key: compare.title,
      name: (
        <p>
          {compare.title}{" "}
          <CustomImage
            src=""
            alt="i"
            width="100%"
            height="100%"
                                 
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        </p>
      ),
      free: compare.free ? (
        <CustomImage
          src="/icons/check-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ) : (
        <CustomImage
          src="/icons/close-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ),
      selfPaced: compare.selfPaced ? (
        <CustomImage
          src="/icons/check-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ) : (
        <CustomImage
          src="/icons/close-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ),
      sqlZoom: compare.sqlZoom ? (
        <CustomImage
          src="/icons/check-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ) : (
        <CustomImage
          src="/icons/close-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ),
      sqlPowerBIZoom: compare.sqlPowerBIZoom ? (
        <CustomImage
          src="/icons/check-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ) : (
        <CustomImage
          src="/icons/close-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ),
      premium: compare.premium ? (
        <CustomImage
          src="/icons/check-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ) : (
        <CustomImage
          src="/icons/close-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ),
      quizPack: compare.quizPack ? (
        <CustomImage
          src="/icons/check-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ) : (
        <CustomImage
          src="/icons/close-icon.svg"
          alt="i"
          width="24px"
          height="24px"
                               
          onError={(e) => {
            e.target.style.display = "none";
          }}
        />
      ),
    });
  });
  // console.log(rows);

  return (
    <div>
      <CustomTable
        dataSource={rows}
        columns={columns}
        pagination={{ pageSize: rows.length }}
      />
    </div>
  );
};

export default PlanDataTable;
