import React, { useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import {
  Heading,
  LinkCard,
  PaymentProcessing,
  ShowCoursesCard,
  Wrapper,
} from "./index.style";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import CountUp from "react-countup";
import { useSelector, useDispatch } from "react-redux";
import { getAdminDashboard } from "../../redux/adminSlice";
import { Link, useNavigate } from "react-router-dom";
import { getAdminCourses } from "../../redux/courseSlice";
import { Helmet } from "react-helmet";
import CustomImage from "../../common/images";
import { EditOutlined } from "@ant-design/icons";

const AdminDashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => state);
  const { userProfile } = store?.auth;

  useEffect(() => {
    dispatch(getAdminDashboard());
    dispatch(getAdminCourses());
  }, [dispatch]);

  // const { adminDashboard } = store?.admin;
  const { adminCourses } = store?.course;
  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | DashBoard </title>
        <meta
          property="og:image"
          content={"https://tansyacademy.com/images/logo.svg"}
        />
      </Helmet>
      <Container fluid>
        <Heading>
          <p>Welcome!</p>
          <h3>{userProfile?.name}.</h3>
        </Heading>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 16 }}>
            <Row gutter={16}>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <ShowCoursesCard className="firstCard">
                  <div className="cardHeader">
                    <CustomImage
                      src="/icons/books-icon.svg"
                      alt="i"
                      width="32px"
                      height="32px"
                    />
                    <span style={{ color: "#d39331" }}>
                      <AiOutlinePlus
                        onClick={() => navigate("/admin/courses/add-course")}
                      />
                    </span>
                  </div>
                  <div className="topics">
                    <CountUp start={0} end={adminCourses?.length} delay={0}>
                      {({ countUpRef }) => <h1 ref={countUpRef} />}
                    </CountUp>
                    <p>Courses</p>
                  </div>
                  <Link to="/admin/courses">See all Courses</Link>
                </ShowCoursesCard>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <ShowCoursesCard style={{ backgroundColor: "#9A3BAA" }}>
                  <div className="cardHeader">
                    <CustomImage
                      src="/icons/book.svg"
                      alt="i"
                      width="32px"
                      height="32px"
                    />
                    <span>
                      <AiOutlinePlus
                        onClick={() => navigate("/admin/chapters")}
                      />
                    </span>
                  </div>
                  <div className="topics">
                    <p>Chapters</p>
                  </div>
                  <Link to="/admin/chapters">Go to Chapters</Link>
                </ShowCoursesCard>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <ShowCoursesCard style={{ backgroundColor: "#25A4AD" }}>
                  <div className="cardHeader">
                    <CustomImage
                      src="/icons/topic.svg"
                      alt="i"
                      width="32px"
                      height="32px"
                    />
                    <span>
                      <AiOutlinePlus onClick={() => navigate("/admin/topic")} />
                    </span>
                  </div>
                  <div className="topics">
                    <div className="cardHeader">
                      <p>Topics</p>
                      <EditOutlined
                        style={{
                          width: "25px",
                          height: "25px",
                          marginRight: "4px",
                        }}
                        onClick={() => navigate("/admin/topic/update")}
                      />
                    </div>
                  </div>
                  <Link to="/admin/topic">Manage topics</Link>
                </ShowCoursesCard>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 6 }}>
                <ShowCoursesCard style={{ backgroundColor: "#3F6FD8" }}>
                  <div className="cardHeader">
                    <CustomImage
                      width="28px"
                      height="28px"
                      src="/icons/liveclassIcon.svg"
                      alt="i"
                      onError={(e) => {
                        e.target.style.display = "none";
                      }}
                    />
                    <span>
                      <AiOutlinePlus
                        onClick={() => navigate("/admin/liveclass")}
                      />
                    </span>
                  </div>
                  <div className="topics">
                    <p>Live Class</p>
                  </div>
                  <Link to="/admin/liveclass">Live Class</Link>
                </ShowCoursesCard>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Link to="/admin/payment-processing">
                  <PaymentProcessing>
                    <CustomImage
                      src="/icons/bank.svg"
                      alt="i"
                      width="28"
                      height="28"
                      onError={(e) => {
                        e.target.style.display = "none";
                      }}
                    />
                    <p>Payment Processing</p>
                  </PaymentProcessing>
                </Link>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Link to="/admin/payment-history">
                  <PaymentProcessing style={{ backgroundColor: "#e04f5f" }}>
                    <CustomImage
                      src="/icons/atm.svg"
                      alt="i"
                      width="28"
                      height="28"
                      onError={(e) => {
                        e.target.style.display = "none";
                      }}
                    />
                    <p>Payment History</p>
                  </PaymentProcessing>
                </Link>
              </Col>
              <Col span={24}>
                <PaymentProcessing style={{ backgroundColor: "#edbb01" }}>
                  <CustomImage
                    src="/icons/msg.svg"
                    alt="i"
                    width="28"
                    height="28"
                    onError={(e) => {
                      e.target.style.display = "none";
                    }}
                  />
                  <div className="commentsLinks">
                    <Link to="/admin/review">
                      Review Comments <span>16</span>
                    </Link>
                    <Link to="/admin/review">Review Threads</Link>
                    <Link to="/admin/review">Review Testimonials</Link>
                  </div>
                </PaymentProcessing>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <LinkCard style={{ backgroundColor: "#3F6FD8" }}>
                  <div className="commentsLinks">
                    <Link to="/admin/quiz/questions">Quiz</Link>
                  </div>
                </LinkCard>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <LinkCard style={{ backgroundColor: "#25A4AD" }}>
                  <div className="commentsLinks">
                    <Link to="/admin/assignment/tasks">Assignment</Link>
                  </div>
                </LinkCard>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <LinkCard style={{ backgroundColor: "#9A3BAA" }}>
                  <div className="commentsLinks">
                    <Link to="/admin/interview/questions">
                      Interview Questions
                    </Link>
                  </div>
                </LinkCard>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <LinkCard style={{ backgroundColor: "#25ad84" }}>
                  <div className="commentsLinks">
                    <Link to="/admin/contact_us">Contact US</Link>
                  </div>
                </LinkCard>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <LinkCard
                  style={{
                    background: "linear-gradient(45deg, #4a90e2, #50e3c2)",
                  }}
                >
                  <div className="commentsLinks">
                    <Link to="/admin/user-session-details">
                      User Session Details
                    </Link>
                  </div>
                </LinkCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default AdminDashBoard;
