import { Button, Timeline } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "Source Sans Pro";
  margin: auto;
  padding-bottom: 3em;

  #mutli-level {
    scroll-behavior: smooth;
  }
  .level {
    padding: 2em 0;
    width: 90%;
    margin: auto;
  }
  .include {
    width: 90%;
    margin: auto;
    .requirements {
      font-size: 32px;
      font-weight: 700;
      text-align: left;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
  .key-feature {
    padding: 2em 0;
    .title {
      font-size: 36px;
      font-weight: 700;
      line-height: 42px;
    }
    .wrapper {
      display: flex;
      justify-content: center;
    }
    .content-wrapper {
      padding: 1rem;
      width: 480px;
      gap: 20px;
    }
    .list {
      padding-left: 1.5em;
      margin-top: 1em;
    }
    .list-item {
      font-size: 16px;
      font-weight: 400;
      line-height: 36px;
      color: #454545;
      a {
        color: linear-gradient(91deg, #2f5bbc -11%, #5c92ed 45%, #2f5bbc 108%);
        font-weight: 600;
        text-decoration: underline;
      }
    }
    .image-wrapper {
      display: flex;
      align-items: start;
      justify-content: center;
    }
    .image {
      width: 416px;
      height: 400px;
      @media (max-width: 768px) {
        width: 320px;
        height: 250px;
      }
    }
  }
  .access {
    margin: 2em 0;
    align-items: center;
    background-color: #1f2e50;
    height: 152px;
    gap: 36px;
    display: flex;
    padding: 0 20px;
    justify-content: space-around;
  }
  .access p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
    width: 200px;
    display: flex;
    align-items: start;
  }
  .access img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    vertical-align: middle;
  }
  @media (max-width: 768px) {
    .access {
      gap: 0;
      height: auto;
      display: block;
      flex-direction: column;
      padding: 10px 0;
    }
    .access p {
      text-align: left;
      width: auto;
      padding: 10px 10px 10px 18px;
    }
    .access img {
      margin-right: 10px;
      vertical-align: middle;
    }
  }
`;

export const Intro = styled.div`
  background-image: url("/images/intro-banner.png");
  background-color: white;
  min-height: 500px;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 98% auto;
  position: relative;
  margin-bottom: 2em;
  border-radius: 24px 0px 0px 0px;
  border: 1px 0px 0px 0px;

  @media (max-width: 768px) {
    background-size: 98% 500px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    background-size: 98% 500px;
  }

  .introContainer {
    align-items: center;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      padding: 5px;
      margin: 5px;
    }
    h1 {
      padding: 5px;
      margin: 5px;
    }
    .heading {
      text-align: center;
      width: 80%;
      font-size: 56px;
      font-weight: 400;
      line-height: 64px;
      letter-spacing: -2.8px;
      color: #ffffff;
    }

    .subHeading {
      text-align: center;
      font-size: 56px;
      font-weight: 600;
      line-height: 64px;
      letter-spacing: -2.8px;
      background-image: linear-gradient(
        90deg,
        #c982ff 0%,
        #ff9471 50%,
        #a5a4ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .paragraph {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color: #ffffff;
      text-align: center;
      width: 70%;
    }

    .button {
      margin: 2em;
      width: 220px;
      height: 52px;
      padding: 14px;
      background: #ffffff;
      color: #181059;
      border: 1px solid #ffffff;
      box-shadow: 0px 1.85px 3.15px 0px #31313106,
        0px 8.15px 6.52px 0px #3131310a, 0px 20px 13px 0px #3131310d,
        0px 38.52px 25.48px 0px #3131310f, 0px 64.81px 46.85px 0px #31313113,
        0px 100px 80px 0px #3131311a;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      border-top: 1px solid #ffffff;
      transition: background-color 0.3s ease, color 0.3s ease,
        transform 0.3s ease;
    }

    .button:hover {
      background-color: #f2f2f2;
      color: #000000;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
        0px 8px 16px rgba(0, 0, 0, 0.1);
    }
    @media (max-width: 768px) {
      .button {
        width: 190px;
        height: 44px;
        padding: 8px 14px;
        font-size: 13px;
      }
      .heading {
        width: 80%;
        font-size: 32px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: -2.8px;
        color: #ffffff;
      }

      .subHeading {
        width: 80%;
        font-size: 32px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -2.8px;
        background-image: linear-gradient(
          90deg,
          #c982ff 0%,
          #ff9471 50%,
          #a5a4ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .paragraph {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: #ffffff;
        text-align: center;
        width: 80%;
      }
    }
  }
`;

export const Banner = styled.div`
  background-color: #181059;
  width: 100%;
  padding: 1em;
  height: 529px;
  color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .sqlimg {
    padding: 32px;
    max-width: 430px;
    height: 330px;
  }
  .headingContainer {
    width: 593px;
    padding: 32px;
    text-align: left;

    .title {
      font-size: 52px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -1px;
      margin-bottom: 16px;
    }

    .subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin: 20px 0;
    }
    .buttonContainer {
      margin-top: 32px;
    }
    .iconContainer {
      display: flex;
      gap: 17px;
      margin: 20px 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    .sqlimg {
      max-width: 360px;
    }
    .headingContainer {
      width: 100%;
      padding: 20px;
      text-align: center;
      margin-bottom: 16px;

      .title {
        font-size: 36px;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: -0.73;
        text-align: center;
      }

      .subtitle {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        text-align: center;
      }

      .iconContainer {
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        img {
          width: 24;
          height: 24;
        }
      }
      .buttonContainer {
        margin-top: 24px;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    box-shadow: 0px 1.85px 3.15px 0px #31313106, 0px 8.15px 6.52px 0px #3131310a,
      0px 20px 13px 0px #3131310d, 0px 38.52px 25.48px 0px #3131310f,
      0px 64.81px 46.85px 0px #31313113, 0px 100px 80px 0px #3131311a;
    border: 1px solid #ffffff;
    width: 311px;
    height: 52px;
    padding: 14px 36px;
    gap: 5px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: #ffffff;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  }

  &:hover {
    background-color: transparent;
    border: 1px solid #000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
  }
`;

export const PanelContainer = styled.div`
  font-family: "Source Sans Pro";
  width: 90%;
  margin: auto;
  padding: 3em 0;
  .ant-collapse {
    border: 0px solid #d9d9d9;
    .customPanel {
      margin-bottom: 24px;
      overflow: hidden;
      padding: 1px 3px 1px 3px;
      gap: 0px;
      border: 1px;
      opacity: 0px;
      background: #ffffff;
      border: 1px solid #3131311a;
    }
    .ant-collapse-header {
      border: 1px solid #3131311a;
      border: 0px;

      .panelHeader {
        color: #313131;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        width: 90%;

        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
    .ant-collapse-content {
      color: rgba(0, 0, 0, 0.85);
      background-color: #fff;
      border-top: 0px;

      .ant-collapse-content-box {
        padding: 4px 16px 16px 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: #454545;
        width: 90%;
        a {
          color: linear-gradient(
            91.53deg,
            #2f5bbc -11.9%,
            #5c92ed 45.19%,
            #2f5bbc 108.85%
          );
          font-weight: 600;
          text-decoration: underline;
        }
        ul {
          list-style-type: disc;
          margin-left: 0px;
          padding-left: 1.5rem;
        }
        li {
          margin-bottom: 8px;
        }
      }
    }
  }
`;

export const CustomTimeline = styled(Timeline)`
  padding: 2em 0;
  width: 90%;
  margin: auto;
  .ant-timeline-item-content {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #454545;
    width: 90%;
    margin: auto;
  }
  .ant-collapse {
    border: 0px solid #d9d9d9;
    .customPanel {
      margin-bottom: 24px;
      overflow: hidden;
      padding: 1px 3px 1px 3px;
      gap: 0px;
      border: 1px;
      opacity: 0px;
      background: #ffffff;
      border: 1px solid #3131311a;
    }
    .ant-collapse-header {
      border: 1px solid #3131311a;
      border: 0px;
      .linkStyle {
        color: rgba(63, 111, 216, 1);
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        text-align: left;
      }
    }
    .ant-collapse-content {
      color: rgba(0, 0, 0, 0.85);
      background-color: #fff;
      border-top: 0px;
      .titleStyle {
        font-size: 18px;
        padding-bottom: 5px;
        font-weight: 700;
        line-height: 26px;
        text-align: left;
        color: rgba(49, 49, 49, 1);
      }
      .contentStyle {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        color: rgba(69, 69, 69, 1);
      }
    }
  }
  .ant-collapse-content-box {
    a {
      color: linear-gradient(
        91.53deg,
        #2f5bbc -11.9%,
        #5c92ed 45.19%,
        #2f5bbc 108.85%
      );
      font-weight: 600;
      text-decoration: underline;
    }
  }
  .ant-timeline-item .ant-timeline-item-tail {
    position: absolute;
    top: 10px;
    left: 4px;
    height: calc(100% - 10px);
    border-left: 2px solid rgba(24, 144, 255, 0.3) !important;
  }
  .ant-timeline-item-head {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 10px;
    background-color: #1890ff;
    border: 2px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 100px;
  }
  .ant-timeline-item-head-blue {
    border-color: #1890ff;
  }
`;

export const CustomSyllabus = styled.div`
  width: 81%;
  margin: auto;
  background-color: rgba(31, 46, 80, 1);
  a {
    font-weight: 600;
    text-decoration: underline;
  }

  ul {
    list-style-type: disc;
    margin-left: 0px;
    padding-left: 1.5rem;
  }
  .chapters {
    width: auto;
    height: 120px;
    padding: 25px;
    border: 1px solid rgba(66, 133, 244, 1);
    background: rgba(66, 133, 244, 1);
    .title {
      font-size: 36px;
      font-weight: 700;
      line-height: 42px;
      text-align: left;
      padding-bottom: 10px;
      color: rgba(255, 255, 255, 1);
    }
    .description {
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      align-items: start;
    }
    .item:not(:last-child) {
      margin-bottom: 10px;
    }
    .img-size {
      width: 28px;
      height: 28px;
      margin: 8px 8px 8px 0;
    }
    .lesson {
      margin: 8px;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      min-width: 90px;
    }
  }
  .ant-collapse-borderless {
    background-color: rgba(31, 46, 80, 1);
    border: 0;
    color: rgba(255, 255, 255, 1);
    font-color: rgba(255, 255, 255, 1);
  }
  .ant-collapse-expand-icon {
    color: rgba(255, 255, 255, 1);
  }
  .ant-collapse-item {
    padding: 20px 0;
  }
  .ant-collapse-header-text {
    .heading {
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
      margin: 5px 0 10px 15px;
      color: rgba(255, 255, 255, 1);
    }
    .main {
      margin: 0 0 10px 15px;
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .ant-collapse-content-box p {
    color: rgba(255, 255, 255, 1);
  }
`;

export const CustomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d39331 !important;
  border: 1px solid #d39331 !important;
  display: flex;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  justify-content: center;
  align-items: center;
  width: 193px;
  height: 58px;
  border-radius: 0;
  cursor: pointer;
  .loader {
    font-size: 2rem !important;
  }

  &:disabled {
    background: #d39331;
    border: 1px solid #d39331;
    width: 180px;
    height: 60px;
    border-radius: 0;
    opacity: 0.5;

    &:hover {
      background: #d39331;
      border: 1px solid #d39331;
      width: 180px;
      height: 60px;
      border-radius: 0;
      opacity: 0.5;
    }
  }

  span {
    font-size: 1.3em;
    color: #ffffff;
  }

  &:focus,
  &:hover {
    background: #d39331;
    border-radius: 0;
    border: 1px solid #d39331;
    opacity: 0.9;
    span {
      color: #ffffff;
    }
  }

  @media (max-width: 992px) {
    & {
      width: 165px;
      height: 52px;
    }
  }
`;

export const CustomStyle = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 3em 0 1em 0;
`;

export const JobRoles = styled.div`
  padding-top: 1em;

  .job-roles-container {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    text-align: center;
  }

  .job-roles-wrapper {
    margin: 1em;

    .job-role {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .role {
        text-align: center;
        width: 240px;
        margin: 2em;

        .role-image {
          width: 60px;
          height: 60px;
          margin: 2em 0;
        }

        .role-title {
          font-size: 18.44px;
          margin-bottom: 10px;
          font-weight: 700;
          line-height: 26px;
          text-align: center;
          color: rgba(49, 49, 49, 1);
        }

        .role-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color: rgba(69, 69, 69, 1);
          text-align: center;
        }
      }
    }
  }
`;

export const Certifications = styled.div`
  margin: 1em 0;
  .certifications-container {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    text-align: center;
  }

  .certifications-wrapper {
    margin: 1em;

    .certification {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .certification-item {
        text-align: left;
        width: 310px;
        margin: 1.5em;
        padding: 0.5em;
        border-bottom: 3px solid #d39331;

        .certification-image {
          width: 60px;
          height: 60px;
          margin: 2em 0;
        }

        .certification-title {
          font-size: 18.44px;
          margin-bottom: 10px;
          font-weight: 700;
          line-height: 26px;
          text-align: left;
          color: rgba(49, 49, 49, 1);
        }

        .certification-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color: rgba(69, 69, 69, 1);
          text-align: left;
        }
      }
    }
  }
`;

export const GetInTouch = styled.div`
  background-image: url("/images/get-in-touch-lg.svg");
  background-color: white;
  width: 81%;
  min-height: 280px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 2em;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    .info-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading {
        margin-bottom: 30px;
        font-size: 48px;
        font-weight: 700;
        line-height: 42px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        @media (max-width: 992px) {
          font-size: 36px;
        }
      }

      .info-wrap {
        margin-top: 8px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @media (max-width: 992px) {
          align-items: start;
          justify-content: center;
        }

        .info {
          margin: 5px 15px;
          font-family: "Source Sans Pro";
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          color: rgba(255, 255, 255, 1);
          text-align: center;
          display: flex;
          align-items: center;

          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
`;

export const CustomCarousel = styled.div`
  margin-bottom: 20px;

  .carousel-heading {
    color: #0056b3;
    text-align: center;
    margin: 2em 0;
    font-size: 36px;
    position: relative;
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }

  .carousel-heading img {
    width: 46px;
    height: 46px;
    position: absolute;
    top: -20px;
    z-index: 1;
    @media (max-width: 768px) {
      width: 42px;
      height: 42px;
    }
  }

  .carousel-container {
    border: none;
  }

  .carousel-item {
    margin: 0 10px;
  }

  .carousel-item .ant-card {
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }

  .carousel-item.highlighted .ant-card {
    // border: 2px solid rgba(63, 111, 216, 1);
  }

  .carousel-item .ant-card-meta-title {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: rgba(63, 111, 216, 1);
    line-height: 17px;
  }

  .carousel-item .ant-card-meta-description {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: rgba(49, 49, 49, 1);
    line-height: 38px;
  }

  .occupation {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: rgba(49, 49, 49, 0.5);
    line-height: 17px;
    margin-top: 2em;
  }

  .name {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: rgba(49, 49, 49, 1);
    line-height: 23px;
    margin: 5px 0;
  }

  .carousel-controls {
    display: flex;
    justify-content: center;
  }

  .carousel-controls .carousel-control {
    border: none;
    margin: 10px;
    width: 44px;
    height: 44px;
  }

  .carousel-controls .carousel-control .ant-btn-icon-only {
    color: rgba(63, 111, 216, 1);
  }
`;

export const CustomOrganizer = styled.div`
  position: relative;
  background: rgba(247, 243, 235, 1);
  width: 100%;
  padding: 20px;
  margin: 2em 0;
  height: auto;

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .img {
    margin-bottom: -40px;
    max-width: 520px;
    @media screen and (max-width: 768px) {
      .imgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
      width: 100%;
      margin-bottom: -35px;
      max-width: 350px;
    }

    @media screen and (min-width: 576px) {
      .content {
        flex: 0 0 calc(50% - 40px);
        max-width: calc(50% - 40px);
      }
    }
  }

  .content {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .heading p {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    color: rgba(151, 77, 255, 1);
    margin: 20px 0;
  }

  .heading p:nth-child(2) {
    font-size: 26px;
    color: rgba(49, 49, 49, 1);
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: rgba(69, 69, 69, 1);
    margin: 0;
  }

  @media screen and (min-width: 576px) {
    .content {
      flex: 0 0 calc(50% - 40px);
      max-width: calc(50% - 40px);
    }
  }

  @media screen and (min-width: 992px) {
    .content {
      flex: 0 0 calc(50% - 40px);
      max-width: calc(50% - 40px);
    }
  }
`;
