import styled from "styled-components";

export const Wrapper = styled("div")`
  padding: 2em 4em;
  .firstCard {
    border: 1px solid #00000010;
    .cardHeader {
      span {
        color: #d39331;
      }
    }
    p {
      color: #313131;
    }
    a {
      border: 1px solid #00000010;
      color: #d39331;
    }
  }
  @media (max-width: 768px) {
    padding: 1em 0em;
  }
`;
export const Heading = styled("div")`
  margin-bottom: 2em;
  p {
    font-weight: 600;
  }
  h3 {
  }
`;

export const ShowCoursesCard = styled.div`
  border: 1px solid #00000010;
  padding: 2em 1em;
  margin-bottom: 1em;

  .cardHeader {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;

    .imgStyle {
      width: 28px;
      height: 28px;
    }

    span {
      color: #ffffff;

      svg {
        font-size: 2em;
        cursor: pointer;
      }
    }
  }

  p {
    color: #fff;
  }

  .topics {
    height: 100%;

    .pending-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 23px;
      padding: 10px 0;
      letter-spacing: 0em;
      text-align: left;
    }

    .review-count {
      font-size: 42px;
      font-weight: 700;
      line-height: 53px;
      letter-spacing: 0em;
      text-align: left;
    }

    .review-details {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 1px;
      gap: 10px;
      padding: 10px 15px;
      margin-bottom: 1em;
      margin-top: 1em;
    }

    .assignment-name {
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
      padding: 3px 0;
    }

    .submission-time {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      padding: 3px 0;
    }

    .review-link {
      height: 23px;
      background: rgba(63, 111, 216, 1);
    }

    .topics {
      height: 100px;
    }

    .review {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 0.5em;
    }

    a {
      height: 38px;
      display: flex;
      width: 120px;
      font-size: 14px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      justify-content: center;
      align-items: center;
      background: transparent;
      margin: 0.5em 0 0.5em;
      border: 1px solid #ffffff90;
      color: #ffffff;
    }

    @media (max-width: 768px) {
      max-width: 250px;
      margin: 1em auto;
    }
  }
`;
