import styled from "styled-components";

export const Wrapper = styled("div")`
  background-color: #ffffff;
`;
export const Container = styled("div")`
  background-color: #ffffff;
  // @media (max-width: 992px) {
  //   & {
  //     text-align: center;
  //     padding: 1em;
  //   }
  // }
`;
