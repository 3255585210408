import { Table } from "antd";
import styled from "styled-components";

export const TableContainer = styled(Table)`
  width: 100%;
  .ant-table-thead {
    .ant-table-cell {
      background: #ffffff;
      background-color: #181059;
      color: #ffffff;
      &::before {
        display: none;
      }
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      background: #ffffff;

      p {
        color: #181059;
      }
      &:first-child {
        text-align: left;
      }
      .ant-table-cell-row-hover {
        background: #ffffff !important;
      }
    }
    tr {
      &:hover {
        background: #ffffff !important;
      }
      &:nth-child(even) {
        .ant-table-cell {
          background: #f3f3f3 !important;
        }
      }
    }
  }
  .ant-table-filter-trigger:hover {
    color: #ffffff;
    background: rgba(0, 0, 0, 0.04);
  }
  .ant-table.ant-table-bordered > .ant-table-title {
    border: 0px solid #f0f0f0;
    border-bottom: 0;
  }
  @media (max-width: 992px) {
    width: 700px;
    overflow-x: auto;
  }
`;
