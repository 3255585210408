import React, { useEffect, useState } from "react";
import { Radio, Button, Space, Tag, Badge, Typography, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getUserSessionDetails } from "../../../redux/userInfoSlice";
import moment from "moment";
import { getAdminCourses } from "../../../redux/courseSlice";
import { TableContainer } from "./index.style";
import { Link } from "react-router-dom";
import { Table as BSTable } from "react-bootstrap";

const { Text } = Typography;
function AdminUserSessionDetails() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const { userSessionDetails = [], loading = true } = useSelector(
    (state) => state?.userInfoSession
  );
  const { adminCourses = null, courseLoading = true } = useSelector(
    (state) => state?.course
  );

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
    sorter: {},
    filters: {},
  });

  useEffect(() => {
    dispatch(getAdminCourses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getUserSessionDetails({
        page: pagination.page,
        limit: pagination.pageSize,
        setPagination,
        sorter: pagination.sorter,
        filters: pagination.filters,
      })
    );
  }, []);

  useEffect(() => {
    setUserData(userSessionDetails);
  }, [userSessionDetails]);

  const courseOptions =
    (adminCourses &&
      adminCourses?.map(({ _id, longName }) => ({
        value: _id,
        label: longName,
      }))) ||
    [];
  const getCourseName = (id) =>
    courseOptions?.find((option) => option.value?.toString() === id?.toString())
      ?.label;

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const { current, pageSize, total } = pagination;
    const { action = false } = extra;
    if (action === "paginate") {
      dispatch(
        getUserSessionDetails({
          page: current,
          limit: pageSize,
          total,
          filters,
          sorter,
          setPagination,
        })
      );
    } else {
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: pagination.total,
        pageSize: pagination.pageSize,
      }));
    }
  };

  const SignUpDateFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    return (
      <div style={{ padding: 8 }}>
        <Radio.Group
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e.target.value])}
        >
          <Space direction="vertical">
            <Radio value="7days">Last 7 Days</Radio>
            <Radio value="30days">Last 30 Days</Radio>
            <Radio value="3months">Last 3 Months</Radio>
            <Radio value="6months">Last 6 Months</Radio>
            <Radio value="1year">Last 1 Year</Radio>
          </Space>
        </Radio.Group>
        <div style={{ marginTop: 8, textAlign: "right" }}>
          <Button onClick={() => clearFilters()} size="small">
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ marginLeft: 8 }}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "S.no",
      dataIndex: "s_no",
      key: "s_no",
      width: 25,
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 50,
      fixed: "left",
      ellipsis: !true,
      filters: ["Gmail", "Outlook", "Others"].map((text) => ({
        text,
        value: text.toLowerCase(),
      })),
      onFilter: (value, record) =>
        value === "others"
          ? !record?.email?.includes("gmail") &&
            !record?.email?.includes("outlook")
          : record?.email?.includes(value),
      render: (mail, record) => (
        <Link to={`/admin/user-session/${record?.key}/payment-details`}>
          {mail}
        </Link>
      ),
    },

    {
      title: "Status",
      children: [
        {
          title: "Admin",
          dataIndex: "admin",
          key: "admin",
          width: 45,
          filters: ["Admin", "Student"].map((text) => ({ text, value: text })),
          onFilter: (value, record) => record?.admin === value,
          render: (text) => (
            <Badge
              status={text === "Admin" ? "purple" : "default"}
              text={text}
            />
          ),
        },
        {
          title: "Lecturer",
          dataIndex: "lecturer",
          key: "lecturer",
          width: 45,
        },
      ],
    },
    {
      title: "Payment",
      children: [
        {
          title: "Status",
          dataIndex: "paidStatuses",
          key: "paidStatuses",
          width: 60,
          filters: [
            { text: "Paid", value: "paid - active" },
            { text: "Unpaid", value: "Unpaid" },
            { text: "Refunded", value: "paid - refunded" },
            { text: "Disabled", value: "paid - disabled" },
          ],
          onFilter: (value, record) => record?.paidStatuses?.includes(value),
          render: (statuses) => (
            <>
              {statuses?.map((status, index) => (
                <>
                  <Tag
                    key={index}
                    bordered={false}
                    color={
                      status === "paid - active"
                        ? "success"
                        : status === "paid - refunded"
                        ? "warning"
                        : status === "paid - disabled"
                        ? "error"
                        : "default"
                    }
                  >
                    {status || "Undefined"}
                  </Tag>
                  <br />
                </>
              ))}
            </>
          ),
        },
        {
          title: "Expiry Dates",
          dataIndex: "expiryDates",
          key: "expiryDates",
          width: 80,
        },
        {
          title: "Amounts",
          dataIndex: "amounts",
          className: "column-money",
          key: "amounts",
          align: "right",
          width: 70,
          render: (amount) =>
            amount?.map(({ currency, value }, index) => {
              return (
                <Tag
                  key={`${index}`}
                  bordered={false}
                  color={index % 2 === 0 ? "processing" : "cyan"}
                >
                  {`${currency} ${value?.toFixed(2)}`}
                </Tag>
              );
            }),
        },
      ],
    },
    {
      title: "Courses",
      children: [
        {
          title: "Course Name",
          dataIndex: "courseName",
          key: "courseName",
          width: 70,
          filters: courseOptions?.map(({ label, value }) => ({
            value,
            text: label,
          })),
          onFilter: (filterValue, record) =>
            record?.courseName?.some(
              (course) =>
                course?.courseId?.toString() === filterValue?.toString()
            ),
          render: (courseList) =>
            courseList?.map(({ courseId }, index) => {
              return (
                <Tag
                  key={`${courseId}-${index}`}
                  bordered={false}
                  color={index % 2 === 0 ? "processing" : "cyan"}
                >
                  {getCourseName(courseId)}
                </Tag>
              );
            }),
        },
        {
          title: "Tasks",
          children: [
            {
              title: "Quiz Tasks",
              dataIndex: "quizTasks",
              key: "quizTasks",
              width: 50,
            },
            {
              title: "Lesson Comments",
              dataIndex: "lessonComments",
              key: "lessonComments",
              width: 50,
            },
          ],
        },
      ],
    },
    {
      title: "Live Classes",
      children: [
        {
          title: "Classes Attended",
          dataIndex: "classesAttended",
          key: "classesAttended",
          width: 50,
        },
        {
          title: "Assignments",
          dataIndex: "assignments",
          key: "assignments",
          width: 50,
        },
      ],
    },
    {
      title: "Testimonials",
      dataIndex: "testimonials",
      key: "testimonials",
      width: 100,
    },
    {
      title: "SignIn",
      dataIndex: "signUpdate",
      key: "signUpdate",
      width: 40,
      fixed: "right",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 40,
      fixed: "right",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <SignUpDateFilterDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        if (!value) return true;
        const signUpDate = moment(record?.createdAt, "MMM D[,] YYYY");
        const now = moment();
        let dateLimit;

        switch (value) {
          case "7days":
            dateLimit = now?.subtract(7, "days");
            break;
          case "30days":
            dateLimit = now?.subtract(30, "days");
            break;
          case "3months":
            dateLimit = now?.subtract(3, "months");
            break;
          case "6months":
            dateLimit = now?.subtract(6, "months");
            break;
          case "1year":
            dateLimit = now?.subtract(1, "year");
            break;
          default:
            return false;
        }

        return signUpDate?.isAfter(dateLimit);
      },
    },
  ];

  const dataSource =
    userData?.map((item, index) => {
      const {
        _id,
        user: {
          email,
          createdAt,
          course = [],
          admin,
          lecturer,
          signUpdate,
        } = {},
        quizResponses = [],
        lessonComments = [],
        assignmentResponses = [],
        liveClasses = [],
        testimonials = [],
        paymentIntents = [],
      } = item;
      const paidStatuses = [];
      const expiryDates = [];
      const amounts = [];
      const courseName = course;
      const quizTasks = quizResponses
        ?.filter(({ quizResponses }) =>
          quizResponses?.some((response) => response?.attemptFlag)
        )
        ?.map(
          ({ quizResponses }) =>
            quizResponses?.filter((response) => response?.attemptFlag)?.length
        )
        ?.reduce((acc, length) => acc + length, 0);

      const lessonCommentsCount = lessonComments?.length;
      const assignmentsCount = assignmentResponses?.reduce(
        (acc, { assignmentCount = 0 }) => acc + assignmentCount,
        0
      );
      const testimonialTexts = testimonials?.length
        ? testimonials?.map(({ testimonial, approved }, i) => (
            <React.Fragment key={i}>
              <Badge
                status={approved ? "success" : "error"}
                text={
                  testimonial?.length > 20
                    ? testimonial?.slice(0, 20) + " ..."
                    : testimonial
                }
              />
              {i < testimonials?.length - 1 && <br />}
            </React.Fragment>
          ))
        : null;

      paymentIntents?.map(
        ({
          paymentStatus,
          expiryDate,
          date,
          subscriptionStatus,
          afterDiscountAmount,
          currency,
        }) => {
          paidStatuses?.push(`${paymentStatus} - ${subscriptionStatus}`);
          expiryDates?.push(
            `${moment(date).format("MMM Do")} - ${moment(expiryDate).format(
              "MMM Do YYYY"
            )}
            `
          );
          amounts?.push({
            currency: currency?.toUpperCase(),
            value: parseFloat(afterDiscountAmount["$numberDecimal"]),
          });
        }
      );
      return {
        key: _id,
        s_no: (pagination?.page - 1) * pagination?.pageSize + (index + 1),
        email: email,
        createdAt: moment(createdAt).format("MMM D[,] YYYY"),
        signUpdate: moment(signUpdate).format("MMM D[,] YYYY"),
        courseName,
        quizTasks,
        admin: admin ? "Admin" : "Student",
        lecturer: lecturer ? (
          <Badge status="processing" text="Lecturer" />
        ) : null,
        lessonComments: lessonCommentsCount,
        classesAttended: liveClasses?.length,
        assignments: assignmentsCount,
        testimonials: testimonialTexts,
        paidStatuses: paidStatuses?.length
          ? paidStatuses?.map((status, i) => status)
          : ["Unpaid"],
        expiryDates: expiryDates?.length
          ? expiryDates?.map((date, i) => (
              <Tag key={i} color={i % 2 === 0 ? "processing" : "cyan"}>
                {date}
              </Tag>
            ))
          : null,
        amounts: amounts || [],
      };
    }) || [];

  return (
    <div style={{ width: "92%", margin: "auto", padding: "2em 0" }}>
      <TableContainer
        title={() => (
          <>
            <h5 className="mb-2">User Session Details</h5>
            <p className="mb-2">Total Users: {pagination?.total}</p>
          </>
        )}
        columns={columns}
        dataSource={dataSource}
        loading={loading || courseLoading}
        size="small"
        bordered
        scroll={{ x: "130%" }}
        pagination={{
          current: pagination?.page,
          pageSize: pagination?.pageSize,
          total: pagination?.total,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        summary={(pageData) => {
          const currencyTotals = {};
          const memberEmails = [];

          pageData?.forEach(({ amounts, email, paidStatuses }) => {
            if (Array.isArray(amounts) && Array.isArray(paidStatuses)) {
              amounts?.forEach(({ currency, value }, index) => {
                const status = paidStatuses?.[index];
                if (currency && value && status) {
                  if (!currencyTotals[currency]) {
                    currencyTotals[currency] = {
                      "paid - active": 0,
                      "paid - refunded": 0,
                      "paid - disabled": 0,
                    };
                  }
                  currencyTotals[currency][status] += value;
                }
              });
            }
            if (email) memberEmails.push(email);
          });

          const dataSource = Object.entries(currencyTotals)?.map(
            ([currency, statusValues]) => ({
              key: currency,
              currency,
              paidActive: statusValues["paid - active"].toFixed(2),
              paidRefunded: statusValues["paid - refunded"].toFixed(2),
              paidDisabled: statusValues["paid - disabled"].toFixed(2),
            })
          );

          const columns = [
            {
              title: "",
              dataIndex: "currency",
              key: "currency",
              render: (text) => <Text strong>{text}</Text>,
            },
            {
              title: "Active",
              dataIndex: "paidActive",
              key: "paidActive",
              render: (value) => <Text type="success">{value}</Text>,
            },
            {
              title: "Refunded",
              dataIndex: "paidRefunded",
              key: "paidRefunded",
              render: (value) => <Text type="warning">{value}</Text>,
            },
            {
              title: "Disabled",
              dataIndex: "paidDisabled",
              key: "paidDisabled",
              render: (value) => <Text type="danger">{value}</Text>,
            },
          ];

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  <Text>
                    Total Members &nbsp;:&nbsp;{" "}
                    <Text type="warning">{memberEmails?.length}</Text>
                  </Text>
                </Table.Summary.Cell>
                {dataSource?.length ? (
                  <Table.Summary.Cell index={4} colSpan={3} align="center">
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <thead>
                        <tr>
                          {columns?.map(({ title }) => (
                            <th key={title}>{title}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {dataSource?.map(
                          ({
                            currency,
                            paidActive,
                            paidRefunded,
                            paidDisabled,
                          }) => (
                            <tr key={currency}>
                              <td>
                                <Text strong>{currency}</Text>
                              </td>
                              <td>
                                <Text type="success">{paidActive}</Text>
                              </td>
                              <td>
                                <Text type="warning">{paidRefunded}</Text>
                              </td>
                              <td>
                                <Text type="danger">{paidDisabled}</Text>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </Table.Summary.Cell>
                ) : (
                  <Table.Summary.Cell index={4} colSpan={3} align="center">
                    <Text>
                      Total Amount : <Text type="danger">No amounts</Text>
                    </Text>
                  </Table.Summary.Cell>
                )}
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
}

export default AdminUserSessionDetails;
