import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorMsg, successMsg } from "../components/AlertMessage";
import { ApiRequests } from "../services/ApiRequest";

export const addTasksResponse = createAsyncThunk(
  "tasksResponse/addTasksResponse",
  async (
    { data, courseId, assignmentId, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.addTasksRes(data, courseId);
      if (response.status === 201) {
        successMsg(response?.data?.message);
        navigate(`/assignment/response/${courseId}/${assignmentId}/task`);
        return response?.data?.data;
      } else {
        errorMsg(response?.data?.message);
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const getAssignmentResponse = createAsyncThunk(
  "tasksResponse/getAssignmentResponse",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getAssignmentRes(courseId);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const assignmentTasksResponse = createAsyncThunk(
  "tasksResponse/assignmentTasksResponse",
  async ({ courseId, assignmentId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.assignmentTasksRes(
        courseId,
        assignmentId
      );
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const updateTasksResponse = createAsyncThunk(
  "tasksResponse/updateTasksResponse",
  async (
    { resId, courseId, onReset, formData, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.updateTaskRes(resId, formData);
      if (response.status === 200) {
        onReset();
        navigate(`/assignment/response/${courseId}/tasks`);
        dispatch(getAssignmentResponse(courseId));
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const lecturerAssignmentReview = createAsyncThunk(
  "tasksResponse/lecturerAssignmentReview",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.lecturerAssignmentReview(courseId);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const AssignmentsFilteredReview = createAsyncThunk(
  "tasksResponse/AssignmentsFilteredReview",
  async (courseId, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.AssignmentsFilteredReview(courseId);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const lecturerTasksReview = createAsyncThunk(
  "tasksResponse/lecturerTasksReview",
  async (
    { resId, courseId, onReset, formData, navigate },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await ApiRequests.lecturerTasksReview(resId, formData);
      if (response.status === 200) {
        successMsg("Review has been updated");
        onReset();
        navigate(`/lecturer/assignments/${courseId}/review`);
        dispatch(getAssignmentResponse(courseId));
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

export const getStudentTasks = createAsyncThunk(
  "tasksResponse/getStudentTasks",
  async ({ resId, assignmentId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.getStudentTasks(resId, assignmentId);
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      errorMsg("Please try again.");
      throw rejectWithValue(error);
    }
  }
);

const initialState = {
  loading: false,
  assignmentloading: false,
  assignmentResponse: {},
  taskResponse: {},
  updatedTasksRes: null,
  assignmentReview: [],
  filterAndMatchAssignments: [],
  studentTasks: {},
};

const assignmentsResSlice = createSlice({
  name: "assignmentsRes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTasksResponse.pending, (state) => {
        state.assignmentloading = true;
      })
      .addCase(addTasksResponse.fulfilled, (state) => {
        state.assignmentloading = false;
      })
      .addCase(addTasksResponse.rejected, (state) => {
        state.assignmentloading = false;
      })
      .addCase(getAssignmentResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssignmentResponse.fulfilled, (state, action) => {
        state.assignmentResponse = action.payload;
        state.loading = false;
      })
      .addCase(getAssignmentResponse.rejected, (state) => {
        state.loading = false;
      })
      .addCase(lecturerAssignmentReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(lecturerAssignmentReview.fulfilled, (state, action) => {
        state.assignmentReview = action.payload;
        state.loading = false;
      })
      .addCase(lecturerAssignmentReview.rejected, (state) => {
        state.loading = false;
      })
      .addCase(AssignmentsFilteredReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(AssignmentsFilteredReview.fulfilled, (state, action) => {
        state.filterAndMatchAssignments = action.payload;
        state.loading = false;
      })
      .addCase(AssignmentsFilteredReview.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateTasksResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTasksResponse.fulfilled, (state, action) => {
        state.updatedTasksRes = action.payload;
        state.loading = false;
      })
      .addCase(updateTasksResponse.rejected, (state) => {
        state.loading = false;
      })
      .addCase(lecturerTasksReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(lecturerTasksReview.fulfilled, (state, action) => {
        state.tasksReview = action.payload;
        state.loading = false;
      })
      .addCase(lecturerTasksReview.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getStudentTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStudentTasks.fulfilled, (state, action) => {
        state.studentTasks = action.payload;
        state.loading = false;
      })
      .addCase(getStudentTasks.rejected, (state) => {
        state.loading = false;
      })
      .addCase(assignmentTasksResponse.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignmentTasksResponse.fulfilled, (state, action) => {
        state.taskResponse = action.payload;
        state.loading = false;
      })
      .addCase(assignmentTasksResponse.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default assignmentsResSlice.reducer;
