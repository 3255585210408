import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Form, Input, Checkbox, Radio, Skeleton, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { CustomBtn, Wrapper } from "./index.style";
import { getStudentTasks, lecturerTasksReview } from "../../../redux/assignmentsResSlice";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const TasksReview = () => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { resId, assignmentId } = useParams();
  const [isSkeleton, setSkeleton] = useState(false);
  const { studentTasks, loading } = useSelector((state) => state.assignmentsRes);
  const [taskCompleteData, setTaskCompleteData] = useState([]);
  const [taskData, setTaskData] = useState(null);

  useEffect(() => {
    if (resId && assignmentId) {
      dispatch(getStudentTasks({ resId, assignmentId }));
    }
  }, [resId, assignmentId, dispatch]);

  useEffect(() => {
    const hasStudentTasks = Object.keys(studentTasks).length >= 0;
    setSkeleton(hasStudentTasks);
  
    const data = studentTasks?.data || {};
    if (data?.Tasks) {
      setTaskData(data);      
      const updatedTaskCompleteData = data?.Tasks?.map((task) => ({
        taskId: task?.taskId || null,
        comments: (task?.Review?.[0]?.comments || "") || "", 
        isCompleted: task?.Review?.[0]?.status === "Approved",
      })) || [];
      
      setTaskCompleteData(updatedTaskCompleteData); 
    }
  }, [studentTasks, form]);
  
  useEffect(() => {
    if (taskCompleteData) {
      const fieldsValues = {
        studentName: studentTasks?.studentName,
        submitTime: moment(taskData?.submitTime).format("hh:mm A - MMMM DD, YYYY"),
        codeURL: taskData?.codeURL,
        status: ["Submitted", "Approved"].includes(taskData?.status) ? "Approved" : "Rejected",
        isLocked: taskData?.lockedFlag,
        ...Object.fromEntries(taskCompleteData?.map((task, index) => [ `comments_${index}`, task?.comments ])),
      };
      form.setFieldsValue(fieldsValues);
    }
  
  }, [taskCompleteData,taskData,form,studentTasks?.studentName]);


  const handleTaskCheckboxChange = (index) => {
    const updatedTaskCompletionFlags = [...taskCompleteData];
    updatedTaskCompletionFlags[index] = {
      ...updatedTaskCompletionFlags[index],
      isCompleted: !updatedTaskCompletionFlags[index].isCompleted,
    };
    setTaskCompleteData(updatedTaskCompletionFlags);
  };

  const handleTaskCommentsChange = (index, value) => {
    const updatedTaskCompletionFlags = [...taskCompleteData];
    updatedTaskCompletionFlags[index] = {
      ...updatedTaskCompletionFlags[index],
      comments: value,
    };
    setTaskCompleteData(updatedTaskCompletionFlags);
  };

  const isSubmitEnabled = ["Submitted"].includes(taskData?.status);

  const onReset = () => {
    form.resetFields();
  };

  const courseId = studentTasks?.courseId;

  const onSubmit = (values) => {
    const formData = {
      tasks: taskCompleteData,
      status: values?.status,
      lockedFlag: values?.isLocked,
      courseId,
      assignmentId,
      resId,
    };
    dispatch(lecturerTasksReview({ resId, courseId, onReset, formData, navigate }));
  };

  return (
    <Wrapper>
      <Helmet>
      <title>Tansy Academy Lecturer | Tasks Review </title>
    </Helmet>
      <Spin spinning={loading}>
        <Container className="Container" fluid>
          <Skeleton active loading={!isSkeleton}>
            <Form form={form} onFinish={onSubmit}>
              <div className="mainHeading">
                <div className="CompletedSection">
                  <h3 className="CompletedText">
                    <span className="HeadingText ">{taskData?.assignmentName}</span>
                  </h3>
                </div>
              </div>
              <Col xs={{ span: 24 }} gutter={10} className="mt-1 mb-4">
                {taskData?.description}
              </Col>
              <Col xs={{ span: 24 }}>
                <div className="pasteUrlSection">
                  <Col xs={{ span: 24 }}>
                    <Form.Item name="studentName" label="Student Name">
                      <Input type="text" readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item name="submitTime" label="Submitted Time">
                      <Input type="text" readOnly bordered={false} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item name="codeURL" label="Code URL">
                      <Link
                        to={studentTasks?.data?.codeURL}
                        target="_blank"
                        className="codeURL"
                        rel="noopener noreferrer"
                      >
                        {studentTasks?.data?.codeURL}
                      </Link>
                    </Form.Item>
                  </Col>
                </div>
              </Col>

              <Col span={24}>
                <div className="mt-3 assignmentDetails">
                  <p className="HeadingText mt-3">Task Details</p>
                  <ol className="taskList">
                    {taskData?.Tasks?.map((task, index) => (
                      <li className="taskName" key={index}>
                        <p className="taskName">{task.taskName}</p>
                        <Checkbox
                          checked={taskCompleteData[index]?.isCompleted}
                          className="taskDescription"
                          onChange={() => handleTaskCheckboxChange(index)}
                          disabled={!isSubmitEnabled}
                        >
                          <p className="d-flex flex-column">{task.taskDescription}</p>
                        </Checkbox>
                        <Form.Item className="mt-3 mb-3" name={`comments_${index}`}>
                          <TextArea
                            defaultValue={taskCompleteData[index]?.comments}
                            rows={7}
                            placeholder="Review Comments"
                            showCount
                            maxLength={1000}
                            onChange={(e) => handleTaskCommentsChange(index, e.target.value)}
                            disabled={!isSubmitEnabled}
                          />
                        </Form.Item>
                      </li>
                    ))}
                  </ol>

                  <div className="p-4 mb-4" style={{ border: "1px solid #fff" }}>
                    <Form.Item name="status">
                      <Radio.Group disabled={!isSubmitEnabled}>
                        <Radio value={"Rejected"}>Rejected</Radio>
                        <Radio value={"Approved"}>Approved</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name="isLocked" valuePropName="checked" noStyle>
                      <Checkbox disabled={!isSubmitEnabled}>Locked</Checkbox>
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <center>
                      <CustomBtn htmlType="submit" type="submit" disabled={!isSubmitEnabled}>
                        {loading ? <LoadingOutlined className="loader" /> : "Save"}
                      </CustomBtn>
                    </center>
                  </Form.Item>
                </div>
              </Col>
            </Form>
          </Skeleton>
        </Container>
      </Spin>
    </Wrapper>
  );
};

export default TasksReview;
