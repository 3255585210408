import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { TableContainer, Wrapper } from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentHistory } from "../../../redux/paymentSlice";
import CustomPagination from "../../../common/pagination";
import { paginate } from "../../../utils/helper";
import { Helmet } from "react-helmet";

const AdminPaymentHistory = () => {
  const dispatch = useDispatch();
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { paymentHistory, loading } = useSelector((state) => state?.payment);

  useEffect(() => {
    dispatch(getPaymentHistory());
  }, [dispatch]);

  const dataSource = [];
  paginate(paymentHistory || [], currentPage, pageSize)?.map((data, i) => {
    dataSource.push({
      key: i,
      s_no: data?.s_no,
      id: data?.id,
      email: data?.email,
      date: data?.date,
      amount: data?.amount,
      method: data?.method,
      platform:
        data?.platform === "razorPay"
          ? "Razor Pay"
          : data?.platform === "stripe"
          ? "Stripe"
          : null,
      subscription: data?.subscription,
      isEmailSent: data?.isEmailSent ? (
        <div style={{ color: "#00AC47" }}>Yes</div>
      ) : (
        <div style={{ color: "#C91D2E" }}>No</div>
      ),
      hasSubscriptionFields: data?.hasSubscriptionFields ? (
        <div style={{ color: "#00AC47" }}>Yes</div>
      ) : (
        <div style={{ color: "#C91D2E" }}>No</div>
      ),
      subscriptionStatus: data?.subscriptionStatus?.toUpperCase(),
      timeline: (
        <div style={{ color: data?.timelineStatus ? "#00AC47" : "#C91D2E" }}>
          {data?.timeline}
        </div>
      ),
    });
  });

  const columns = [
    {
      title: "S No",
      dataIndex: "s_no",
      key: "s_no",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Paid",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
    },
    {
      title: "Email Pdf",
      dataIndex: "isEmailSent",
      key: "isEmailSent",
    },
    {
      title: "Course Fields",
      dataIndex: "hasSubscriptionFields",
      key: "hasSubscriptionFields",
    },
    {
      title: "Status",
      dataIndex: "subscriptionStatus",
      key: "subscriptionStatus",
    },
    {
      title: "Timeline",
      dataIndex: "timeline",
      key: "timeline",
    },
  ];

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy Admin | Payment History </title>
      </Helmet>
      <Container fluid>
        <div>
          <h1>Payment History</h1>
          <p>Recent payment records: {paymentHistory?.length}</p>
        </div>

        <TableContainer
          loading={loading}
          dataSource={dataSource}
          columns={columns}
        />
        {paymentHistory.length > 10 && (
          <CustomPagination
            current={currentPage}
            defaultPageSize={pageSize}
            total={paymentHistory.length}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default AdminPaymentHistory;
