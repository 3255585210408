import { Col, Row } from "antd";
import React from "react";
import { Container } from "react-bootstrap";
// import { selectplan } from "../../assets/data/allData";
import ListCard from "../../components/cards/listCard";
import MobPlanSelect from "../../components/mobSelect";
import PlanDataTable from "../../components/table/planList";
import { SectionHeading } from "../home/index.style";
import { Wrapper } from "./index.style";
import plans from "../../components/json/plans.json";
import { useState } from "react";
import QuizPack from "./quizPack";
import { Link } from "react-router-dom";
import  { DollarBtn } from "./Floating";
import { Affix } from "antd";
import MetaHelmet from "../../common/helmet";
import metaHelmetData from "../../components/json/metaHelmetData.json";

const IndianPlans = () => {
  const [planPeriod, setPlanPeriod] = useState("indiaPlans");
  // eslint-disable-next-line no-unused-vars
  const onChange = (checked) => {
    if (checked) {
      setPlanPeriod("indiaPlans");
    } else {
      setPlanPeriod("internationalPlans");
    }
  };
  return (
    <Wrapper>
      <MetaHelmet {...metaHelmetData.learn_sql_power_bi_analytics_online_training_cost_india} />
      <SectionHeading>
        Select your suitable <strong>Plan</strong>
      </SectionHeading>
      <div className="text-center">
        <p className="my-4">Select plan that is suitable for your needs</p>
        {/* <div className='switch'>
          <strong className={`${planPeriod === "monthly" ? "activePlan" : ""}`}>
            Monthly
          </strong>
          <Switch onChange={onChange} />
          <strong className={`${planPeriod === "annualy" ? "activePlan" : ""}`}>
            Annualy
          </strong>
        </div> */}
      </div>
      <div>
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Affix offsetTop={100} offsetBottom={100}>
              <DollarBtn />
            </Affix>
          </div>
          <Row align="bottom">
            {plans[planPeriod]?.map((plan, i) => (
              <Col
                key={i}
                xs={24}
                lg={{ span: plan.title === "Quiz Pack" ? 24 : 6 }}
              >
                {plan.title !== "Quiz Pack" ? (
                  <ListCard plan={plan} planPeriod={planPeriod} />
                ) : (
                  <QuizPack plan={plan} planPeriod={planPeriod} />
                )}
              </Col>
            ))}
          </Row>

          <div className="refund">
            <h2>Refund Policy</h2>
            <p>
              Please utilize the <Link to="/contact">Contact Us </Link>
              form to reach out to customer service regarding any inquiries you
              may have regarding payment refunds.
            </p>{" "}
          </div>
          <div className="dataArea">
            <h2>detailed comparison</h2>
            <PlanDataTable comparison={plans.comparision} />
          </div>
          <div>
            <MobPlanSelect comparison={plans.comparision} />
          </div>
        </Container>
      </div>
    </Wrapper>
  );
};

export default IndianPlans;
