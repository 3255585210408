import { Col, Image, Rate, Row, Skeleton } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import {
  CardAvatar,
  CardContainer,
  Descr,
  History,
  RateArea,
  SellerBtn,
  TitleName,
  ResponsiveImage,
} from "./index.style";
import { nFormatter, intoHours } from "../../../utils/helper";

const CourseSqlCard = ({ data, loading = false }) => {
  const {
    instructor,
    chapterCount,
    overallRating,
    lessonCount,
    longName,
    viewCount,
    videoCount,
    videoLength,
    imageURL,
    ratingCount,
  } = data || {};
  const imageName = imageURL?.split("/").pop();
  const fallbackSrc = `/photos/${imageName}`;
  return (
    <CardContainer>
      <CardAvatar>
        {loading ? (
          <Skeleton.Avatar active size={206} shape="square" />
        ) : (
          <Image
            src={fallbackSrc}
            alt={`${longName} course image`}
            width="206px"
            height="106px"
            preview={false}
            loading="lazy"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        )}
      </CardAvatar>
      <div>
        <SellerBtn>
          {loading ? (
            <Skeleton.Button active size="small" shape="round" />
          ) : (
            <Link to="#">Best seller</Link>
          )}
        </SellerBtn>
        <TitleName>
          {loading ? <Skeleton.Input active size="default" /> : longName}
        </TitleName>
        <Descr>
          {loading ? <Skeleton.Input active size="small" /> : longName}
        </Descr>
        <Row gutter={16}>
          <Col xs={24} md={8}>
            {loading ? (
              <Skeleton.Input active size="small" />
            ) : (
              <span className="professor">by Professor {instructor}</span>
            )}
          </Col>
          <Col xs={24} md={16}>
            <RateArea>
              {loading ? (
                <Skeleton.Input active size="small" />
              ) : (
                <>
                  <strong>{ratingCount}</strong>
                  <Rate disabled allowHalf defaultValue={overallRating} />
                  &nbsp; ({overallRating}) &nbsp;&bull;&nbsp;
                  <span className="views"> {nFormatter(viewCount)} views</span>
                </>
              )}
            </RateArea>
          </Col>
          <Col span={24}>
            <History>
              <div>
                {loading ? (
                  <>
                    <Skeleton.Avatar active size="small" shape="square" />
                    <Skeleton.Input active size="small" />
                  </>
                ) : (
                  <Link to="#">
                    <ResponsiveImage
                      preview={false}
                      loading="lazy"
                      src="/icons/clock-icon.svg"
                      alt="Clock Icon"
                    />
                    <span>{intoHours(videoLength)}</span>
                  </Link>
                )}
                {loading ? (
                  <>
                    <Skeleton.Avatar active size="small" shape="square" />
                    <Skeleton.Input active size="small" />
                  </>
                ) : (
                  <Link to="#">
                    <ResponsiveImage
                      preview={false}
                      loading="lazy"
                      src="/icons/book-icon.svg"
                      alt="Book Icon"
                    />
                    <span>{chapterCount} Chapters</span>
                  </Link>
                )}
              </div>
              <div>
                {loading ? (
                  <>
                    <Skeleton.Avatar active size="small" shape="square" />
                    <Skeleton.Input active size="small" />
                  </>
                ) : (
                  <Link to="#">
                    <ResponsiveImage
                      preview={false}
                      loading="lazy"
                      src="/icons/topic-icon.svg"
                      alt="Topic Icon"
                    />
                    <span>{lessonCount} Topics</span>
                  </Link>
                )}
                {loading ? (
                  <>
                    <Skeleton.Avatar active size="small" shape="square" />
                    <Skeleton.Input active size="small" />
                  </>
                ) : (
                  <Link to="#">
                    <ResponsiveImage
                      preview={false}
                      loading="lazy"
                      src="/icons/play-icon.svg"
                      alt="Play Icon"
                    />
                    <span>{videoCount} Videos</span>
                  </Link>
                )}
              </div>
            </History>
          </Col>
        </Row>
      </div>
    </CardContainer>
  );
};

export default CourseSqlCard;
