import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Checkbox, Col, Form, Input, Skeleton, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Content, CustomBtn, Wrapper } from "./index.style";
import {
  assignmentTasksResponse,
  updateTasksResponse,
} from "../../../redux/assignmentsResSlice";
import { CustomInput } from "../../../common/input/index.style";
import { antValidator } from "../../../utils/helper";
import { codeURLSchema } from "../../../utils/validationSchema";
import { CodeBlock } from "react-code-blocks";
import { getFileURLFromAzure } from "../../../services/FileRequest";
import { Helmet } from "react-helmet";
import CustomImage from "../../../common/images";

const AssignmentsTaskResponse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const ref = useRef(null);
  const { courseId, assignmentId } = useParams();
  const [isSkeleton, setSkeleton] = useState(false);
  const { taskResponse, loading } = useSelector(
    (state) => state.assignmentsRes
  );
  const [taskCompleteFlags, setTaskCompleteFlags] = useState([]);
  const [taskData, setTaskData] = useState(null);
  const [rules, setRules] = useState({});
  const [fileData, setFileData] = useState(null);
  const [jsonFileURL, setjsonFileURL] = useState(null);

  useEffect(() => {
    setRules(antValidator(codeURLSchema));

    if (courseId && assignmentId) {
      dispatch(assignmentTasksResponse({ courseId, assignmentId }));
    }
  }, [courseId, assignmentId, dispatch]);

  useEffect(() => {
    setSkeleton(Object.keys(taskResponse).length >= 0);
    if (taskResponse?.data?.Tasks && ref.current) {
      setTaskData(taskResponse?.data ?? null);
      ref.current.setFieldsValue({
        codeURL: taskResponse?.data?.codeURL,
      });
      setTaskCompleteFlags(
        taskResponse?.data?.Tasks?.map((task) => ({
          taskId: task.taskId,
          isCompleted: task.taskCompleteFlag,
        }))
      );
    }
  }, [taskResponse, ref]);

  useEffect(() => {
    if (taskResponse?.data?.contentURL) {
      getFileURLFromAzure(
        { fileName: taskResponse.data.contentURL, fileType: "Json" },
        setjsonFileURL
      );
    }
  }, [taskResponse?.data?.contentURL]);

  useEffect(() => {
    if (jsonFileURL) {
      fetch(jsonFileURL)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(
              "Error fetching the JSON file. Status: " + response.status
            );
          }
        })
        .then((data) => {
          setFileData(data);
        })
        .catch((error) => console.log("error", error));
    }
    return () => {
      setFileData(null);
    };
  }, [jsonFileURL]);

  const handleTaskCheckboxChange = (index) => {
    const updatedTaskCompletionFlags = [...taskCompleteFlags];
    updatedTaskCompletionFlags[index] = {
      ...updatedTaskCompletionFlags[index],
      isCompleted: !updatedTaskCompletionFlags[index].isCompleted,
    };
    setTaskCompleteFlags(updatedTaskCompletionFlags);
  };
  const isEveryTaskCompleted = taskCompleteFlags.every(
    (task) => task.isCompleted
  );

  const isSubmitDisabled =
    ["Submitted", "Approved"].includes(taskData?.status) ||
    !isEveryTaskCompleted;

  const isSubmitEnabled = ["Incomplete", "Rejected"].includes(taskData?.status);
  const isReviewEnabled = ["Approved", "Rejected"].includes(taskData?.status);

  let backgroundColorEnabled;

  switch (taskData?.status) {
    case "Approved":
      backgroundColorEnabled = "rgba(144, 238, 144, 0.2)";
      break;
    case "Rejected":
      backgroundColorEnabled = "rgba(255, 204, 204, 0.5)";
      break;
    default:
      backgroundColorEnabled = "rgba(63, 111, 216, 0.1)";
      break;
  }

  const onReset = () => {
    form.resetFields();
  };

  const onSubmit = (value) => {
    const resId = taskResponse?.resId;
    const formData = {
      codeURL: value?.codeURL,
      tasks: taskCompleteFlags,
      attemptCount: parseInt(taskData?.attemptCount || 0) + 1,
      lockedFlag: true,
      attemptFlag: true,
      status: "Submitted",
      courseId,
      assignmentId,
    };
    dispatch(
      updateTasksResponse({ resId, onReset, courseId, formData, navigate })
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Tansy Academy | Assignment Task</title>
      </Helmet>
      <Spin spinning={loading}>
        <Container className="Container" fluid>
          <Skeleton active loading={!isSkeleton}>
            <Form layout="vertical" form={form} ref={ref} onFinish={onSubmit}>
              <div className="mainHeading">
                <div className="CompletedSection">
                  <h3 className="CompletedText">
                    <span className="HeadingText ">Assignment </span>
                  </h3>
                </div>

                <CustomBtn
                  htmlType="submit"
                  type="submit"
                  disabled={isSubmitDisabled}
                >
                  {loading ? (
                    <LoadingOutlined className="loader" />
                  ) : (
                    "Submit my assignment"
                  )}
                </CustomBtn>
              </div>
              <Col xs={{ span: 18 }} gutter={10} className="mt-1 mb-4">
                {taskData?.assignmentName}
              </Col>
              <Col xs={{ span: 18 }} gutter={10} className="mt-1 mb-4">
                {taskData?.description}
              </Col>
              <Col span={18}>
                <div className="pasteUrlSection">
                  <p className="sectionHeading">Paste URL</p>
                  <p className="sectionDescription">
                    You can also paste the URL to your assignment’s Google Drive
                    or Dropbox link.
                  </p>

                  <Col xs={{ span: 18 }} gutter={10}>
                    <CustomInput rules={[rules]} name="codeURL">
                      <Input
                        disabled={!isSubmitEnabled}
                        placeholder="Paste link here"
                      />
                    </CustomInput>
                  </Col>
                </div>
              </Col>{" "}
            </Form>

            <Col span={18}>
              <div
                style={{ background: backgroundColorEnabled }}
                className="mt-3 assignmentDetails"
              >
                <p className="HeadingText mt-3">Task Details</p>
                <ol className="taskList">
                  {taskData?.Tasks?.map((task, index) => (
                    <li className="taskName" key={index}>
                      <p className="taskName">{task.taskName}</p>
                      <Checkbox
                        checked={taskCompleteFlags[index]?.isCompleted}
                        className="taskDescription"
                        onChange={() => handleTaskCheckboxChange(index)}
                        disabled={!isSubmitEnabled}
                      >
                        <p className="d-flex">{task.taskDescription}</p>
                      </Checkbox>
                      {isReviewEnabled && (
                        <div
                          className="mt-3 mb-1 p-3"
                          style={{ backgroundColor: "white" }}
                        >
                          <h6 style={{ color: "#d63384" }}>Review :</h6>
                          <p
                            className="taskDescription"
                            style={{ color: "#d63384" }}
                          >
                            {task?.Review?.[0]?.comments}
                          </p>
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            </Col>
          </Skeleton>
        </Container>
        <Content>
          <div>
            {fileData && fileData ? (
              <div className="subTitle">
                {fileData?.map((item, index) => (
                  <div key={index}>
                    <h3 className="jsonTitle" style={{ marginTop: "2rem" }}>
                      {item.title}
                    </h3>
                    {item.image && (
                      <CustomImage
                        src={item.image}
                        alt="i"
                        width="100%"
                        height="100%"
                                             
                        onError={(e) => {
                          e.target.style.display = "none";
                        }}
                      />
                    )}
                    <p className="jsonDescription">{item.description}</p>

                    {item?.code && (
                      <div className="code">
                        <CodeBlock
                          text={String(item?.code)}
                          showLineNumbers={false}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </Content>
      </Spin>
    </Wrapper>
  );
};

export default AssignmentsTaskResponse;
